<template>
    <div class="w-full  max-h-[98%] overflow-y-auto custom-scrollbar border rounded-md ">
    <table class="w-full h-auto">
        <!-- Table Header -->
      <thead>
        <tr class="border-b w-full">
            <th @click="selectAll" class="px-4 py-3 whitespace-nowrap">
                <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z" :fill="selectAllToggle ? '#1C274C' : '#e2e2e2'"/>
                            </svg>
            </th>
            <th  v-if="visibileColumn.includes(col.key)"  class="px-4 py-3 whitespace-nowrap" v-for="(col,index) in columns" :key="index">
                {{ col.label }}
            </th>
        </tr>
      </thead>
      <!-- Table Body -->
      <tbody class="">  
        <tr  @click="toggleWatchList(row)" v-for="(row,index) in filterData" :key="index" :class=" checkPresent(row) ? 'bg-[#f9f9f9]' : '' " class="border-b hover:bg-[#f9f9f9]">
            <td class="px-4 py-3 cursor-pointer"><svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z" :fill="checkPresent(row) ? '#1C274C' : '#e2e2e2'"/>
                </svg>
              </td>
            <td v-if="visibileColumn.includes(rowTd.key)" class="px-4 py-2  " v-for="(rowTd,indexTd) in columns" :key="indexTd">
                <div :class="getStyle(row,rowTd)" class="">
                    {{ rowTd.key === 'changePercent' ? `${row[rowTd.key]}%` : row[rowTd.key] }}
                </div>
             </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  name: 'marketTable',
  data() {
    return {
        tableData:  [
  {
    symbol: "AAPL",
    companyName: "Apple Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Strong buy",
    show: false,
  },
  {
    symbol: "GOOG",
    companyName: "Google LLC",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Buy",
    show: false,
  },
  {
    symbol: "AMZN",
    companyName: "Amazon Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Hold",
    show: false,
  },
  {
    symbol: "MSFT",
    companyName: "Microsoft Corp.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Strong buy",
    show: false,
  },
  {
    symbol: "NFLX",
    companyName: "Netflix Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Sell",
    show: false,
  },
  {
    symbol: "TSLA",
    companyName: "Tesla Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Buy",
    show: false,
  },
  {
    symbol: "FB",
    companyName: "Meta Platforms Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Buy",
    show: false,
  },
  {
    symbol: "NVDA",
    companyName: "NVIDIA Corporation",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Strong buy",
    show: false,
  },
  {
    symbol: "AMD",
    companyName: "Advanced Micro Devices Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Hold",
    show: false,
  },
  {
    symbol: "BABA",
    companyName: "Alibaba Group Holding Ltd.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Sell",
    show: false,
  },
  {
    symbol: "DIS",
    companyName: "The Walt Disney Company",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Strong buy",
    show: false,
  },
  {
    symbol: "PYPL",
    companyName: "PayPal Holdings Inc.",
    price: (Math.random() * 200 + 100).toFixed(2),
    changePercent: (Math.random() * 5).toFixed(2),
    volume: `${(Math.random() * 50 + 10).toFixed(3)} M`,
    marketCap: `${(Math.random() * 3 + 1).toFixed(3)} T`,
    peRatio: (Math.random() * 30 + 10).toFixed(2),
    epsGrowth: (Math.random() * 15).toFixed(2),
    rating: "Buy",
    show: false,
  },
        ],
        watchLists: [],
        selectAllToggle: false,
        screenWidth: window.innerWidth,
        showColumnVisibilityToggle: false
    }
  },
  props:{
    searchQuery:{
        type: String,
        default: ""
    },
    visibileColumn:{
        type: Array,
        default: []
    },
    columns:{
        type: Array,
        default: []
    }
  },
  computed: {
    filterData(){
        if(this.searchQuery == '' ){
            return this.tableData
        }
        return this.tableData.filter((item) =>
            item.symbol.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },
  },
  methods: {
    getStyle(row,rowTd){
        if(rowTd.key == 'changePercent' && row[rowTd.key] > 0){
            return "text-[#008000]"
        }
        if(rowTd.key == 'epsGrowth' && row[rowTd.key] > 0){
            return "text-[#008000]"
        }
        if(rowTd.key == "rating"){
            if(row[rowTd.key] == 'Strong buy'){
                return "text-[#008000]"
            }
            if(row[rowTd.key] == 'Buy'){
                return "text-[#0000ff]"
            }
        }
        if(rowTd.key == "symbol"){
            // return "bg-[#E2E8F0] text-black py-1 px-3 flex items-center my-auto justify-center rounded-[14px]"
            return "px-4 py-2 w-[70px] max-w-[150px] truncate bg-[#E2E8F0] text-black py-1 px-3 flex items-center my-auto justify-center rounded-[14px]"
        }
        if(rowTd.key == 'companyName'){
          return "max-w-[150px] w-[140px]"
        }
    },
    checkPresent(row){
        let isPresent = this.watchLists.some(item => item.symbol == row.symbol)
        return isPresent
    },
    hideDropDown(){
        console.log('hideDropDown');
        this.showColumnVisibilityToggle = false;
    },
    handleVisibleToggle(){
        this.showColumnVisibilityToggle = !this.showColumnVisibilityToggle
    },
    toggleWatchList(row) {
        const existRow = this.watchLists.some((item)=> item.symbol == row.symbol);
        if(existRow){
            this.watchLists = this.watchLists.filter((item)=> item.symbol !== row.symbol)
            console.log("existRow==>",existRow);
        }else{
            this.watchLists = [...this.watchLists, row]
            console.log("watchlist else==>",);
        }
        this.saveWatchlistToLocalStorage();
        console.log("watchlist list",this.watchLists)
    },
    saveWatchlistToLocalStorage() {
      localStorage.setItem('watchLists', JSON.stringify(this.watchLists));
    },
    loadWatchlistFromLocalStorage() {
      const storedWatchlist = localStorage.getItem('watchLists');
      if (storedWatchlist) {
        this.watchLists = JSON.parse(storedWatchlist);
      }else {
        this.watchLists = []
      }
    },
    selectAll(){
        if(this.watchLists.length  == this.tableData.length){
            this.watchLists = []
            this.selectAllToggle = false
        }else{
            this.watchLists = this.tableData
            this.selectAllToggle = true
        }
        // this.saveWatchlistToLocalStorage()
    },
  },
  watch: {
    watchLists(val){
        if(this.watchLists.length !== this.tableData.length){
            this.selectAllToggle = false
        }
        this.$emit('updateSelectedSymbols',val)
    },
    searchQuery(newVal){
        console.log("adjflksadj",newVal);
    }
  },
  mounted() {
    this.loadWatchlistFromLocalStorage();
  },
  directives: {
    ClickOutside
  },
}
</script>

<style scoped>
.positive {
  color: #008000;
}

.strong-buy {
  color: green;
  font-weight: bold;
}

.buy {
  color: #0000ff;
}
</style>