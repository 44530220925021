// src/utils/svgUtils.js

import ScannerResult from '../../../component/RightSideComponent/ScannerResult/ScannerResult.vue'

export function getSvgByName(name) {
  const svgs = {
    ToggleOut: `
      <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75033 23.8327H16.2503C21.667 23.8327 23.8337 21.666 23.8337 16.2493V9.74935C23.8337 4.33268 21.667 2.16602 16.2503 2.16602L9.75033 2.16602C4.33366 2.16602 2.16699 4.33268 2.16699 9.74935L2.16699 16.2493C2.16699 21.666 4.33366 23.8327 9.75033 23.8327Z" fill="#323232"/>
      <path d="M14.3651 16.8241L10.5518 12.9999L14.3651 9.17578" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      
      `,

    ToggleIn: `
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2497 2.16732L9.74967 2.16732C4.33301 2.16732 2.16634 4.33399 2.16634 9.75065L2.16634 16.2507C2.16634 21.6673 4.33301 23.834 9.74968 23.834L16.2497 23.834C21.6663 23.834 23.833 21.6673 23.833 16.2507L23.833 9.75065C23.833 4.33399 21.6663 2.16732 16.2497 2.16732Z" fill="#323232"/>
    <path d="M11.6349 9.1759L15.4482 13.0001L11.6349 16.8242" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
      `,

    Toggle: `
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 16.5C5.46446 16.5 3.6967 16.5 2.59835 15.4016C1.5 14.3033 1.5 12.5355 1.5 9C1.5 5.46446 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46446 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4016 2.59835C16.5 3.6967 16.5 5.46446 16.5 9C16.5 12.5355 16.5 14.3033 15.4016 15.4016C14.3033 16.5 12.5355 16.5 9 16.5Z" fill="#DEDEDE"/>
    <path d="M11.25 9.5625C11.5606 9.5625 11.8125 9.31065 11.8125 9C11.8125 8.68935 11.5606 8.4375 11.25 8.4375H6.75C6.43934 8.4375 6.1875 8.68935 6.1875 9C6.1875 9.31065 6.43934 9.5625 6.75 9.5625H11.25Z" fill="black"/>
    </svg>
    
        `,

    brandicon: `
    <svg width="36" height="36" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect y="0.5" width="24" height="24" rx="12" fill="url(#pattern0_4954_75097)"/>
    <defs>
    <pattern id="pattern0_4954_75097" patternContentUnits="objectBoundingBox" width="1" height="1">
    <use xlink:href="#image0_4954_75097" transform="scale(0.0178571)"/>
    </pattern>
    <image id="image0_4954_75097" width="56" height="56" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAB0ElEQVRoBe1YvUoDQRDOE1jY6BPYWYqFvoGvYCMoqS0sFDGVhYWFaOULBGwsFGJhZ2ElCFqIraUWQQmCxY4Mx7BzczeXE042Ob6FMLuz88N+387cXTozG4Ha/Ou0+XB8Nhxw2hkGg2BwwrswriiuKK5o2jcl1CBqEDWIGvzXzzU0GTQZNJkJbzI0ZvAVHjc+voiuHgIt78fDej5rR1RoOlvnnnXR1pYUmoyPXbZTh0Ed4/2TaK7rs/79E2i+G5le2A4Ugm9vGbNrMCiIaBZ4LnottY2nZ5vdfvTXPjK/f437z2+izUsdv2oOBgWdPH4RYdlnWWdIDYqf53N8Hej0xtstzy8xtQSDgobFUvRaWpuy9fpZHv0ymzo6nbdqDgYFHYuq6LW0Nt76ZBCfc9bm4MJqiA4vizqdt2oOBgUdi6HotdQ2np5t7l5iHWofnrPf4DF25NunTFdmp3N4czBokbNrRu6vY3HH9xEmhiOi4SjQ7GZWr14OsfckGPSQE30dBvnthb8Hl/b87qnjccyVXqDVXn17MOghMO161CAYxN+GsROmuA2owRSoN5kTDDaJZopYYDAF6k3mBINNopkiFhhMgXqTOcFgk2imiNV6Bn8Bf5k93lsGaqYAAAAASUVORK5CYII="/>
    </defs>
    </svg>
    
        `,

    exclamationicon: `
    <svg width="20" height="20" class="text-darkBlue" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    </svg>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 13.6673C3.85697 13.6673 2.28563 13.6673 1.30932 12.691C0.333008 11.7147 0.333008 10.1433 0.333008 7.00065C0.333008 3.85795 0.333008 2.2866 1.30932 1.3103C2.28563 0.333984 3.85697 0.333984 6.99967 0.333984C10.1423 0.333984 11.7137 0.333984 12.69 1.3103C13.6663 2.2866 13.6663 3.85795 13.6663 7.00065C13.6663 10.1433 13.6663 11.7147 12.69 12.691C11.7137 13.6673 10.1423 13.6673 6.99967 13.6673ZM6.99967 10.834C7.27581 10.834 7.49967 10.6101 7.49967 10.334V6.33398C7.49967 6.05785 7.27581 5.83398 6.99967 5.83398C6.72354 5.83398 6.49967 6.05785 6.49967 6.33398V10.334C6.49967 10.6101 6.72354 10.834 6.99967 10.834ZM6.99967 3.66732C7.36787 3.66732 7.66634 3.9658 7.66634 4.33398C7.66634 4.70217 7.36787 5.00065 6.99967 5.00065C6.63147 5.00065 6.33301 4.70217 6.33301 4.33398C6.33301 3.9658 6.63147 3.66732 6.99967 3.66732Z" fill="currentColor"/>

  `,
    downloadicon: `
  <svg width="20" height="20" class="text-[#4C4C4C] dark:text-[#6C707A]" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  </svg>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333008 7.00065C0.333008 3.85795 0.333008 2.2866 1.30932 1.3103C2.28563 0.333984 3.85697 0.333984 6.99967 0.333984C10.1423 0.333984 11.7137 0.333984 12.69 1.3103C13.6663 2.2866 13.6663 3.85795 13.6663 7.00065C13.6663 10.1433 13.6663 11.7147 12.69 12.691C11.7137 13.6673 10.1423 13.6673 6.99967 13.6673C3.85697 13.6673 2.28563 13.6673 1.30932 12.691C0.333008 11.7147 0.333008 10.1433 0.333008 7.00065ZM6.99967 3.16732C7.27581 3.16732 7.49967 3.39118 7.49967 3.66732V7.12685L8.64614 5.98045C8.84141 5.78518 9.15794 5.78518 9.35321 5.98045C9.54847 6.17572 9.54847 6.49225 9.35321 6.68752L7.35321 8.68752C7.25947 8.78132 7.13227 8.83398 6.99967 8.83398C6.86707 8.83398 6.73987 8.78132 6.64614 8.68752L4.64612 6.68752C4.45086 6.49225 4.45086 6.17572 4.64612 5.98045C4.84138 5.78518 5.15797 5.78518 5.35323 5.98045L6.49967 7.12685V3.66732C6.49967 3.39118 6.72354 3.16732 6.99967 3.16732ZM4.33301 9.83398C4.05687 9.83398 3.83301 10.0579 3.83301 10.334C3.83301 10.6101 4.05687 10.834 4.33301 10.834H9.66634C9.94247 10.834 10.1663 10.6101 10.1663 10.334C10.1663 10.0579 9.94247 9.83398 9.66634 9.83398H4.33301Z" fill="#41901C"/>


  `,
    addicon: `
  <svg
            width="24"
            height="24"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 16.5C5.46446 16.5 3.6967 16.5 2.59835 15.4016C1.5 14.3033 1.5 12.5355 1.5 9C1.5 5.46446 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46446 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4016 2.59835C16.5 3.6967 16.5 5.46446 16.5 9C16.5 12.5355 16.5 14.3033 15.4016 15.4016C14.3033 16.5 12.5355 16.5 9 16.5ZM9 6.1875C9.31065 6.1875 9.5625 6.43934 9.5625 6.75V8.4375H11.25C11.5606 8.4375 11.8125 8.68935 11.8125 9C11.8125 9.31065 11.5606 9.5625 11.25 9.5625H9.5625V11.25C9.5625 11.5606 9.31065 11.8125 9 11.8125C8.68935 11.8125 8.4375 11.5606 8.4375 11.25V9.5625H6.75C6.43934 9.5625 6.1875 9.31065 6.1875 9C6.1875 8.68935 6.43934 8.4375 6.75 8.4375H8.4375V6.75C8.4375 6.43934 8.68935 6.1875 9 6.1875Z"
              fill="#41901C"
            />
          </svg>
  `,
    searchicon: `
    <g clip-path="url(#clip0_4976_400)">
    <svg width="12" height="12" class="text-[#4C4C4C] dark:text-[#7f8490]" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5002 10.4992L8.3252 8.32422" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5Z" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
    </g>
    <rect width="12" height="12" fill="currentColor"/>
    <clipPath id="clip0_4976_400">
    </defs>
    </clipPath>
    
    </svg>
  `,
    croseicon: `
    <path d="M5.01754 12.2176C4.80665 12.4284 4.80665 12.7703 5.01754 12.9812C5.22842 13.1921 5.57033 13.1921 5.78121 12.9812L5.01754 12.2176ZM9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756L9.38119 9.38119ZM8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119L8.61756 8.61756ZM12.9812 5.78121C13.1921 5.57033 13.1921 5.22842 12.9812 5.01754C12.7703 4.80665 12.4284 4.80665 12.2176 5.01754L12.9812 5.78121ZM9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119L9.38119 8.61756ZM12.2176 12.9812C12.4284 13.1921 12.7703 13.1921 12.9812 12.9812C13.1921 12.7703 13.1921 12.4284 12.9812 12.2176L12.2176 12.9812ZM8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756L8.61756 9.38119ZM5.78121 5.01754C5.57033 4.80665 5.22842 4.80665 5.01754 5.01754C4.80665 5.22842 4.80665 5.57033 5.01754 5.78121L5.78121 5.01754ZM5.78121 12.9812L9.38119 9.38119L8.61756 8.61756L5.01754 12.2176L5.78121 12.9812ZM9.38119 9.38119L12.9812 5.78121L12.2176 5.01754L8.61756 8.61756L9.38119 9.38119ZM8.61756 9.38119L12.2176 12.9812L12.9812 12.2176L9.38119 8.61756L8.61756 9.38119ZM9.38119 8.61756L5.78121 5.01754L5.01754 5.78121L8.61756 9.38119L9.38119 8.61756Z" fill="currentColor"/>
    <svg width="21" height="21" class="text-[#7C7C7C] dark:text-[#7f8490]" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    
    </svg>
  `,
    editicon: `
    <svg width="15" height="15" class="text-[#7C7C7C] dark:text-[#34c244]" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4976_429)">
    <path d="M10.64 3.20039L5.86995 7.97037C5.39495 8.44537 3.98494 8.66537 3.66994 8.35037C3.35494 8.03537 3.56994 6.62537 4.04494 6.15037L8.81995 1.37538C8.9377 1.24691 9.08025 1.14364 9.23905 1.07179C9.3978 0.999937 9.5695 0.960987 9.74375 0.957317C9.91795 0.953652 10.0912 0.985322 10.2528 1.05043C10.4145 1.11553 10.5613 1.21273 10.6843 1.33613C10.8074 1.45953 10.9042 1.60658 10.9688 1.76841C11.0335 1.93025 11.0647 2.1035 11.0606 2.27772C11.0564 2.45195 11.017 2.62354 10.9447 2.78212C10.8724 2.94069 10.7688 3.08299 10.64 3.20039Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 2H3C2.46956 2 1.96089 2.21071 1.58581 2.58578C1.21074 2.96086 1 3.46956 1 4V9C1 9.53045 1.21074 10.0391 1.58581 10.4142C1.96089 10.7893 2.46956 11 3 11H8.5C9.605 11 10 10.1 10 9V6.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_4976_429">
    <rect width="12" height="12" fill="currentColor"/>
    </clipPath>
    </defs>
    </svg>
    

   `,
   deleteIcon: `
        <span class="icon-KTgbfaP5 dark:text-gray-300" role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="20" height="20"><path fill="currentColor" d="M18 7h5v1h-2.01l-1.33 14.64a1.5 1.5 0 0 1-1.5 1.36H9.84a1.5 1.5 0 0 1-1.49-1.36L7.01 8H5V7h5V6c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v1Zm-6-2a1 1 0 0 0-1 1v1h6V6a1 1 0 0 0-1-1h-4ZM8.02 8l1.32 14.54a.5.5 0 0 0 .5.46h8.33a.5.5 0 0 0 .5-.46L19.99 8H8.02Z"></path></svg></span>
        `,
    downloadiconb: `
    <svg width="30" height="30" class="text-[#4C4C4C] dark:text-[#7f8490]" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.5C2 7.78595 2 5.42893 3.46447 3.96447C4.92893 2.5 7.28595 2.5 12 2.5C16.714 2.5 19.0711 2.5 20.5355 3.96447C22 5.42893 22 7.78595 22 12.5C22 17.214 22 19.5711 20.5355 21.0355C19.0711 22.5 16.714 22.5 12 22.5C7.28595 22.5 4.92893 22.5 3.46447 21.0355C2 19.5711 2 17.214 2 12.5ZM12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5V12.6893L14.4697 10.9697C14.7626 10.6768 15.2374 10.6768 15.5303 10.9697C15.8232 11.2626 15.8232 11.7374 15.5303 12.0303L12.5303 15.0303C12.3897 15.171 12.1989 15.25 12 15.25C11.8011 15.25 11.6103 15.171 11.4697 15.0303L8.46967 12.0303C8.17678 11.7374 8.17678 11.2626 8.46967 10.9697C8.76256 10.6768 9.23744 10.6768 9.53033 10.9697L11.25 12.6893V7.5C11.25 7.08579 11.5858 6.75 12 6.75ZM8 16.75C7.58579 16.75 7.25 17.0858 7.25 17.5C7.25 17.9142 7.58579 18.25 8 18.25H16C16.4142 18.25 16.75 17.9142 16.75 17.5C16.75 17.0858 16.4142 16.75 16 16.75H8Z" fill="currentColor"/>
    </svg>
    

   `,
    croseiconb: `
   <svg width="30" height="30" class="text-[#4C4C4C] dark:text-[#7f8490]" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
   </svg>
   <path d="M6.6894 16.7914C6.40822 17.0726 6.40822 17.5284 6.6894 17.8096C6.97057 18.0907 7.42646 18.0907 7.70763 17.8096L6.6894 16.7914ZM12.5076 13.0096C12.7888 12.7284 12.7888 12.2726 12.5076 11.9914C12.2264 11.7102 11.7706 11.7102 11.4894 11.9914L12.5076 13.0096ZM11.4894 11.9914C11.2082 12.2726 11.2082 12.7284 11.4894 13.0096C11.7706 13.2907 12.2264 13.2907 12.5076 13.0096L11.4894 11.9914ZM17.3076 8.20958C17.5888 7.92841 17.5888 7.47252 17.3076 7.19135C17.0264 6.91018 16.5706 6.91018 16.2894 7.19135L17.3076 8.20958ZM12.5076 11.9914C12.2264 11.7102 11.7706 11.7102 11.4894 11.9914C11.2082 12.2726 11.2082 12.7284 11.4894 13.0096L12.5076 11.9914ZM16.2894 17.8096C16.5706 18.0907 17.0264 18.0907 17.3076 17.8096C17.5888 17.5284 17.5888 17.0726 17.3076 16.7914L16.2894 17.8096ZM11.4894 13.0096C11.7706 13.2907 12.2264 13.2907 12.5076 13.0096C12.7888 12.7284 12.7888 12.2726 12.5076 11.9914L11.4894 13.0096ZM7.70763 7.19135C7.42646 6.91018 6.97057 6.91018 6.6894 7.19135C6.40822 7.47252 6.40822 7.92841 6.6894 8.20958L7.70763 7.19135ZM7.70763 17.8096L12.5076 13.0096L11.4894 11.9914L6.6894 16.7914L7.70763 17.8096ZM12.5076 13.0096L17.3076 8.20958L16.2894 7.19135L11.4894 11.9914L12.5076 13.0096ZM11.4894 13.0096L16.2894 17.8096L17.3076 16.7914L12.5076 11.9914L11.4894 13.0096ZM12.5076 11.9914L7.70763 7.19135L6.6894 8.20958L11.4894 13.0096L12.5076 11.9914Z" fill="currentColor"/>

   `,
    watchlist: `
    <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4792 19.7484C16.3332 19.7484 16.171 19.7147 16.0412 19.6473L10.802 16.9345L5.6763 19.8832C5.30323 20.1022 4.83283 20.0011 4.54086 19.681C4.36244 19.4788 4.28133 19.1924 4.31377 18.9059L5.18968 12.8907L0.891234 8.72893C0.631705 8.47619 0.534381 8.1055 0.647925 7.75167C0.745249 7.39783 1.021 7.14509 1.37785 7.09454L7.16859 6.08358L9.63412 0.557006C9.7801 0.236868 10.0883 0.0178272 10.4289 0.000977906C10.7858 -0.0158714 11.1102 0.18632 11.2724 0.506458L13.9812 5.91509L19.8044 6.65646C20.1613 6.70701 20.437 6.9429 20.5506 7.29674C20.6641 7.63372 20.583 8.02126 20.3397 8.274L16.2359 12.638L17.3551 18.6195C17.4038 18.8891 17.3389 19.1755 17.1767 19.3946C16.9983 19.6136 16.7387 19.7484 16.4792 19.7484ZM10.7696 15.9067C10.9155 15.9067 11.0778 15.9404 11.2075 16.0078L16.3819 18.6869L15.2789 12.7896C15.214 12.4863 15.3113 12.1662 15.5222 11.9471L19.5773 7.66742L13.8352 6.92605C13.5433 6.89235 13.2675 6.69016 13.1378 6.42057L10.4776 1.09618L8.02828 6.53852C7.89852 6.82495 7.63899 7.02715 7.34702 7.07769L1.63738 8.05496L5.85473 12.1493C6.08182 12.3684 6.17914 12.6717 6.13048 12.9918L5.27079 18.9228L10.3316 16.0247C10.4776 15.9573 10.6236 15.9236 10.7696 15.9067Z" fill="currentColor"/>
    </svg>


   `,
    scanner: `
   <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8337 0.5H3.16699C1.98848 0.5 1.39923 0.5 1.03311 0.8435C0.666992 1.187 0.666992 1.73985 0.666992 2.84555V3.4204C0.666992 4.28527 0.666992 4.7177 0.883325 5.07618C1.09966 5.43467 1.49488 5.65715 2.28534 6.10213L4.71286 7.46867C5.24321 7.76717 5.50838 7.9165 5.69825 8.08133C6.09365 8.42458 6.33706 8.82792 6.44736 9.32267C6.50032 9.56017 6.50032 9.83817 6.50032 10.3941V12.6187C6.50032 13.3767 6.50032 13.7556 6.71026 14.0511C6.9202 14.3465 7.29306 14.4922 8.03882 14.7838C9.60432 15.3958 10.3871 15.7018 10.9437 15.3537C11.5003 15.0055 11.5003 14.2099 11.5003 12.6187V10.3941C11.5003 9.83817 11.5003 9.56017 11.5533 9.32267C11.6636 8.82792 11.907 8.42458 12.3024 8.08133C12.4922 7.9165 12.7574 7.76717 13.2878 7.46867L15.7153 6.10213C16.5057 5.65715 16.901 5.43467 17.1173 5.07618C17.3337 4.7177 17.3337 4.28527 17.3337 3.4204V2.84555C17.3337 1.73985 17.3337 1.187 16.9676 0.8435C16.6014 0.5 16.0122 0.5 14.8337 0.5Z"
                  stroke="currentColor"
                  stroke-width="1"
                />
              </svg>


   `,
    trainingMode: `
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
</svg>
  `,
    comparingSymbols: `
   

    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" :class="iconName == 'barchart' ? 'text-[#FFFFFF]' : 'text-[#181818]'" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round"" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
</svg>

    
    `,
    filter: `
    <svg width="20" height="20" class="text-[#4C4C4C] dark:text-[#6C707A]" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.333 5.41602H13.333" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.00033 5.41602H1.66699" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33366 8.33333C9.94449 8.33333 11.2503 7.0275 11.2503 5.41667C11.2503 3.80583 9.94449 2.5 8.33366 2.5C6.72283 2.5 5.41699 3.80583 5.41699 5.41667C5.41699 7.0275 6.72283 8.33333 8.33366 8.33333Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66699 14.584H1.66699" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3333 14.582H15" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6667 17.4993C13.2775 17.4993 14.5833 16.1935 14.5833 14.5827C14.5833 12.9718 13.2775 11.666 11.6667 11.666C10.0558 11.666 8.75 12.9718 8.75 14.5827C8.75 16.1935 10.0558 17.4993 11.6667 17.4993Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    indicator: `
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5 2.5L11.6667 7.5L8.33333 4.16667L2.5 9.16667M3.75 17.5C3.05964 17.5 2.5 16.9403 2.5 16.25V14.5833C2.5 13.893 3.05964 13.3333 3.75 13.3333C4.44036 13.3333 5 13.893 5 14.5833V16.25C5 16.9403 4.44036 17.5 3.75 17.5ZM9.58333 17.5C8.893 17.5 8.33333 16.9403 8.33333 16.25V12.0833C8.33333 11.393 8.893 10.8333 9.58333 10.8333C10.2737 10.8333 10.8333 11.393 10.8333 12.0833V16.25C10.8333 16.9403 10.2737 17.5 9.58333 17.5ZM15.4167 17.5C14.7263 17.5 14.1667 16.9403 14.1667 16.25V13.75C14.1667 13.0597 14.7263 12.5 15.4167 12.5C16.107 12.5 16.6667 13.0597 16.6667 13.75V16.25C16.6667 16.9403 16.107 17.5 15.4167 17.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      
          `,
    scannerResults: `
      <svg
                width="18"
                height="16"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8337 0.5H3.16699C1.98848 0.5 1.39923 0.5 1.03311 0.8435C0.666992 1.187 0.666992 1.73985 0.666992 2.84555V3.4204C0.666992 4.28527 0.666992 4.7177 0.883325 5.07618C1.09966 5.43467 1.49488 5.65715 2.28534 6.10213L4.71286 7.46867C5.24321 7.76717 5.50838 7.9165 5.69825 8.08133C6.09365 8.42458 6.33706 8.82792 6.44736 9.32267C6.50032 9.56017 6.50032 9.83817 6.50032 10.3941V12.6187C6.50032 13.3767 6.50032 13.7556 6.71026 14.0511C6.9202 14.3465 7.29306 14.4922 8.03882 14.7838C9.60432 15.3958 10.3871 15.7018 10.9437 15.3537C11.5003 15.0055 11.5003 14.2099 11.5003 12.6187V10.3941C11.5003 9.83817 11.5003 9.56017 11.5533 9.32267C11.6636 8.82792 11.907 8.42458 12.3024 8.08133C12.4922 7.9165 12.7574 7.76717 13.2878 7.46867L15.7153 6.10213C16.5057 5.65715 16.901 5.43467 17.1173 5.07618C17.3337 4.7177 17.3337 4.28527 17.3337 3.4204V2.84555C17.3337 1.73985 17.3337 1.187 16.9676 0.8435C16.6014 0.5 16.0122 0.5 14.8337 0.5Z"
                  stroke="currentColor"
                />
              </svg>`,
    scanResult: `
              <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.8337 0.5H3.16699C1.98848 0.5 1.39923 0.5 1.03311 0.8435C0.666992 1.187 0.666992 1.73985 0.666992 2.84555V3.4204C0.666992 4.28527 0.666992 4.7177 0.883325 5.07618C1.09966 5.43467 1.49488 5.65715 2.28534 6.10213L4.71286 7.46867C5.24321 7.76717 5.50838 7.9165 5.69825 8.08133C6.09365 8.42458 6.33706 8.82792 6.44736 9.32267C6.50032 9.56017 6.50032 9.83817 6.50032 10.3941V12.6187C6.50032 13.3767 6.50032 13.7556 6.71026 14.0511C6.9202 14.3465 7.29306 14.4922 8.03882 14.7838C9.60432 15.3958 10.3871 15.7018 10.9437 15.3537C11.5003 15.0055 11.5003 14.2099 11.5003 12.6187V10.3941C11.5003 9.83817 11.5003 9.56017 11.5533 9.32267C11.6636 8.82792 11.907 8.42458 12.3024 8.08133C12.4922 7.9165 12.7574 7.76717 13.2878 7.46867L15.7153 6.10213C16.5057 5.65715 16.901 5.43467 17.1173 5.07618C17.3337 4.7177 17.3337 4.28527 17.3337 3.4204V2.84555C17.3337 1.73985 17.3337 1.187 16.9676 0.8435C16.6014 0.5 16.0122 0.5 14.8337 0.5Z"
                          stroke="currentColor"
                        />
                      </svg>`,
    logout: `
      <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0003 2.5V10M15.3012 4.70001C16.3499 5.749 17.064 7.08538 17.3532 8.54017C17.6423 9.995 17.4937 11.5028 16.9259 12.8732C16.3582 14.2434 15.3969 15.4147 14.1636 16.2387C12.9302 17.0627 11.4803 17.5024 9.99707 17.5024C8.51382 17.5024 7.06386 17.0627 5.83055 16.2387C4.59723 15.4147 3.63594 14.2434 3.06821 12.8732C2.50048 11.5028 2.35181 9.995 2.64101 8.54017C2.93019 7.08538 3.64426 5.749 4.69291 4.70001"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>`,
    settings: `
    <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.333 5.41602H13.333"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.00033 5.41602H1.66699"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.33366 8.33333C9.94449 8.33333 11.2503 7.0275 11.2503 5.41667C11.2503 3.80583 9.94449 2.5 8.33366 2.5C6.72283 2.5 5.41699 3.80583 5.41699 5.41667C5.41699 7.0275 6.72283 8.33333 8.33366 8.33333Z"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66699 14.584H1.66699"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.3333 14.582H15"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.6667 17.4993C13.2775 17.4993 14.5833 16.1935 14.5833 14.5827C14.5833 12.9718 13.2775 11.666 11.6667 11.666C10.0558 11.666 8.75 12.9718 8.75 14.5827C8.75 16.1935 10.0558 17.4993 11.6667 17.4993Z"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>`,
  }

  return svgs[name] || ''
}


/** comparison chart icon svg */ 

{/* <svg width="20" height="20" :class="iconName == 'barchart' ? 'text-[#FFFFFF]' : 'text-[#181818]'" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.819336 9.99183C0.866117 9.05622 2.56586 8.33617 3.34491 8.75004C3.96643 9.08022 4.43562 9.74848 5.09661 9.97983C5.62601 10.1651 6.25022 10.251 6.75833 9.95884C7.58695 9.48238 8.27233 8.83216 8.91496 8.13215C9.57429 7.41395 10.2638 6.72215 10.7806 5.88853C11.2301 5.16353 11.8207 4.49946 12.3944 3.86987C12.575 3.67157 12.7537 3.47048 12.9133 3.25497C12.9365 3.22366 13.0213 3.13007 13.0213 3.18898" stroke="currentColor" stroke-width="0.8" stroke-linecap="round"/>
<path d="M0.873047 6.96905C1.07543 6.96905 1.27987 6.80184 1.46695 6.72909C1.80005 6.59955 2.19278 6.56175 2.54376 6.51013C3.25337 6.40578 4.08142 6.41879 4.76039 6.64511C5.02954 6.73483 5.32813 6.76098 5.57025 6.91506C5.87126 7.10661 6.23629 7.24376 6.56908 7.37398C7.15326 7.60258 7.71572 7.91959 8.29979 8.12986C8.57462 8.22879 8.78802 8.38272 9.07966 8.4388C9.39815 8.50005 9.66649 8.54081 9.9975 8.53479C10.4881 8.52587 10.9749 8.29221 11.3503 7.99188C11.699 7.71289 12.175 7.41596 12.4151 7.02304C12.5081 6.87083 12.8817 6.53713 13.075 6.53713" stroke="currentColor" stroke-width="0.8" stroke-linecap="round"/>
</svg> */}