// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import Bars from '../overlays/chartTypes/Bars.vue'
import symbol, { getLastSymbol, setLastSymbol } from '../services/symbol'
import scanner from '../services/scans'
import gurus from '../services/gurus'
import { chartEvents } from '../chartEvents'
import { marketsData } from '../data/markets'
import Overlays from '../overlays'
import moment from 'moment'
import {
  deleteWatchList,
  getWatchListData,
  saveWatches,
} from '@/services/watches'
import {
  ChartSettingsDefaults,
  IndicatorSettingsDefaults,
  MultipleIndicatorSettings,
} from '../component/dialogBoxes/helper/GeneralSettingsDefaults'
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_EVENT_SETTINGS,
  DEFAULT_FILTER_SETTINGS,
  defaultChartSettings,
  // protraderCharts,
} from '../settings/USER_SETTINGS_DEFAULT'
import { currentSymbolDefaults } from '../settings'
import { DEFAULT_OVERLAYS, OVERLAYS_CHARTS } from '../settings/DEFAULT_OVERLAYS'
import {
  DEFAULT_THEME_SETTINGS,
  isThemePrimary,
  THEME_DETAILS,
} from '../settings/THEME_DETAILS'
import {
  deleteAllDrawingDataV1,
  getDrawingDataV1,
  updateDrawingDataV1,
  updateDrawingDataV2,
} from '@/services/drawing'
import { copy as copyObject } from '../utils/chartEvent'
import { TimeRangesConfig } from '../utils/TimeRangesConfig'
import multipleSymbolStore from './multipleSymbol'
import { updateUserSettings, updateChartSettings, updateIndicatorRequest } from '@/services/user'
import { parseToChartCandleData, getMarketTime } from '../utils'
import { Utils } from 'trading-vue-js'
import connectionStore from './connectionStore'
import {
  setAllValuesToTrue,
  setAllValuesToFalse,
  setAllValuesToTrueWithIsActiveKey,
  setAllValuesToFalseWithIsActiveKey,
  setAllObjectValueTrue,
  setAllObjectValueFalse,
} from '../utils/common'

Vue.use(Vuex)

let GetType = localStorage.getItem('SET_CHART_TYPE')
if (!GetType) {
  GetType = 'Candles'
}
const themeModeSave = localStorage.getItem('themeModeSave')
const defaultThemeSettings = JSON.parse(JSON.stringify(DEFAULT_THEME_SETTINGS))
let themeDarkMode = false
if (themeModeSave) {
  themeDarkMode = themeModeSave === 'true'
  if (themeDarkMode) {
    defaultThemeSettings.id = 'dark'
    defaultThemeSettings.settings = THEME_DETAILS['dark']
    defaultThemeSettings.themeDarkMode = themeDarkMode
  }
}
let hasStoreRange = localStorage.getItem('TimeRange')
// let rangeObj = { key: '1Y', label: '1Y', fullName: '1 Year' }
let rangeObj = { key: '3M', label: '3M', fullName: '3 Month' }
if (hasStoreRange) {
  rangeObj = JSON.parse(hasStoreRange)
}

export default new Vuex.Store({
  modules: {
    multipleSymbol: multipleSymbolStore,
    connectionStore: connectionStore,
  },
  state: {
    // isShowProgressBar: false,
    scanName: "",
    progressiveBarScanner: {
      samsPattern: false,
      darvasScan: false,
      fundementals: false,
      activity: false,
      candlesticks: false,
      patterns: false,
      indicators: false,
      proReversalSignal: false,
      proDowntrendMonitor: false,
      proBreakoutAlert: false,
      proDivergenceWatch: false,
      proSwingBoost: false,
      proSwingDrop: false,
    },
    progressiveBarGuru: {
      capitulation: false,
      bollingerSqueeze: false,
      divergenceScan: false,
      bigCandle: false,
      pocketPivot: false,
      heikenAshi: false,
      franksPattern: false,
      hhhl: false,
    },
    progressPer: 0,
    symbolCountLength: 0,
    chartReset: false,
    chartMove: false,
    totalCount: 0,
    checkCount: 0,
    emojiIcon: '🤗',
    count: 0,
    variants: {
      firstVariant: false,
      secondVariant: false,
      thirdVariant: false,
      fourthVariant: true,
      fifthVariant: false,
      sixthVariant: false,
    },
    chartOverlays: DEFAULT_OVERLAYS,
    marketId: '',
    mainChartProps: false,
    selectedDrawingID: '',
    showDescription: true,
    chartCursor: { name: 'Cross', icon: 'crosshair' },
    currentDrawing: '',
    candleIndexJumpSelected: null,
    togglePlayBtn: false,
    trainingOffset: 21,
    drawingDataList: [],
    symbolIndex: 0,
    ohlcvTimeMap: [],
    chartSettingValue:{
      maxPrice: 0,
      minPrice: 0,
      boxSize: 0,
      reversal: 0,
    },
    compareChartSymbols: [],
    chartControlPressed: false,
    trainingModeStartingIndex: 0,
    priceLoading: false,
    trainingModeStartingIndexSubset: 0,
    chartVisibleRange: rangeObj.key,
    symbolIsMissing: false,
    SelectedScanSymbol: '',
    tradingChartReference: null,
    candleIndexSelected: null,
    chartLowerRange: 0,
    chartUpperRange: 0,
    comparisionIndex: 0,
    chartPredineRangeUpdates: false,
    symbolPriceData: null,
    intradayMode: true,
    selectedWatchlist: null,
    copySymbol: [],
    chartSettings: defaultChartSettings,
    defaultChartSettings: defaultChartSettings,
    theme: false,
    isChartLoading: false,
    isArrowOpen: false,
    trainingMode: false,
    proTrader: {
      themeSettings: defaultThemeSettings,
    },
    dc: null,
    selectedIndicator: [
      // {
      //   id: 7,
      //   name: 'MACD',
      //   desc: 'Moving Average Convergence/Divergence',
      //   chartType: 'offchart',
      //   type: 'MACD',
      //   data: [],
      //   settings: {
      //     macdColor: '#3782f2',
      //     signalColor: '#f48709',
      //     histColor: '#C0C0C0',
      //     histWidth: 4,
      //     macdWidth: 1,
      //     signalWidth: 1,
      //     crosses: [],
      //   },
      // },
    ],
    chartType: GetType,
    barTrendChart: 34,
    watchListsData: [],
    selectedWatch: '',
    selectedWatchCode: -1,
    selectedWatchSymbolCode: -1,
    userSubscriptionDetail:null,
    selectedSymbol: 'AAPL',
    userChartlist: [],
    // showWatchesModal: false,
    chartIDList: [],
    removeIndicatorName: null,
    chartSettingUpdatedDate: null,
    currentSymbolData: [],
    ohlcv: [],
    trainingModeData: [],
    trainingModeDataRaw: [],
    drawingData: {},
    updatedSettings: [],
    currentChartSetting: null,
    appSetting: null,
    watchlistData: [],
    watchLists: [],
    topListSymbol: '',
    symbolNameList: [],
    symbolsList: [],
    latestWatchlist: null,
    topListSymbolName: '',
    currentSymbol: currentSymbolDefaults,
    userData: {
      ...ChartSettingsDefaults,
      ...IndicatorSettingsDefaults,
      ...MultipleIndicatorSettings,
    },
    ChartSettingsDefaults: JSON.stringify(ChartSettingsDefaults),
    IndicatorSettingsDefaults: JSON.stringify(IndicatorSettingsDefaults),
    MultipleIndicatorSettings: JSON.stringify(MultipleIndicatorSettings),
    userId: null,
    userTrialStart:null,
    emailId: null,
    firstName: null,
    lastName: null,
    openScannerModal: false,
    openScannerRightBar: false,
    userSavedScans: [],
    timeFrame: localStorage.getItem('SET_TIME_FRAME') || 'D',
    scanResult: [],
    scanList: [],
    sectorGroup: {},
    pocketPivotToggle: false,
    percentageRiskBufferToggle: false,
    // Sans.Scanner.Activity.Settings Activity Scanners
    selectedMarket: 'market',
    scans: {
      results: [],
      gurus: {
        capitulation: {
          selectedMarket: 'market',
          volumeMultiplier: 2,
          lastVolumeAvgPeriod: 5,
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        bollingerSqueeze: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          minScanningPeriod: 200,
          maxScanningPeriod: 250,
          dayFromToday: 5,
          minSqueezeLength: 200,
          maxSqueezeLength: 250,
          ratioOfSpikeHeight: 10,
        },
        divergenceScan: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          selectedScan: 'obv',
          maxRange: 20,
          stoc: {
            kPeriod: 12,
            dPeriod: 26,
            period: 9,
          },
          rsi: 14,
          macd: {
            fast: 12,
            slow: 26,
            signal: 9,
          },
        },
        bigCandle: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          candleSizeMultiplier: 2,
          previousCandlePeriod: 5,
        },
        pocketPivot: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 5,
        },
        heikenAshi: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 10,
        },
        franksPattern: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        hhhl: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
      },
      selectedMarket: 'market',
      scanner: {
        samsPattern: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          settings: {
            scanType: 'long',
            scanPeriod: 55,
            topConfirmationPeriod: 3,
            bottomConfirmationPeriod: 3,
            percentageRiskBuffer: 2.0,
            limitTotalRisk: {
              isActive: false,
              valuePct: 20.0,
            },
            volumeIncreasedOver: {
              isActive: false,
              percent: 300,
              previousBars: 1,
            },
            inBox: {
              isActive: false,
              type: 'anyBox', // 'anyBox' | '1stBox'
            },
            within: {
              isActive: false,
              type: 'close', // 'close' | 'high'
              pricePoints: 0,
            },
            restrictBoxToMaxBox: {
              isActive: false,
              maxBoxes: 0,
            },
            restrictCurrentBoxWidth: {
              isActive: false,
              start: 0,
              end: 10,
            },
            onlyShowBoxOfTopBottomOnFirstBar: false,
          },
        },
        darvasScan: {
          selectedMarket: 'market',
          settings: {
            scanType: 'long',
            scanPeriod: 55,
            topConfirmationPeriod: 3,
            bottomConfirmationPeriod: 3,
            percentageRiskBuffer: 2.0,
            limitTotalRisk: {
              isActive: false,
              valuePct: 20.0,
            },
            volumeIncreasedOver: {
              isActive: false,
              percent: 300,
              previousBars: 1,
            },
            inBox: {
              isActive: false,
              type: 'anyBox', // 'anyBox' | '1stBox'
            },
            within: {
              isActive: false,
              type: 'close', // 'close' | 'high'
              pricePoints: 0,
            },
            restrictBoxToMaxBox: {
              isActive: false,
              maxBoxes: 0,
            },
            restrictCurrentBoxWidth: {
              isActive: false,
              start: 0,
              end: 10,
            },
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          scanType: 'long',
          scanPeriod: 55,
          topConfirmationPeriod: 3,
          bottomConfirmationPeriod: 3,
          percentageRiskBuffer: 2.0,
          limitTotalRisk: {
            isActive: false,
            valuePct: 20.0,
          },
          volumeIncreasedOver: {
            isActive: false,
            percent: 300,
            previousBars: 1,
          },
          inBox: {
            isActive: false,
            type: 'anyBox', // 'anyBox' | '1stBox'
          },
          within: {
            isActive: false,
            type: 'close', // 'close' | 'high'
            pricePoints: 0,
          },
          restrictBoxToMaxBox: {
            isActive: false,
            maxBoxes: 0,
          },
          restrictCurrentBoxWidth: {
            isActive: false,
            start: 0,
            end: 10,
          },
        },
        fundementals: {
          selectedMarket: 'market',
          settings: {
            earningYield: {
              isActive: false,
              type: 'more',
              value: 5,
            },
            peRatio: {
              isActive: false,
              type: 'more',
              value: 20,
            },
            dividendYield: {
              isActive: false,
              type: 'more',
              value: 5,
            },
            frankedPct: {
              isActive: false,
              type: '100',
            },
            capitalisation: {
              topSymbols: {
                isActive: false,
                value: 20,
              },
              sharesIssued: {
                isActive: false,
                type: 'more',
                value: 5,
              },
              marketCap: {
                isActive: false,
                type: 'more',
                value: 5,
              },
            },
            returns: {
              isActive: false,
              type: 'more',
              value: 100,
              month: 'return12m',
            },
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          earningYield: {
            isActive: false,
            type: 'more',
            value: 5,
          },
          peRatio: {
            isActive: false,
            type: 'more',
            value: 20,
          },
          dividendYield: {
            isActive: false,
            type: 'more',
            value: 5,
          },
          frankedPct: {
            isActive: false,
            type: '100',
          },
          capitalisation: {
            topSymbols: {
              isActive: false,
              value: 20,
            },
            sharesIssued: {
              isActive: false,
              type: 'more',
              value: 5,
            },
            marketCap: {
              isActive: false,
              type: 'more',
              value: 5,
            },
          },
          returns: {
            isActive: false,
            type: 'more',
            value: 100,
            month: 'return12m',
          },
        },
        activity: {
          selectedMarket: 'market',
          settings: {
            mode: 'top',
            topCount: 10,
            topMethod: 'volume',
            withinPct: 1.0,
            priceRangeStart: 1,
            priceRangeEnd: 5,
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          mode: 'top',
          topCount: 10,
          topMethod: 'volume',
          withinPct: 1.0,
          priceRangeStart: 1,
          priceRangeEnd: 5,
        },
        candlesticks: {
          selectedMarket: 'market',
          settings: {
            doji: false,
            bullishHarami: false,
            bearishHarami: false,
            bullishHaramiCross: false,
            bearishHaramiCross: false,
            hammer: false,
            invertedHammer: false,
            shootingStar: false,
            hangingMan: false,
            bullishEngulfing: false,
            bearishEngulfing: false,
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          doji: false,
          bullishHarami: false,
          bearishHarami: false,
          bullishHaramiCross: false,
          bearishHaramiCross: false,
          hammer: false,
          invertedHammer: false,
          shootingStar: false,
          hangingMan: false,
          bullishEngulfing: false,
          bearishEngulfing: false,
        },
        patterns: {
          selectedMarket: 'market',
          settings: {
            saucerBottom: false,
            doubleBottom: false,
            bearishKeyReversal: false,
            bullishPennant: false,
            bearishFlag: false,
            ascendingTriangle: false,
            doubleTop: false,
            triPointResistance: false,
            bearishPennant: false,
            descendingTriangle: false,
            bullishKeyReversal: false,
            triPointSupport: false,
            bullishFlag: false,
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
          saucerBottom: false,
          doubleBottom: false,
          bearishKeyReversal: false,
          bullishPennant: false,
          bearishFlag: false,
          ascendingTriangle: false,
          doubleTop: false,
          triPointResistance: false,
          bearishPennant: false,
          descendingTriangle: false,
          bullishKeyReversal: false,
          triPointSupport: false,
          bullishFlag: false,
        },
        indicators: {
          selectedMarket: 'market',
          settings: {
            crossesAndAboveBelow: {
              isActive: false,
              left: 'close',
              leftPeriod: 14,
              operatorType: 'above',
              right: 'ma',
              rightPeriod: 14,
            },
            ROC: {
              isActive: false,
              period: 10,
              mode: 'overbought',
            },
            RSI: {
              isActive: false,
              period: 14,
              mode: 'overbought',
            },
            STOC: {
              isActive: false,
              period: 15,
              kPercent: 3,
              dPercent: 3,
              mode: 'overbought',
            },
            BANDS: {
              isActive: false,
              period: 21,
              breakMode: 'top',
            },
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
          crossesAndAboveBelow: {
            isActive: false,
            left: 'close',
            leftPeriod: 14,
            operatorType: 'above',
            right: 'ma',
            rightPeriod: 14,
          },
          ROC: {
            isActive: false,
            period: 10,
            mode: 'overbought',
          },
          RSI: {
            isActive: false,
            period: 14,
            mode: 'overbought',
          },
          STOC: {
            isActive: false,
            period: 15,
            kPercent: 3,
            dPercent: 3,
            mode: 'overbought',
          },
          BANDS: {
            isActive: false,
            period: 21,
            breakMode: 'top',
          },
        },
        // New scanner added the in the store staring point
        proReversalSignal: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
        },
        proDowntrendMonitor: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
        },
        proBreakoutAlert: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
        },
        proDivergenceWatch: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
        },
        proSwingBoost: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
        },
        proSwingDrop: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
        }
      },
      dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
      filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
      eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
      eventUpdates: {
        status: false,
        dialogId: '',
      },
    },
    indicatorDefaults: {
      sma: 14,
      tci: 21,
    },
  },
  mutations: {
    async setBollingerSqueezeSettings(context, settings) {
      context.commit('SET_GURUS_BOLLINGER_SQUEEZE_PATTERN_SETTINGS', settings)
    },
    COMPARE_CHART_SYMBOLS(state, payload) {
      console.log("compareChartSymbols",payload)
      state.compareChartSymbols = payload
  },

    SET_GURUS_BOLLINGER_SQUEEZE_PATTERN_SETTINGS(state, settings) {
      state.scans.gurus.bollingerSqueeze = {
        ...state.scans.gurus.bollingerSqueeze,
        ...settings,
      }
    },

    RESET_GURUS(state) {
      state.scans.gurus = {
        capitulation: {
          selectedMarket: 'market',
          volumeMultiplier: 2,
          lastVolumeAvgPeriod: 5,
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        bollingerSqueeze: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          minScanningPeriod: 200,
          maxScanningPeriod: 250,
          dayFromToday: 5,
          minSqueezeLength: 200,
          maxSqueezeLength: 250,
          ratioOfSpikeHeight: 10,
        },
        divergenceScan: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          selectedScan: 'obv',
          maxRange: 20,
          stoc: {
            kPeriod: 12,
            dPeriod: 26,
            period: 9,
          },
          rsi: 14,
          macd: {
            fast: 12,
            slow: 26,
            signal: 9,
          },
        },
        bigCandle: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          candleSizeMultiplier: 2,
          previousCandlePeriod: 5,
        },
        pocketPivot: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 5,
        },
        heikenAshi: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 10,
        },
        franksPattern: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        hhhl: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
      }
      state.scans.filterSettings = {
        priceRangeDollar: {
          isActive: false,
          start: 0,
          end: 0,
        },
        ignoreResults: {
          isActive: false,
          minDays: 280,
        },
        isIgnoreResultsWithNoRangeForLastDay: false,
        onlyShowResultsThatHaveTraded: {
          isActive: false,
          pastDays: 10,
        },
        ignoreResultsWithValueLess: {
          isActive: false,
          value: 2500,
        },
        ignoreResultsWithVolumeLess: {
          isActive: false,
          volume: 2500,
        },
        isExcludeIntraDayData: false,
      }
    },
    SET_GURUS_CAPITULATION_SETTINGS(state, payload) {
      console.log('settings==>', payload)
      state.scans.gurus.capitulation = {
        ...state.scans.gurus.capitulation,
        ...payload,
      }
    },
    SET_TRAINING_MODE_CANDLES(state,payload){
      state.trainingOffset = payload
    },
    SET_CHART_YRANGE_LOWER(state, payload) {
      state.chartLowerRange = payload
  },
  SET_CHART_YRANGE_UPPER(state, payload) {
    state.chartUpperRange = payload
    },
    SET_COMPARISION_INDEX(state, payload) {
      console.log("comparison chart index",payload)
      state.comparisionIndex = payload
  },
    SET_GURUS_BIG_CANDLE_SCAN_SETTINGS(state, settings) {
      state.scans.gurus.bigCandle = {
        ...state.scans.gurus.bigCandle,
        ...settings,
      }
    },
    SET_GURUS_HEIKEN_ASHI_CANDLE_SCAN_SETTINGS(state, settings) {
      state.scans.gurus.heikenAshi = {
        ...state.scans.gurus.heikenAshi,
        ...settings,
      }
    },
    SET_EMOJI_ICON(state, payload) {
      state.emojiIcon = payload
    },
    SET_ACTIVITY_SCAN_SETTINGS(state, payload) {
      console.log('payload==>', payload)
      state.scans.scanner.activity.settings = {
        ...state.scans.scanner.activity.settings,
        ...payload,
      }
      console.log('payload==>', payload)
    },
    SET_CHART_SETTINGS(state, payload) {
      state.chartSettings = {
        ...state.chartSettings,
        ...payload,
      }
    },
    CHART_VISIBLE_RANGE(state, range) {
      state.chartVisibleRange = range
    },
    UPDATE_USER_DRAWING_TOOLS(state, payload) {
      console.log('payload drawing', payload)
      state.drawingData = payload
    },
    UPDATE_USER_DRAWING_LIST(state, payload) {
      console.log("payload drawing user",payload)
      state.drawingDataList = payload
    },
    SET_TRAINING_MODE(state, payload) {
      console.log('payload', payload)
      state.trainingMode = payload.state
      state.trainingModeStartingIndex =
        payload.startingIndex ?? state.trainingModeStartingIndex
      state.trainingModeStartingIndexSubset =
        payload.trainingDataIndex ?? state.trainingModeStartingIndex
    },
    SET_TIME_FRAME(state, payload) {
      console.log('timeframe', payload)
      localStorage.setItem('SET_TIME_FRAME', payload)
      state.currentSymbol.timeFrame = payload
      state.timeFrame = payload
    },
    SET_CHART_LOADING_STATUS(state, status) {
      state.isChartLoading = status
    },
    SET_CHART_DEFAULT_SETTINGS(state, payload) {
      state.defaultChartSettings = {
        ...state.defaultChartSettings,
        ...payload,
      }
      state.chartSettingUpdatedDate = new Date().getTime()
    },
    UPDATE_CHART_STATE(state, payload) {
      state.chartSettingUpdatedDate = payload
    },
    CHART_PREDEFINE_RANGE_UPDATES(state) {
      state.chartPredineRangeUpdates = !state.chartPredineRangeUpdates
    },
    UPDATE_SYMBOL_INDEX(state, newSymbolIndex) {
      /////////////////////////////////////
      state.symbolIndex = newSymbolIndex
    },
    TOGGLE_SCANNER_MODAL(state, payload) {
      state.openScannerModal = payload
    },
    SET_USER_SUBSCRIPTION_DETAIL(state, payload) {
      state.userSubscriptionDetail = payload
    },
    TOGGLE_DESCRIPTION(state) {
      state.showDescription = !state.showDescription
    },
    UPDATE_USER_SCAN_LIST(state, payload) {
      state.userSavedScans = payload
    },
    TOP_LIST_SYMBOL(state, payload) {
      state.topListSymbol = payload
    },
    UPDATE_SYMBOL_DETAIL(state, payload) {
      console.log("update symbol",payload)
      state.currentSymbol = payload
    },
    getSymbolListData(state, payload) {
      state.symbolNameList = payload
    },
    ADD_TO_WATCHLIST(state, payload) {
      state.watchlistData.push(payload)
      state.latestWatchlist = payload
    },
    UPDATE_TCI_PARAMS(state, payload) {
      state.barTrendChart = payload
    },
    UPDATE_APP_SETTING(state, payload) {
      state.appSetting = payload
    },
    SET_CANDLE_DATA_TIME_MAP(state, payload) {
      console.log("timestamp",payload)
      state.ohlcvTimeMap = payload
    },
    UPDATE_SYMBOL(state, payload) {
      state.selectedSymbol = payload
    },
    UPDATE_TRAINING_MODE_DATA(state, payload) {
      state.trainingModeData = payload.data
      state.trainingModeDataRaw = payload.raw
    },
    UPDATE_CHART_SETTING(state, payload) {
      state.currentChartSetting = payload
    },
    SET_TRADING_CHART_REFERENCE(state, payload) {
      state.tradingChartReference = payload.tradingChartReference
    },
    SET_DRAWING_ID(state, payload) {
      state.selectedDrawingID = payload
    },
    SET_CURRENT_DRAWING(state, payload) {
      state.currentDrawing = payload
    },
    SET_CHART_CURSOR(state, payload) {
      state.chartCursor = payload
    },
    SET_DATA_CUBE(state, payload) {
      state.dc = payload.dc
    },
    SET_OHLCV(state, payload) {
      console.log("payload==>",payload)
      state.ohlcv = payload
    },
    setVariant(state, variants) {
      state.variants = variants
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    setChartReset(state, val) {
      state.chartReset = val
    },
    setChartMove(state, val) {
      state.chartMove = val
    },
    SET_CHARTSETTING_VALUE(state,val){
      state.chartSettingValue = val
    },
    ADD_INDICATOR(state, indicator) {
      state.selectedIndicator.push(indicator)
    },
    SET_CURRENT_SYMBOL(state, symbol) {
      console.log('symbol==>', symbol)
      state.currentSymbol = symbol
    },
    UPDATE_INDICATOR_LIST(state, indicator) {
      state.selectedIndicator = indicator
    },
    UPDATE_THEME(state, payload) {
      state.theme = payload
    },
    SET_MARKET_ID(state, marketId) {
      let data = marketsData.find((m) => m.code === marketId)
      if (data) {
        state.marketDesc = data.desc
      }
      state.marketId = marketId
    },
    REMOVE_INDICATOR(state, indicator) {
      state.selectedIndicator = state.selectedIndicator.filter(
        (ind) => ind.name !== indicator.name
      )
    },
    UPDATE_WATCHLIST(state, payload) {
      console.log('res', state.watchlistData)
      console.log('payload', payload)
      let watchlist = state.watchlistData
      watchlist = watchlist.filter((item) => {
        if (item.id === payload.id) {
          state.watchlistData.map((item) =>
            item.id === payload.id
              ? (item.watchlistName = payload.watchlistName)
              : item
          )
        }
      })

      console.log('after updated', state.watchlistData)
    },

    UPDATE_CUSTOM_THEME(state, payload) {
      state.proTrader.themeSettings = { settings: payload }
    },
    THEME_MODE(state, payload) {
      state.proTrader.themeSettings = {
        ...state.proTrader.themeSettings,
        themeDarkMode: payload,
      }
    },
    UPDATE_DEFAULT_THEME(state, payload) {
      state.proTrader.themeSettings = { id: payload }
    },
    SET_CANDLE_INDEX_UPD(state, payload) {
      state.candleIndexSelected = payload
    },
    UPDATE_COPY_SYMBOL(state, payload) {
      state.copySymbol = payload
    },
    SET_CANDLE_INDEX_UPD_TRAINING(state, payload) {
      console.log('jumped', payload)
      state.candleIndexJumpSelected = payload
    },
    SET_SYMBOL_TIME_FRAME(state, timeFrame) {
      state.timeFrame = timeFrame
    },
    SET_CHART_TYPE(state, payload) {
      console.log("payload==>",payload)
      localStorage.setItem('SET_CHART_TYPE', payload)
      state.chartType = payload
    },
    UPDATE_SYMBOL_DATA(state, payload) {
      state.currentSymbolData = payload
      state.ohlcv = payload
    },
    UPDATE_REMOVE_INDICATOR(state, payload) {
      state.removeIndicatorName = payload
    },
    UPDATE_SETTINGS(state, payload) {
      state.updatedSettings = payload
    },
    UPDATE_ARROW(state, payload) {
      state.isArrowOpen = payload
    },

    RESET_SCAN_SETTINGS_DEFAULT(state) {
      state.dataSettings = JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS))
      state.filterSettings = JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS))
      state.eventsSettings = JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS))
    },

    TOP_LIST_SYMBOL_NAME(state, payload) {
      state.topListSymbolName = payload
    },
    UPDATE_PRICE_LOADING(state, payload) {
      state.priceLoading = payload
    },
    UPDATE_SYMBOLS_LIST(state, symbolsList) {
      ///////////////////////////////////////
      state.symbolsList = symbolsList
    },
    UPDATE_SYMBOLS_PRICE(state, data) {
      state.symbolPriceData = data
    },

    SET_USER_DATA(state, payload) {
      console.log('SET_USER_DATA', payload)
      if (state.userData?.indicatorSettings?.additional) {
        Object.keys(state.userData.indicatorSettings.additional).map((key) => {
          if (!payload.indicatorSettings.additional[key]) {
            payload.indicatorSettings.additional[key] =
              state.userData.indicatorSettings.additional[key]
          }
        })
      }

      state.userData = {
        ...state.userData,
        ...payload,
      }
    },
    SET_USER_EMAIL(state, payload) {
      state.emailId = payload
    },
    MAIN_CHART_PROPS(state, payload) {
      console.log("btn click")
      state.mainChartProps = payload
  },
    SET_USER_LAST_NAME(state, payload) {
      state.lastName = payload
    },
    SET_USER_FIRST_NAME(state, payload) {
      state.firstName = payload
    },
    SET_USER_ID(state, payload) {
      state.userId = payload
    },
    SET_USER_TRIAL_DATE(state, payload) {
      state.userTrialStart = payload
    },
    SET_USER_CHART_LIST(state, payload) {
      const defaultCharts = [
        'trendCharts',
        'momentumCharts',
        'volatilityCharts',
        'plusCharts',
      ]
      let userChartData = false
      let userChartlist = []
      let chartIDList = []
      for (let defaultChart of defaultCharts) {
        // debugger
        let findElement = false
        if (
          state.chartOverlays[defaultChart] &&
          state.chartOverlays[defaultChart].data
        ) {
          for (const datum of state.chartOverlays[defaultChart].data) {
            if (payload.data.includes(datum.chartId)) {
              userChartlist.push(datum.chartId)
              chartIDList.push(datum.name)
              datum.selected = true
              findElement = true
            } else {
              datum.selected = false
            }
          }
        }
        if (!userChartData) {
          userChartData = findElement
        }
        state.chartOverlays[defaultChart].selected = findElement
      }
      state.userChartlist = userChartlist
      state.chartIDList = chartIDList
    },
    UPDATE_SCAN_RESULT(state, payload) {
      if (state.scanResult.length > 0) {
        state.scanResult.shift()
        state.scanResult.push(payload)
      } else {
        state.scanResult.push(payload)
      }
      // state.openScannerRightBar = true
      // setTimeout(() => {
      // state.openScannerRightBar = false
      // }, 2000)
    },
    UPDATE_SCAN_LIST(state, payload) {
      console.log('payload', payload)
      state.scanList = [...state.scanList, payload]
    },
    UPDATE_SECTOR_MAP(state, sectorGroup) {
      let cleanedData = {};
      Object.keys(sectorGroup).forEach((exchange)=> {
        if(Array.isArray(sectorGroup[exchange])){
          cleanedData[exchange] = sectorGroup[exchange]
                  .filter((item)=> item.trim() !== "")
                  .map((item)=> item.replace(/\\|"/g, ""))
        }
      })
      state.sectorGroup = cleanedData
    },
    SET_SCAN_DATA_SETTINGS2(state, payload) {
      if (payload.type === 'scanner') {
        let spreadElements = {
          ...state.scans.scanner[payload.id].dataSettings,
          ...payload.settings,
        }
        state.scans.scanner[payload.id].dataSettings = spreadElements
      } else if (payload.type === 'guru') {
        let spreadElements = state.scans.gurus[payload.id].dataSettings
        spreadElements = { ...spreadElements, ...payload.settings }
        state.scans.gurus[payload.id].dataSettings = spreadElements
      }
    },
    SET_SCAN_DATA_SETTINGS(state, settings) {
      state.scans.dataSettings = { ...state.scans.dataSettings, ...settings }
    },
    SET_EVENTS_SETTINGS(state, payload) {
      let spreadElements = {
        ...state.scans.scanner[payload.id].eventsSettings,
        ...payload.settings,
      }
      state.scans.scanner[payload.id].eventsSettings = spreadElements
    },
    SET_PATTERN_SETTINGS(state, payload) {
      let spreadElements = {
        ...state.scans.scanner[payload.id].settings,
        ...payload.settings,
      }
      state.scans.scanner[payload.id].settings = spreadElements
    },
    TOGGLE_SELECT_ALL(state, payload) {
      // Logic for Funcdemental scanner to handle the select and clear buttons.
      if (payload.id == 'fundementals') {
        console.log("payload==>", payload);
        if (payload.tab == 'Returns') {
          let set = state.scans.scanner[payload.id].settings.returns
          if (payload.type == 'selectAll') {
            for (let key in set) {
              if (key == 'isActive') {
                set[key] = true
              }
            }
          } else {
            for (let key in set) {
              if (key == 'isActive') {
                set[key] = false
              }
            }
          }
          console.log("set==>", set);
        }
        if (payload.tab == "Capitalization") {
          let set = state.scans.scanner[payload.id].settings.capitalisation;
          if (payload.type == 'selectAll') {
            for (let key in set) {
              if (typeof set[key] == 'object') {
                set[key].isActive = true
              }
            }
          } else {
            for (let key in set) {
              if (typeof set[key] == 'object') {
                set[key].isActive = false
              }
            }
          }
          console.log("setting object==>", set);
        }
        if (payload.tab == 'Measures') {
          let set = state.scans.scanner[payload.id].settings;
          for (const key in set) {
            if (payload.type == 'selectAll') {
              if (set[key] && typeof set[key] == 'object') {
                if (key !== 'capitalisation' && key !== 'returns') {
                  if (set[key].hasOwnProperty('isActive')) {
                    set[key].isActive = true
                  }
                }
              }
            } else if (payload.type == 'clearAll') {
              if (set[key] && typeof set[key] == 'object') {
                if (key !== 'capitalisation' && key !== 'returns') {
                  if (set[key].hasOwnProperty('isActive')) {
                    set[key].isActive = false
                  }
                }
              }
            }

          }
        }
      }

      if (payload.id == 'darvasScan') {
        let set = state.scans.scanner[payload.id].settings;
        if (payload.type == 'selectAll') {
          set = setAllObjectValueTrue(set)
        } else {
          set = setAllObjectValueFalse(set)
        }
      }

      if (payload.tab == 'Charting Patterns') {
        let set = state.scans.scanner[payload.id].settings
        if (payload.type == 'selectAll') {
          set = setAllValuesToTrue(set)
        } else {
          set = setAllValuesToFalse(set)
        }
        state.scans.scanner[payload.id].settings = set
      }

      if (payload?.tab == 'Candlestick') {
        let set = state.scans.scanner[payload.id].settings
        console.log('candlestick payload', set)
        if (payload.type == 'selectAll') {
          set = setAllValuesToTrue(set)
        } else {
          set = setAllValuesToFalse(set)
        }
        state.scans.scanner[payload.id].settings = set
      }

      if (payload?.tab == 'Technical Indicators') {
        let set = state.scans.scanner[payload.id].settings
        if (payload.type == 'selectAll') {
          set = setAllValuesToTrueWithIsActiveKey(set)
        } else {
          set = setAllValuesToFalseWithIsActiveKey(set)
        }
        // state.scans.scanner[payload.id].eventsSettings = set
      }

      if (payload.tab == 'Events') {
        let set = state.scans.scanner[payload.id].eventsSettings
        if (payload.type == 'selectAll') {
          set = setAllValuesToTrueWithIsActiveKey(set)
        } else {
          set = setAllValuesToFalseWithIsActiveKey(set)
        }
        state.scans.scanner[payload.id].eventsSettings = set
      }

      if (payload?.tab == 'Filters') {
        const set = state.scans.scanner[payload.id].filterSettings
        if (payload.type == 'selectAll') {
          set = setAllValuesToTrueWithIsActiveKey(set)
        } else {
          set = setAllValuesToFalseWithIsActiveKey(set)
        }
        state.scans.scanner[payload.id].filterSettings = set
      }
    },
    SET_PROGRESS_COUNT(state, payload) {
      console.log('payload===>', payload)
      state.symbolCountLength = Object.values(payload)

      const sum = Object.values(payload.progress).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )

      const result = (sum / payload.totalSymbolNumber) * 100
      state.count = Math.round(result)
    },
    SET_TOTAL_COUNT(state, payload) {
      state.totalCount = payload
    },
    SET_SCAN_FILTER_SETTINGS2(state, payload) {
      let spreadElements = {
        ...state.scans.scanner[payload.id].filterSettings,
        ...payload.settings,
      }
      state.scans.scanner[payload.id].filterSettings = spreadElements
    },
    SET_SCAN_FILTER_SETTINGS(state, settings) {
      state.scans.filterSettings = {
        ...state.scans.filterSettings,
        ...settings,
      }
    },
    SET_SCAN_FILTER_SETTINGS2(state, payload) {
      let spreadElements = {
        ...state.scans.scanner[payload.id].filterSettings,
        ...payload.settings,
      }
      state.scans.scanner[payload.id].filterSettings = spreadElements
    },
    ADD_NEW_SCAN_RESULT(state, newScanResult) {
      console.log('new result', newScanResult)
      let date = moment().format('HH:mm:ss')
      if (!newScanResult.events) {
        newScanResult.events = {}
      }
      newScanResult.settings = JSON.parse(
        JSON.stringify({ ...newScanResult.settings, ...newScanResult.events })
      )
      let scanId =
        newScanResult.scanType + ' - ' + newScanResult.timeFrame + ' - ' + date
      let scanName =
        newScanResult.scanType.substr(0, 3).toUpperCase() +
        ' - ' +
        newScanResult.timeFrame +
        ' - ' +
        date

      // console.log("newScanResult",newScanResult)
      state.scans.results.push({
        ...newScanResult,

        date: date,
        scanId,
        scanName,
        createdDate: moment(),
        count: 0,
      })
    },
    SET_INDICATORS_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.indicators.settings = {
        ...state.scans.scanner.indicators.settings,
        ...settings,
      }
    },
    SET_DARVAS_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.darvasScan.settings = {
        ...state.scans.scanner.darvasScan.settings,
        ...settings,
      }
    },
    SET_FUNDEMENTAL_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.fundementals.settings = {
        ...state.scans.scanner.fundementals.settings,
        ...settings,
      }
    },
    SET_GURUS_DIVERGENCE_SCAN_SETTINGS(state, settings) {
      state.scans.gurus.divergenceScan = {
        ...state.scans.gurus.divergenceScan,
        ...settings,
      }
    },
    SET_GURUS_POCKET_PIVOT_SCAN_SETTINGS(state, pocketPivotPeriod) {
      state.scans.gurus.pocketPivot = pocketPivotPeriod
    },
    SET_POCKET_PIVOT(state) {
      state.pocketPivotToggle = !state.pocketPivotToggle
    },
    SET_PERCENTAGE_RISK_BUFFER(state) {
      state.percentageRiskBufferToggle = !state.percentageRiskBufferToggle
    },
    SET_PROGRESS_BAR_COUNT(state, payload) {
      state.progressPer = payload
    },
    SET_EMPTY_PROGRESS_BAR(state, payload) {
      state.progressPer = payload
    },
    SET_PROGRESSIVE_BAR_SCANNER(state, payload) {
      if (state.progressiveBarScanner.hasOwnProperty(payload)) {
        state.progressiveBarScanner[payload] = true
      }
    },
    SET_PROGRESSIVE_BAR_GURU(state, payload) {
      if (state.progressiveBarGuru.hasOwnProperty(payload)) {
        state.progressiveBarGuru[payload] = true
      }
    },
    SET_IS_SHOW_PROGRESS_BAR(state, payload) {
      state.isShowProgressBar = payload
    }
  },
  actions: {
    updateNameOfScan(context,payload){
      context.state.scanName = payload;
    },
    updateTrainingModeCandles(context,payload){
      console.log("payload",payload)
      context.commit('SET_TRAINING_MODE_CANDLES',payload)
    },
    updateIsShowProgressiveBar(context, payload) {
      context.commit('SET_IS_SHOW_PROGRESS_BAR', payload)
    },
    updateProgressiveBarForScanner(context, payload) {
      context.commit('SET_PROGRESSIVE_BAR_SCANNER', payload)
    },
    updateProgressiveBarForGuru(context, payload) {
      context.commit('SET_PROGRESSIVE_BAR_GURU', payload)
    },
    emptyProgressBar(context, payload) {
      context.commit('SET_EMPTY_PROGRESS_BAR', payload)
    },
    userSubscription(context,payload){
      context.commit('SET_USER_SUBSCRIPTION_DETAIL',payload)
    },
    updateProgressBarPercentage(context,payload){
      console.log("upate progress==>",payload);
      context.commit('SET_PROGRESS_BAR_COUNT',payload)
    },
    percentageRiskBufferChange(context) {
      context.commit('SET_PERCENTAGE_RISK_BUFFER')
    },
    pocketPivotChange(context) {
      context.commit('SET_POCKET_PIVOT')
    },
    async sendProSwingScanRequest(context) {
      try {
        const settings = {}
        const result = await scanner.proSwingUpScan(
          settings,
          context.state.scans.scanner.proSwingBoost.filterSettings,
          context.state.scans.scanner.proSwingBoost.dataSettings,
          context.state.scans.scanner.proSwingBoost.eventsSettings,
          context.state.userId,
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendProSwingDownScanRequest(context) {
      try {
        const settings = {}
        const result = await scanner.proSwingDownScan(
          settings,
          context.state.scans.scanner.proSwingBoost.filterSettings,
          context.state.scans.scanner.proSwingBoost.dataSettings,
          context.state.scans.scanner.proSwingBoost.eventsSettings,
          context.state.userId,
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendDivergenveRequest(context) {
      try {
        const settings = {}
        const result = await scanner.proDivergenceScan(
          settings,
          context.state.scans.scanner.proDivergenceWatch.filterSettings,
          context.state.scans.scanner.proDivergenceWatch.dataSettings,
          context.state.scans.scanner.proDivergenceWatch.eventsSettings,
          context.state.userId,
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async updateMarketAccordingTODataSettings(context, payload) {
      console.log("payload==>", payload)
      // let value = ''
      context.commit('SET_SCAN_FILTER_SETTINGS2', {
        id: payload.id,
        settings: payload.filterSettings,
      })
      context.commit('SET_EVENTS_SETTINGS', {
        id: payload.id,
        settings: payload.events,
      })
      context.commit('SET_PATTERN_SETTINGS', {
        id: payload.id,
        settings: payload.settings,
      })
      context.commit('SET_SCAN_DATA_SETTINGS2', {
        id: payload.id,
        settings: payload.dataSettings,
        type: 'scanner',
      })
      // if (payload.dataSettings.isWatch) {
      //     value = 'watch-list'
      // } else if (payload.dataSettings.isScan) {
      //     value = 'scan-result'
      // } else {
      //     value = 'market'
      // }

      // await context.dispatch('setSelectedMarketNew', {
      //     id: payload.id,
      //     value: value,
      //     type: 'scanner',
      // })
    },
    async sendProBraakoutRequest(context) {
      try {
        // const settings = {}
        const result = await scanner.runProBreakout(
          // settings,
          context.state.scans.scanner.proBreakoutAlert.filterSettings,
          context.state.scans.scanner.proBreakoutAlert.dataSettings,
          context.state.scans.scanner.proBreakoutAlert.eventsSettings,
          context.state.userId,
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendProDowntrendScanRequest(context) {
      try {
        const result = await scanner.proDownTradeScan(
          context.state.scans.scanner.proDowntrendMonitor.filterSettings,
          context.state.scans.scanner.proDowntrendMonitor.dataSettings,
          context.state.scans.scanner.proDowntrendMonitor.eventsSettings,
          context.state.userId,
        )
        context.dispatch('addNewScanResult', { ...result.data.data })
        return result.data
      } catch (error) {
        console.log("errro:", error);
        return null
      }
    },
    async sendProReversalScanRequest(context) {
      try {
        const settings = {}
        const result = await scanner.proReversalScan(
          context.state.scans.scanner.proReversalSignal.dataSettings,
          context.state.scans.scanner.proReversalSignal.filterSettings,
          context.state.userId,
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendPocketPivotScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.pocketPivot
        const result = await gurus.pocketPivotScan(
          settings,
          context.state.scans.gurus.pocketPivot.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async setPocketPivotScanSettings(context, period) {
      context.commit('SET_GURUS_POCKET_PIVOT_SCAN_SETTINGS', period)
    },
    async sendDivergenceScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.divergenceScan
        const result = await gurus.divergenceScan(
          settings,
          context.state.scans.gurus.divergenceScan.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async setDivergenceScanSettings(context, settings) {
      context.commit('SET_GURUS_DIVERGENCE_SCAN_SETTINGS', settings)
    },
    async sendFundementalScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.fundementals.settings
        const filterSettings =
          context.state.scans.scanner.fundementals.filterSettings
        const result = await scanner.fundementalScan(
          settings,
          filterSettings,
          context.state.scans.scanner.fundementals.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async setFundementalsScanSettings(context, settings) {
      console.log("settings==>", settings);
      context.commit('SET_FUNDEMENTAL_SCAN_SETTINGS', settings)
    },
    async sendDarvasScanRequest(context) {
      try {
        chartEvents.$emit('removeDarvas')
        const settings = context.state.scans.scanner.darvasScan.settings
        const filterSettings =
          context.state.scans.scanner.darvasScan.filterSettings
        const result = await scanner.darvasScan(
          settings,
          filterSettings,
          context.state.scans.scanner.darvasScan.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async setDarvasScanSettings(context, settings) {
      context.commit('SET_DARVAS_SCAN_SETTINGS', settings)
    },
    async setCapitulationSettings(context, settings) {
      console.log('setCapitulationSettings', settings)
      context.commit('SET_GURUS_CAPITULATION_SETTINGS', settings)
    },
    async setBigCandleScanSettings(context, settings) {
      console.log('setBigCandleScanSettings', settings)
      context.commit('SET_GURUS_BIG_CANDLE_SCAN_SETTINGS', settings)
    },
    async setHeikenAshiScanSettings(context, settings) {
      context.commit('SET_GURUS_HEIKEN_ASHI_CANDLE_SCAN_SETTINGS', settings)
    },
    // async setBigCandleScanSettings(context, settings) {
    //   console.log('setBigCandleScanSettings', settings)
    //   context.commit('SET_GURUS_BIG_CANDLE_SCAN_SETTINGS', settings)
    // },
    async updateIntraDayModeState(context, isIntradayMode) {
      context.commit('SET_INTRADAY_MODE', isIntradayMode)

      window.dc = this.getters.dc
      const {
        currentZoneTime,
        marketStartTime,
        marketEndTime,
        isMarketOpened,
      } = getMarketTime(context.state.marketId)

      let dataForCurrentSymbol = context.state.currentSymbol.data
      let timeFrame = context.state.currentSymbol.timeFrame
      let lastCandleApi = context.state.currentSymbol?.lastCandle
      let latestCandle = context.state.currentSymbol?.latestCandle
      const lastDataIndex = dataForCurrentSymbol.length - 1
      const lastCandle = lastCandleApi
        ? lastCandleApi
        : dataForCurrentSymbol[lastDataIndex]
      const lastCandleTime = moment(lastCandle.date).valueOf()

      if (isMarketOpened) {
        context
          .dispatch('setSymbolData', {
            symbolName: context.state.currentSymbol.symbol,
            timeFrame: context.state.timeFrame,
            reForce: true,
          })
          .then((d) => {
            chartEvents.$emit(
              'setChartRange',
              undefined,
              'MarketIntraDayChange'
            )
            chartEvents.$emit('symbolChangeVerifyScanDetails')
            chartEvents.$emit('loadDrawingData')
          })
        // getD the
        /*if (!isIntradayMode) {
          // remove last candle

          if (moment(lastCandle.date).isSame(currentZoneTime, 'day')) {
            context.commit(
              'UPDATE_DATA_SET',
              context.state.currentSymbol.data.slice(0, lastDataIndex)
            )
            this.getters.dc.set(
              'chart.data',
              parseToChartCandleData(context.state.currentSymbol.data)
            )
          }
        } else {
          // re-add last candle
          let data = await symbol.getLatestCandle(
            context.state.currentSymbol.symbol,
            context.state.currentSymbol.timeFrame,
            context.state.marketId
          )

          if (data) {
            let dataset = context.state.currentSymbol.data
            if (dataset && dataset.length) {
              let candleDate = dataset[dataset.length - 1].date
              let dateNow = data.date
              if (!moment(candleDate).isSame(moment(dateNow))) {
                dataset.push(data)
                context.commit('UPDATE_DATA_SET', dataset)
              }
            }
          }

          this.getters.dc.set(
            'chart.data',
            parseToChartCandleData(dataForCurrentSymbol)
          )
        }*/
      }
    },
    async sendActivityScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.activity.settings
        const filterSettings = context.state.scans.scanner.activity.filterSettings
        const result = await scanner.activityScan(
          settings,
          filterSettings,
          context.state.scans.scanner.activity.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendIndicatorsScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.indicators.settings
        let events = context.state.scans.scanner.indicators.eventsSettings
        if ('macd' in events) {
          let macd = context.getters.getSingleIndicator.macd
          events = {
            ...events,
            macd: {
              ...events.macd,
              fastPeriod: macd.fast,
              slowPeriod: macd.slow,
              signalPeriod: macd.smooth,
            },
          }
        }
        const result = await scanner.indicatorsScan(
          settings,
          context.state.scans.scanner.indicators.filterSettings,
          context.state.scans.scanner.indicators.dataSettings,
          events,
          context.state.userId
        )
        context.dispatch('addNewScanResult', {
          ...result.data.data,
          settings,
          events,
        })
        return result.data
      } catch (error) {
        return null
      }
    },
    async setIndicatorsScanSettings(context, settings) {
      context.commit('SET_INDICATORS_SCAN_SETTINGS', settings)
    },
    setActivityScanSettings(context, settings) {
      // console.log('setActivityScanSettings', settings)
      context.commit('SET_ACTIVITY_SCAN_SETTINGS', settings)
      // console.log('setActivityScanSettings', settings)
    },
    async updateIntraDayModeState(context, isIntradayMode) {
      context.commit('SET_INTRADAY_MODE', isIntradayMode)

      window.dc = this.getters.dc
      const {
        currentZoneTime,
        marketStartTime,
        marketEndTime,
        isMarketOpened,
      } = getMarketTime(context.state.marketId)

      let dataForCurrentSymbol = context.state.currentSymbol.data
      let timeFrame = context.state.currentSymbol.timeFrame
      let lastCandleApi = context.state.currentSymbol?.lastCandle
      let latestCandle = context.state.currentSymbol?.latestCandle
      const lastDataIndex = dataForCurrentSymbol.length - 1
      const lastCandle = lastCandleApi
        ? lastCandleApi
        : dataForCurrentSymbol[lastDataIndex]
      const lastCandleTime = moment(lastCandle.date).valueOf()

      if (isMarketOpened) {
        context
          .dispatch('setSymbolData', {
            symbolName: context.state.currentSymbol.symbol,
            timeFrame: context.state.timeFrame,
            reForce: true,
          })
          .then((d) => {
            chartEvents.$emit(
              'setChartRange',
              undefined,
              'MarketIntraDayChange'
            )
            chartEvents.$emit('symbolChangeVerifyScanDetails')
            chartEvents.$emit('loadDrawingData')
          })
        // getD the
        /*if (!isIntradayMode) {
          // remove last candle

          if (moment(lastCandle.date).isSame(currentZoneTime, 'day')) {
            context.commit(
              'UPDATE_DATA_SET',
              context.state.currentSymbol.data.slice(0, lastDataIndex)
            )
            this.getters.dc.set(
              'chart.data',
              parseToChartCandleData(context.state.currentSymbol.data)
            )
          }
        } else {
          // re-add last candle
          let data = await symbol.getLatestCandle(
            context.state.currentSymbol.symbol,
            context.state.currentSymbol.timeFrame,
            context.state.marketId
          )

          if (data) {
            let dataset = context.state.currentSymbol.data
            if (dataset && dataset.length) {
              let candleDate = dataset[dataset.length - 1].date
              let dateNow = data.date
              if (!moment(candleDate).isSame(moment(dateNow))) {
                dataset.push(data)
                context.commit('UPDATE_DATA_SET', dataset)
              }
            }
          }

          this.getters.dc.set(
            'chart.data',
            parseToChartCandleData(dataForCurrentSymbol)
          )
        }*/
      }
    },

    async sendCandlestickScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.candlesticks.settings
        const result = await scanner.candlestickScan(
          settings,
          context.state.scans.scanner.candlesticks.filterSettings,
          context.state.scans.scanner.candlesticks.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async addNewScanResult(context, newResult) {
      console.log("newResult==>", newResult);
      context.commit('ADD_NEW_SCAN_RESULT', newResult)
    },
    async sendPatternsScanRequest(context) {
      console.log("context==>");
      try {
        // console.log("context.state.scans.scanner.patterns.eventsSettings",JSON.stringify(context.state.scans.scanner.patterns.eventsSettings))
        const settings = context.state.scans.scanner.patterns.settings
        let events = context.state.scans.scanner.patterns.eventsSettings
        if ('macd' in events) {
          let macd = context.getters.getSingleIndicator.macd
          console.log('macd==>', macd)
          events = {
            ...events,
            macd: {
              ...events.macd,
              fastPeriod: macd.fast,
              slowPeriod: macd.slow,
              signalPeriod: macd.smooth,
            },
          }
        }
        const result = await scanner.patternsScan(
          settings,
          context.state.scans.scanner.patterns.filterSettings,
          context.state.scans.scanner.patterns.dataSettings,
          events,
          context.state.userId
        )
        console.log("result====>api", result);
        context.dispatch('addNewScanResult', {
          ...result.data.data,
          settings,
          events,
        })
        console.log('resultdata==>', result)
        // context.state.progressPer = 0
        return result.data
      } catch (error) {
        console.log(error)
        return null
      }
    },
    async setScanFilterSettingsOld(context, settings) {
      context.commit('SET_SCAN_FILTER_SETTINGS', settings)
    },
    async setScanFilterSettings(context, settings) {
      context.commit('SET_SCAN_FILTER_SETTINGS2', settings)
      return true
    },
    setPregressCount(context, payload) {
      context.commit('SET_PROGRESS_COUNT', payload)
    },
    setTotalCount(context, payload) {
      context.commit('SET_TOTAL_COUNT', payload)
    },
    async setScanFilterSettings(context, settings) {
      context.commit('SET_SCAN_FILTER_SETTINGS2', settings)
      return true
    },
    async setEventsFilterSettings(context, settings) {
      console.log('settings payload==>', settings)
      // console.log("SET EVENTS Settings",settings)
      context.commit('SET_EVENTS_SETTINGS', settings)
    },
    setPatternSettings(context, settings) {
      context.commit('SET_PATTERN_SETTINGS', settings)
    },
    setToggleAll(context, payload) {
      context.commit('TOGGLE_SELECT_ALL', payload)
    },
    async setScanDataSettingsOld(context, payload) {
      context.commit('SET_SCAN_DATA_SETTINGS', payload)
    },
    async setSelectedMarketNew(context, payload) {
      console.log('payload market new==>', payload)
      if (payload.type === 'scanner') {
        context.state.scans.scanner[payload.id].selectedMarket = payload.value
        if (payload.value === 'watch-list') {
          context.state.scans.scanner[payload.id].dataSettings.isScan = false
          context.state.scans.scanner[payload.id].dataSettings.isWatch = true
        } else if (payload.value === 'scan-result') {
          context.state.scans.scanner[payload.id].dataSettings.isScan = true
          context.state.scans.scanner[payload.id].dataSettings.isWatch = false
        } else {
          context.state.scans.scanner[payload.id].dataSettings.isScan = false
          context.state.scans.scanner[payload.id].dataSettings.isWatch = false
        }
      } else if (payload.type === 'guru') {
        context.state.scans.gurus[payload.id].selectedMarket = payload.value
        if (payload.value === 'watch-list') {
          context.state.scans.gurus[payload.id].dataSettings.isScan = false
          context.state.scans.gurus[payload.id].dataSettings.isWatch = true
        } else if (payload.value === 'scan-result') {
          context.state.scans.gurus[payload.id].dataSettings.isScan = true
          context.state.scans.gurus[payload.id].dataSettings.isWatch = false
        } else {
          context.state.scans.gurus[payload.id].dataSettings.isScan = false
          context.state.scans.gurus[payload.id].dataSettings.isWatch = false
        }
      }
    },
    async setSelectedMarket(context, payload) {
      context.state.selectedMarket = payload
      if (payload === 'watch-list') {
        context.state.scans.dataSettings.isScan = false
        context.state.scans.dataSettings.isWatch = true
      } else if (payload === 'scan-result') {
        context.state.scans.dataSettings.isScan = true
        context.state.scans.dataSettings.isWatch = false
      } else {
        context.state.scans.dataSettings.isScan = false
        context.state.scans.dataSettings.isWatch = false
      }
    },
    async setScanDataSettings(context, payload) {
      console.log('scan payload==>', payload)
      context.commit('SET_SCAN_DATA_SETTINGS2', payload)
    },
    cancelScans() {
      scanner.cancelScan()
    },
    updateScanList(context, payload) {
      context.commit('UPDATE_SCAN_LIST', payload)
    },
    updatedScanResult(context, payload) {
      context.commit('UPDATE_SCAN_RESULT', payload)
    },
    toggleScannerModal(context, payload) {
      context.commit('TOGGLE_SCANNER_MODAL', payload)
    },
    toggleDescription(context) {
      context.commit('TOGGLE_DESCRIPTION')
    },

    updateSymbolDetail(context, payload) {
      console.log('update==>', payload)
      context.commit('UPDATE_SYMBOL_DETAIL', payload)
    },
    async retrieveSymbolsList(context) {
      const symbolsList = await symbol.getListOfSymbols()
      console.log('symbolList==>', symbolsList)
      context.commit('UPDATE_SECTOR_MAP', symbolsList.sectorGroup)
      context.commit('UPDATE_INDUSTRY_MAP', symbolsList.industryGroup)
      context.commit('UPDATE_SUB_INDUSTRY_MAP', symbolsList.subIndustryGroup)
      context.commit('UPDATE_SYMBOLS_LIST', symbolsList.symbolList)
    },
    async saveChartSettings(context, chartSettings) {
      try {
        let settings = {
          isAdd: !chartSettings?.changeState,
          isRemove: chartSettings?.changeState,
          chartName: chartSettings?.chartId,
        }
        const result = await updateChartSettings(settings)
        return result.updated
      } catch (err) {
        return false
      }

      // try {
      //     let toolType = chartSettings.tool_type
      //     let chartId = chartSettings.chartId
      //     let changeState = chartSettings.selected
      //     let chartSettingObj = context.state.chartOverlays
      //     let chartType = chartSettingObj[toolType]

      //     let settings = {
      //         isAdd: !changeState,
      //         isRemove: changeState,
      //         chartName: chartId,
      //     }

      //     if (chartType && chartType.data) {
      //         let hasChartSelected = false
      //         for (const key in chartType.data) {
      //             if (chartType.data[key].name === chartSettings.tool_name) {
      //                 chartType.data[key].selected = !chartType.data[key].selected

      //                 if (!changeState) {

      //                     hasChartSelected = true

      //                 }
      //             } else {
      //                 if (chartType.data[key].selected) {
      //                     hasChartSelected = true
      //                 }
      //             }
      //         }

      //         chartType.selected = hasChartSelected

      //         chartSettingObj[toolType] = chartType
      //         const result = await updateChartSettings(settings)

      //         return result.updated
      //     }

      //     return false
      // } catch (error) {
      //     console.log(error)
      //     return null
      // }
    },
    async deleteAllDrawing(context, payload) {
      console.log('deleteAllDrawing==', context, payload)
      let elementDeleted = false
      // let localDrawData = context.state.drawingData
      elementDeleted = await deleteAllDrawingDataV1(
        context.state.currentSymbol.symbol,
        context.state.userId
      )
      // console.log(elementDeleted)
      context.state.drawingData = {}
      return elementDeleted
    },
    async deleteDrawing(context, payload) {
      const parts = payload?.t?.split('.')
      let toolType = parts?.[1]?.split('-')
      let chartType = parts?.[0]
      const drawingList = [
        'FibonacciRetrace',
        'FibonacciTime',
        'HLineTool',
        'TrendLine',
        'ArcTool',
        'ChannelTool',
        'TradeVisualizerTool',
        'RectangleTool',
        'TrailingStopTool',
        'TextTool',
        'EmojiTool',
        'RangeTool',
      ]

      toolType = drawingList.find((d) => {
        const startsWith = toolType?.[0]?.startsWith(d)
        // console.log("startsWith decision",toolType?.[0],d,startsWith)
        return startsWith
      })

      // console.log("deleteDrawing",payload,context.getters.tv,toolType)
      let elementDeleted = false
      let localDrawData = copyObject(context.state.drawingData)

      // let grid_id=JSON.parse(copyObject(context.state.drawingData)[payload])?.grid?.id;
      // if (localDrawData.hasOwnProperty(payload)) {
      if (Object.keys(localDrawData).includes(payload.t)) {
        delete localDrawData[payload.t]
        elementDeleted = true
      }
      let newMapData = {}
      let deletedTypes = []
      let chartTypeList = context.getters.tv.data.get(
        `${chartType}.${toolType}`
      )
      console.log(
        'payload==>',
        payload,
        parts,
        toolType,
        chartType,
        toolType,
        localDrawData,
        chartTypeList
      )

      if (payload.e != 'onRemoveTool') {
        for (let chartTypeListElement of chartTypeList) {
          console.log('chartTypeListElement', chartTypeListElement)
          context.getters.tv.data.del(chartTypeListElement.settings.$uuid)
        }
      }
      console.log('deleteDrawing payload', payload)
      for (let key in localDrawData) {
        let data = JSON.parse(localDrawData[key])
        // console.log("Data",data)
        if (toolType === data.type) {
          deletedTypes.push(data)
        } else {
          if (data?.settings?.$uuid === key) {
            // console.log("Data keys==>",key)
            newMapData[key] = localDrawData[key]
          }
        }
      }
      let deletedIterator = 0
      if (deletedTypes.length) {
        for (let deletedType of deletedTypes) {
          let _chartType = deletedType?.id?.split('.')[0]
          let timeStamp = Utils.now()
          // let _grid_id=deletedType?.grid?.id;
          if (_chartType === chartType) {
            //&& _grid_id===grid_id ){
            deletedType.name = `${deletedType.type} ${deletedIterator}`
            deletedType.id = `${_chartType}.${deletedType.type}${deletedIterator}`
            deletedType.settings.$uuid = `${_chartType}.${deletedType.type}${deletedIterator}-${timeStamp}`
            deletedIterator++
            // context.getters.tv.data.add(chartType,toolType)
            newMapData[deletedType.settings.$uuid] = JSON.stringify(deletedType)
          } else {
            newMapData[deletedType.settings.$uuid] = JSON.stringify(deletedType)
          }
        }
      }
      if (elementDeleted) {
        let data = {
          symbolName: context.state.currentSymbol.symbol,
          data: localDrawData,
        }
        // setTimeout(() => {
        //
        // })
        updateDrawingDataV1({
          data: payload.e == 'onRemoveTool' ? localDrawData : newMapData,
          symbolName: context.state.currentSymbol.symbol,
          userId: context.state.userId,
        }).then(() => {
          if (payload.e == 'onRemoveTool') {
            context.state.drawingData = localDrawData
          } else {
            context.state.drawingData = newMapData
          }
        })
        // await deleteSingleDrawingDataV1(
        //     context.state.currentSymbol.symbol,
        //     context.state.userId,payload)

        return true
      } else {
        return false
      }
    },
    async addDrawingForScannedSymbol(
      context,
      { symbolData, scanType, settings }
    ) {
      let scanOverlay
      let patternName = symbolData.patternName
      let symbolName = symbolData.symbol
      let name = OVERLAYS_CHARTS[patternName]
      let allChartOverlays = context.getters.allChartOverlays

      console.log("Talk is cheap,",{patternName,scanType,settings,symbolName,name,allChartOverlays,Overlays,symbolData})

      let isHeikenAshi = scanType === 'heikenAshi'
      let isBollingerSqueeze = scanType === 'bollingerSqueeze'
      let isDivergenceScan = scanType === 'divergenceScan'
      let isFranksPattern = scanType === 'franksPattern'
      let overlay_tv = Object.values(Overlays)
      let ChartPatternsOverlay = overlay_tv.find(
        (n) => n.name.toUpperCase() === 'ChartPatterns'.toUpperCase()
        )
      console.log("Test: ==>2",{isHeikenAshi,isBollingerSqueeze,isDivergenceScan,isFranksPattern,overlay_tv,ChartPatternsOverlay});
      if (isHeikenAshi) {
        await context.dispatch('setNewChartType',{type:'HeikenAshi',location:"From HeikenAshi"} )
      } else if (isDivergenceScan) {
        if ('data' in symbolData) {
          const presetSettings =
            ChartPatternsOverlay.methods.meta_info().preset.settings
          const chartPattern = {
            name: `ChartPatterns-${symbolName}`,
            type: 'ChartPatterns',
            data: [
              {
                patternName: symbolData.patternName,
                drawData: symbolData?.data?.elements,
              },
            ],
            scanResults: true,
            settings: { ...presetSettings },
          }
          // context.state.dc.data.onchart.push(chartPattern)
          chartEvents.$emit(
            'add_overlay_object',
            [chartPattern],
            'ChartPatterns'
          )
        }
      } else if (isBollingerSqueeze) {
        console.log("bollingerSqueeze==>");
        setTimeout(()=>{
          console.log("setTimeout bollinger");
              context.getters.dc.add(
                'onchart',
                {
                  name: 'BollingerBands',
                  type: 'BB',
                  data: [],
                  settings: {},
                },
              )
            },2000)
        console.log("bollingerSqueeze charts",context.state.dc, context.getters.dc,context.state.dc);
        context.state.dc.del('BollingerBands')
      }
      let isChartPattern = scanType === 'chartPatterns'
      let isHHL = scanType === 'hhhl'
      let isDarvasScans =
        scanType === 'samsPattern' || scanType === 'darvasScan'

      if (isChartPattern) {
      console.log("charting patterns")
        if ('drawingData' in symbolData && symbolData?.drawingData) {
          if (symbolData.drawingData && symbolData.drawingData.elements) {
            const presetSettings = ChartPatternsOverlay.methods.meta_info().preset.settings
            console.log("presetSettings==>",presetSettings);
            const chartPattern = {
              name: `ChartPatterns-${symbolName}`,
              type: 'ChartPatterns',
              data: [
                {
                  patternName: symbolData.patternName,
                  drawData: symbolData?.drawingData?.elements,
                },
              ],
              scanResults: true,
              settings: { ...presetSettings },
            }
            // setTimeout(()=>{
              // context.state.dc.data.onchart.push(chartPattern)
              // console.log("chartSetTimeout==>",context.state.dc);
            // },2000)
            chartEvents.$emit(
              'add_overlay_object',
              [chartPattern],
              'ChartPatterns'
            )
          }
        } 
        else if ('data' in symbolData && symbolData?.data) {
          if (symbolData.data && symbolData.data.elements) {
            const presetSettings =
              ChartPatternsOverlay.methods.meta_info().preset.settings
            const chartPattern = {
              name: `ChartPatterns-${symbolName}`,
              type: 'ChartPatterns',
              data: [
                {
                  patternName: symbolData.patternName,
                  drawData: symbolData?.data?.elements,
                },
              ],
              scanResults: true,
              settings: { ...presetSettings },
            }
            // context.state.dc.data.onchart.push(chartPattern)
            chartEvents.$emit(
              'add_overlay_object',
              [chartPattern],
              'ChartPatterns'
            )
          }
        }
      } 
      else if (isHHL) {
        if ('data' in symbolData) {
          const presetSettings =
            ChartPatternsOverlay.methods.meta_info().preset.settings
          const chartPattern = {
            name: `ChartPatterns-${symbolName}`,
            type: 'ChartPatterns',
            data: [
              {
                patternName: symbolData.patternName,
                drawData: symbolData?.data?.elements,
              },
            ],
            scanResults: true,
            settings: { ...presetSettings },
          }
          // context.state.dc.data.onchart.push(chartPattern)
          chartEvents.$emit(
            'add_overlay_object',
            [chartPattern],
            'ChartPatterns'
          )
        }
      } else if (isDarvasScans || isFranksPattern) {
        // drawing darvas
        // chartEvents.$emit('removeDarvas')
        function getDarvasDirection(mainPoints, riskPoints) {
          if (!riskPoints || !mainPoints) return true
          return (
            Math.min(mainPoints[0].value, mainPoints[1].value) >
            Math.min(riskPoints[0].value, riskPoints[1].value)
          )
        }
        const darvasDrawingList = []
        for (const element of symbolData?.data?.elements) {
          const points = element.points
          let mainPoints = null,
            riskPoints = null
          if (element.metatype === 'Main') {
            mainPoints = points
            riskPoints = symbolData.data.elements.find(
              (el) =>
                el.metatype === 'Risk' &&
                el.points[0].timeStamp === mainPoints[0].timeStamp &&
                el.points[1].timeStamp === mainPoints[1].timeStamp
            )?.points
          } else {
            riskPoints = points
            mainPoints = symbolData.data.elements.find(
              (el) =>
                el.metatype === 'Main' &&
                el.points[0].timeStamp === riskPoints[0].timeStamp &&
                el.points[1].timeStamp === riskPoints[1].timeStamp
            )?.points
          }
          const isBullish = getDarvasDirection(mainPoints, riskPoints)
          const darvasDrawing = {
            name: `Darvas-${symbolName}-${element.metatype}-${points[0].value}`,
            type: 'DarvasBox',
            data: [
              [
                element.metatype,
                points[0].timeStamp,
                points[1].timeStamp,
                points[0].value,
                points[1].value,
                element.metatype === 'Main'
                  ? 'rgba(39, 128, 227, 0.3)'
                  : 'rgba(245, 188, 195, 0.3)',
                isBullish,
              ],
            ],
            scanResults: true,
            settings: {
              legend: false,
            },
          }
          // context.state.dc.data.onchart.push(darvasDrawing)
          darvasDrawingList.push(darvasDrawing)
        }
        chartEvents.$emit('add_overlay_object', darvasDrawingList, 'DarvasBox')
      }

      if (isDivergenceScan) {
        name = OVERLAYS_CHARTS[settings.selectedScan]
      }

      chartEvents.$emit('remove_scan_overlay_new', name)
      let patternDataSet = ["CLOSE","MA","XMA","WMA"]

      let isCrossAboveSet = scanType==="indicatorsPatterns" && (settings?.crossesAndAboveBelow?.isActive)
          && patternDataSet.includes(patternName);
      console.log("Test: ==>3",{isCrossAboveSet,patternDataSet})
      if(isCrossAboveSet){
        
        let _overlay1,_overlay2;
        let _leftName=OVERLAYS_CHARTS[settings?.crossesAndAboveBelow?.left?.toUpperCase()]
        let _rightName=OVERLAYS_CHARTS[settings?.crossesAndAboveBelow?.right?.toUpperCase()]


        if(_leftName===_rightName){
          chartEvents.$emit('remove_scan_overlay_new', _leftName.toUpperCase(),true)
          setTimeout(() => {
            let config = allChartOverlays.find((n) => n.name === _leftName)
            const userSettings = config.getOverlayData(settings, scanType)
            _overlay1 = overlay_tv.find(
                (n) => n.name.toUpperCase() === _leftName.toUpperCase()
            )
            chartEvents.$emit(
                'add_overlay_new',
                _overlay1,
                _leftName,
                [{ ...userSettings,period:settings?.crossesAndAboveBelow?.leftPeriod }, { ...userSettings,period:settings?.crossesAndAboveBelow?.rightPeriod }],
                true
            )
          })
        }
        else{
          chartEvents.$emit('remove_scan_overlay_new', "",true)
          setTimeout(() => {
            if(_leftName){
              let config = allChartOverlays.find((n) => n.name === _leftName)
              const userSettings = config.getOverlayData(settings, scanType)
              _overlay1 = overlay_tv.find(
                  (n) => n.name.toUpperCase() === _leftName.toUpperCase()
              )
              chartEvents.$emit(
                  'add_overlay_new',
                  _overlay1,
                  _leftName,
                  { ...userSettings,period:settings?.crossesAndAboveBelow?.leftPeriod },
                  true
              )
            }

            if(_rightName){
              let config = allChartOverlays.find((n) => n.name === _rightName)
              const userSettings = config.getOverlayData(settings, scanType)
              _overlay2 = overlay_tv.find(
                  (n) => n.name.toUpperCase() === _rightName.toUpperCase()
              )
              chartEvents.$emit(
                  'add_overlay_new',
                  _overlay2,
                  _rightName,
                  { ...userSettings,period:settings?.crossesAndAboveBelow?.rightPeriod },
                  true
              )
            }
          })
        }
      }else{
        if (name) {
          console.log("Test Name==>",name);
          let config = allChartOverlays.find((n) => n.name === name)
          let overlay = overlay_tv.find(
              (n) => n.name.toUpperCase() === name.toUpperCase()
          )
          console.log("Test: ==>config",{config,overlay});
          if (config.getOverlayData) {


            // else{
            const userSettings = config.getOverlayData(settings, scanType)
            chartEvents.$emit(
                'add_overlay_new',
                overlay,
                name,
                { ...userSettings },
                true
            )
            // }
          }
        }
      }

      // await context.dispatch('setChartRange', context.state.chartVisibleRange)
      if (scanOverlay) {
        return scanOverlay
        // context.commit('SCAN_OVERLAYS',scanOverlay)
        // context.commit('SHOW_OVERLAYS',true)
      } else {
        return null
      }
    },
    async changeSymbolToScannedSymbol(context,{ symbolData, timeFrame, scanType, settings }) {
      
      console.log("changeSymbolInstore");
      context.state.SelectedScanSymbol = symbolData.symbol
      // Check ScanType
      
      // const symbolName = symbolData.symbol ? symbolData.symbol : symbolData
      // await context.dispatch('setSymbolData', {
      //   symbolName,
      //   timeFrame,
      //   scanResultData: true,
      // })
      // chartEvents.$emit('setChartRange', undefined, 'ScannedSymbolSelect')
      let val = await context.dispatch('addDrawingForScannedSymbol', {
        symbolData,
        scanType,
        settings,
      })
      console.log("valueOffDrawing==>",val);
      chartEvents.$emit('loadDrawingData')
      // if we are not drawing anything
      if (val) {
        return val
      }
      return false
    },
    async updateDrawing(context, payload) {
      if (context.state.drawingData.hasOwnProperty(payload.ref)) {
        context.state.drawingData[payload.ref] = payload.data
      } else {
        context.state.drawingData[payload.ref] = payload.data
      }
      let newMapData = {}
      for (let key in context.state.drawingData) {
        let data = JSON.parse(context.state.drawingData[key])

        if (data?.settings?.$uuid === key) {
          newMapData[key] = context.state.drawingData[key]
        }
      }
      let data = {
        symbolName: context.state.currentSymbol.symbol,
        data: {
          modeId: payload.ref,
          data: payload.data,
          type: payload?.type,
          newTool: payload?.newTool,
          pointsData: payload?.pointsData,
        },
      }

      // await updateDrawingData(data)

      await updateDrawingDataV2({ ...data, userId: context.state.userId })
      context.state.drawingData = newMapData
      return true
    },

    async updateIndicator(context, payload) {
      let res = {
        indicatorsSetting: JSON.stringify(payload),
        userId: context.state.userId,
      }
      await updateIndicatorRequest(res)
    },

    setChartSettingValue(context,payload){
       context.commit('SET_CHARTSETTING_VALUE', payload)
    },

    // gurus requests
    resetGurusSettings(context) {
      context.commit('RESET_GURUS')
    },
    async sendCapitulationRequest(context) {
      try {
        const settings = {
          ...context.state.scans.gurus.capitulation,
          tci: context.state.indicatorDefaults.tci,
        }
        const result = await gurus.capitulation(
          settings,
          context.state.scans.gurus.capitulation.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return error
      }
    },
    async sendHigherHighHigherLowPatternRequest(context) {
      try {
        const settings = context.state.scans.gurus.hhhl.dataSettings
        const result = await gurus.higherHighHigherLow(
          settings,
          context.state.scans.gurus.hhhl?.selectedMarket,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendBigCandleScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.bigCandle
        const result = await gurus.bigCandleScan(
          settings,
          context.state.scans.gurus.bigCandle.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendHeikenAshiScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.heikenAshi
        const result = await gurus.heikenAshiScan(
          settings,
          context.state.scans.gurus.heikenAshi.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendBollingerSqueezePatternRequest(context) {
      try {
        const settings = context.state.scans.gurus.bollingerSqueeze
        const result = await gurus.bollingerSqueeze(
          settings,
          context.state.scans.gurus.bollingerSqueeze.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },

    cancelGuruScans() {
      gurus.cancelGuruScan()
    },

    updateTogglePlay(context, payload) {
      context.state.togglePlayBtn = !context.state.togglePlayBtn
      if (context.state.togglePlayBtn) {
        if (!context.state.intervalId) {
          context.state.intervalId = setInterval(() => {
            let val =
              context.getters.trainingModeStartingIndex +
              (context.getters.candleIndexJumpSelected?.index -
                context.getters.trainingOffset)
            if (val < context.getters.formattedSymbolData?.length - 1) {
              context.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
                time: null,
                index: context.getters.candleIndexJumpSelected.index + 1,
                chartHit: false,
              })
            } else {
              clearInterval(context.state.intervalId)
              context.state.intervalId = null
            }
          }, 1000)
        }
      } else {
        clearInterval(context.state.intervalId)
        context.state.intervalId = null
      }
    },

    updateTrainingModeState(context, payload) {
      // const { state: trainingModeActivated } = payload
      const trainingModeActivated = payload.state
      console.log('payload updateTrainingModeState==>', payload)
      // when the training mode is deactivated, reset the chart
      if (!trainingModeActivated) {
        context.state.dc.set('chart.data', context.getters.getSymbolData)
        chartEvents.$emit(
          'setChartRange',
          context.state.chartVisibleRange,
          'TrainingModeRangeReset'
        )
      } else {
        console.log('inside else')
        // context.getters.dc.tv.resetChart(true)
        const trainingModeDataRaw = payload.trainingModeDataRaw
        const trainingModeData = payload.trainingModeData
        context.commit('UPDATE_TRAINING_MODE_DATA', {
          data: trainingModeData,
          raw: trainingModeDataRaw,
        })
      }
      context.commit('SET_TRAINING_MODE', payload)
    },

    updatedWatchlist(context, payload) {
      context.commit('UPDATE_WATCHLIST', payload)
    },
    updateCopySymbol(context, payload) {
      context.commit('UPDATE_COPY_SYMBOL', payload)
    },

    updateArrow(context, payload) {
      context.commit('UPDATE_ARROW', payload)
    },
    updateAppSetting(context, payload) {
      context.commit('UPDATE_APP_SETTING', payload)
    },
    updateSymbolName(context, payload) {
      context.commit('UPDATE_SYMBOL', payload)
    },

    updateChartSetting(context, payload) {
      context.commit('UPDATE_CHART_SETTING', payload)
    },
    setChartVisibleRange(context, payload) {
      context.commit('CHART_VISIBLE_RANGE', payload)
    },
    updateSettings(context, payload) {
      context.commit('UPDATE_SETTINGS', payload)
    },
    updateOhlcData(context, payload) {
      context.commit('UPDATE_SYMBOL_DATA', payload)
    },
    updateRemoveIndicatorName(context, payload) {
      context.commit('UPDATE_REMOVE_INDICATOR', payload)
    },
    goToDateChart(context, timeStamp) {
      console.log('store', timeStamp)
      context.state.tradingChartReference.goto(timeStamp)
    },
    selectedVariant({ commit }, payload) {
      commit('setVariant', payload)
    },
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === false ? true : false
      commit('setTheme', newTheme)
    },
    toggleChartReset({ commit }, payload) {
      commit('setChartReset', payload)
    },
    toggleChartMove({ commit }, payload) {
      commit('setChartMove', payload)
    },
    updateTheme({ commit }, payload) {
      commit('UPDATE_THEME', payload)
    },
    updateIndicatorList({ commit, state }, indicator) {
      commit('UPDATE_INDICATOR_LIST', indicator)
    },
    toggleIndicator({ commit, state }, indicator) {
      const exists = state.selectedIndicator?.find(
        (ind) => ind?.name === indicator?.name
      )
      if (exists) {
        commit('REMOVE_INDICATOR', indicator)
      } else {
        commit('ADD_INDICATOR', indicator)
      }
    },
    async setNewChartType(context, payload) {
  
      try {
        let userData = {
          ...context.state.userData,
        }
        userData['chartType'] = payload?.type
        userData['symbolMap'] = payload?.symbolMap
        // context.commit('SET_CHART_TYPE', payload?.type)
       
        // console.log('updateUserSettings',userData)
        const result = await updateUserSettings(
          {
            userData: userData,
          },
          'setNewChartType'
        )
       
        context.state.userData = userData
        context.commit('SET_CHART_TYPE', payload?.type)
        return result.updated
      } catch (error) {
        return null
      }
    },
    async getSymbolList(context, payload) {
      const symbolList = await symbol.getListOfSymbols()
      context.commit('UPDATE_SYMBOLS_LIST', symbolList?.symbolList)
      let updatedArr = symbolList?.symbolList?.map((item) => item?.split('~')[0])
      context.commit('getSymbolListData', updatedArr)
    },

    async updateSymbolPrice(context, payload) {
      context.commit('UPDATE_PRICE_LOADING', true)
      const res = await symbol.symbolPrice(payload)
      if (res?.data) {
        context.commit('UPDATE_SYMBOLS_PRICE', res?.data)
        context.commit('UPDATE_PRICE_LOADING', false)
      }
      context.commit('UPDATE_PRICE_LOADING', false)
    },
    handleNextSymbol(context, payload) {
      let symbolIndex = context.state.symbolNameList?.indexOf(
        context.state.selectedSymbol
      )
      let nextSymbol = context.state.symbolNameList?.[symbolIndex + 1]
      if (nextSymbol) {
        context.commit('UPDATE_SYMBOL', nextSymbol)
      }
    },
    handlePreviousSymbol(context, payload) {
      let symbolIndex = context.state.symbolNameList?.indexOf(
        context.state.selectedSymbol
      )
      let prevSymbol = context.state.symbolNameList?.[symbolIndex - 1]
      if (prevSymbol) {
        context.commit('UPDATE_SYMBOL', prevSymbol)
      }
    },

    async updateScansSettingForLatestScans(context, payload) {
      console.log("latestScans")
      try {
        let userData = { ...context.state.userData }
        const savedScansLatest = localStorage.getItem('ScanRequests')
        userData['ScanRequests'] = savedScansLatest
        const result = await updateUserSettings({ userData: userData }, "saveLatestScansForScans")
        context.state.userData['ScanRequests'] = savedScansLatest
        console.log("try block");
        return result.updated
      } catch (error) {
        return null
      }
    },

    async updateProfileData(context, payload) {
      context.commit('SET_USER_DATA', payload)
      if ('GuruRequests' in payload) {
        localStorage.setItem(
          'GuruRequests',
          JSON.stringify(JSON.parse(payload.GuruRequests))
        )
      } else {
        localStorage.setItem('GuruRequests', '{}')
      }

      if ('ScanRequests' in payload) {
        localStorage.setItem(
          'ScanRequests',
          JSON.stringify(JSON.parse(payload.ScanRequests))
        )
      } else {
        localStorage.setItem('ScanRequests', '{}')
      }

      if ('chartType' in payload) {
        context.commit('SET_CHART_TYPE', payload['chartType'])
        if (payload['chartType'] === 'CompareChartMain') {
          localStorage.setItem('isComparisonChart', 'true')
        }
      }

      if ('symbol' in payload) {
        localStorage.setItem('symbol', payload.symbol)
      }
      if ('symbolMap' in payload) {
        context.commit('changeSymbol', {
          propName: 'symbol1',
          propValue: payload.symbolMap?.symbol1 || '',
        })
        context.commit('changeSymbol', {
          propName: 'symbol2',
          propValue: payload.symbolMap?.symbol2 || '',
        })
        context.commit('changeSymbol', {
          propName: 'symbol3',
          propValue: payload.symbolMap?.symbol3 || '',
        })
        context.commit('changeSymbol', {
          propName: 'symbol4',
          propValue: payload.symbolMap?.symbol4 || '',
        })
      }

      if ('timeFrame' in payload) {
        context.commit('SET_TIME_FRAME', payload['timeFrame'])
      }
      if ('proTrader.chart.0.indicators' in payload) {
        context.commit('SET_USER_CHART_LIST', {
          data: payload['proTrader.chart.0.indicators'],
        })
      }

      if ('proTrader.themeSettings' in payload) {
        let themeSettings = payload['proTrader.themeSettings']
        if (!isThemePrimary(themeSettings)) {
          context.commit('UPDATE_CUSTOM_THEME', themeSettings.settings)
          // context.commit('UPDATE_DEFAULT_THEME', '')
        } else {
          const id = payload['proTrader.themeSettings'].id
          // console.log("proTrader.themeSettings",id)
          context.commit('UPDATE_DEFAULT_THEME', id)
          if ('dark' === id) {
            context.commit('THEME_MODE', true)
          }
          // context.dispatch('toggleDarkMode',id)
        }
      }
    },
    async getUserWatches(context, payload) {
      let watches = await getWatchListData(context.state.userId)
      // console.log("watches ",watches)
      if (watches && watches.data) {
        context.state.watchLists = watches.data
        context.state.selectedWatchlist = watches.data[0]
        context.state.watchListsData = watches?.data[0]?.symbolList
      }
    },
    async getUserScanList(context) {
      let scannerList = await scanner.getScanList(context.state.userId)
      console.log("payload list", scannerList)
      let scannerListForStore = []

      // console.log('scannerList ', scannerList)
      if (scannerList && scannerList.data) {
        for (const scan of scannerList.data) {
          let newObj = scan
          newObj.data = JSON.parse(scan.data)

          scannerListForStore.push(newObj)
        }
        context.commit('UPDATE_USER_SCAN_LIST', scannerListForStore)
        // context.state.userSavedScans = scannerListForStore
      }
    },

    async createNewWatchList(context, payload, callback) {
      console.log('createwatchlist==>', payload)
      let array = []
      if (context.state.copySymbol?.length > 0) {
        array = context.state.copySymbol
      }
      if (payload?.ScanResults) {
        console.log('payload.ScanResults ', payload, callback)
        for (const iterator in payload.ScanResults) {
          if (
            payload.ScanResults[iterator].scanId == context.state.SelectedScanId
          ) {
            for (const i of payload.ScanResults[iterator].results) {
              array.push({ name: i.symbol, id: context.state.marketId })
            }
            break
          }
        }
      }
      let watches = {
        symbolList: array,
        name: payload?.name,
        userId: context.state.userId,
      }
      console.log('watches==>', watches)

      let newWatch = await saveWatches(watches)
      console.log('newWatch==>', newWatch)
      if (newWatch) {
        context.state.watchLists = newWatch?.data
      }
      // if (payload.updateModal) {
      //     context.state.showWatchesModal = !context.state.showWatchesModal
      // }
      return newWatch
    },

    async updateWatchList(context, payload) {
      let newWatch = await saveWatches(payload.value)
      console.log('newWatch updateWatchList', newWatch)
      if (newWatch) {
        // console.log('watch response updated')
        context.state.watchLists = newWatch?.data
        return true
      } else {
        return false
      }
    },

    async deleteWatchList(context, payload) {
      let deleteWatchListRes = await deleteWatchList(payload)
      if (!deleteWatchListRes.isError) {
        let newWatchList = context.state.watchLists.filter(
          (s) => s.id !== payload
        )
        console.log('new watch', newWatchList)
        let watchListDataObj = newWatchList[0]
        context.state.watchLists = newWatchList
        if (watchListDataObj) {
          context.state.selectedWatch = watchListDataObj.id
          context.state.selectedWatchCode = 0
          context.state.selectedWatchlist = watchListDataObj
          context.state.watchListsData = watchListDataObj.symbolList
        } else {
          context.state.selectedWatch = ''
          context.state.selectedWatchCode = ''
          context.state.watchListsData = ''
          context.state.selectedWatchlist = null
        }
      }
    },

    async setSelectedWatch(context, payload) {
      let watchListDataObj = context.state.watchLists[payload]
      console.log('watchListDataObj', watchListDataObj, payload)
      if (watchListDataObj && watchListDataObj.symbolList) {
        // console.log('list updates')
        context.state.selectedWatch = watchListDataObj.id
        context.state.selectedWatchCode = payload
        context.state.selectedWatchlist = watchListDataObj
        context.state.watchListsData = watchListDataObj.symbolList
      }
    },

    async renameWatchListItem(context, payload) {
      console.log("renameWatchlist==>", payload);
      let newWatch = context.getters.selectedWatchData
      if (newWatch) {
        newWatch.newName = payload
        let newWatchRes = await saveWatches(newWatch)
        if (newWatchRes) {
          context.state.watchLists = newWatchRes?.data
          const index = newWatchRes?.data.findIndex(
            (item) => item.id === newWatch.id
          )
          context.dispatch('setSelectedWatch', index)
          return newWatchRes
        }
      }
      // context.state.showWatchRenameModal = !context.state.showWatchRenameModal
    },


    async savePatternsScanRequest(context, name) {
      console.log("payload", context.state.scans.scanner.patterns.settings,
        context.state.scans.scanner.patterns.filterSettings,
        context.state.scans.scanner.patterns.dataSettings,
        context.state.scans.scanner.patterns.eventsSettings,
        context.state.userId,
        name)
      try {
        const result = await scanner.savePatternsScan(
          context.state.scans.scanner.patterns.settings,
          context.state.scans.scanner.patterns.filterSettings,
          context.state.scans.scanner.patterns.dataSettings,
          context.state.scans.scanner.patterns.eventsSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        return null
      }
    },

    async saveActivityScanRequest(context, name) {
      try {
        const result = await scanner.saveActivityScan(
          context.state.scans.scanner.activity.settings,
          context.state.scans.scanner.activity.filterSettings,
          context.state.scans.scanner.activity.dataSettings,
          context.state.userId,
          name
        )

        return result.data
      } catch (error) {
        return null
      }
    },

    async saveCandlestickScanRequest(context, name) {
      try {
        const result = await scanner.saveCandlestickScan(
          context.state.scans.scanner.candlesticks.settings,
          context.state.scans.scanner.candlesticks.filterSettings,
          context.state.scans.scanner.candlesticks.dataSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        return null
      }
    },

    async saveIndicatorsScanRequest(context, name) {
      try {
        const result = await scanner.saveIndicatorsScan(
          context.state.scans.scanner.indicators.settings,
          context.state.scans.scanner.indicators.filterSettings,
          context.state.scans.scanner.indicators.dataSettings,
          context.state.scans.scanner.indicators.eventsSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        return null
      }
    },

    async saveDarvasScanRequest(context, name) {
      try {
        const result = await scanner.saveDarvasScan(
          context.state.scans.scanner.darvasScan.settings,
          context.state.scans.scanner.darvasScan.filterSettings,
          context.state.scans.scanner.darvasScan.dataSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        return null
      }
    },

    async saveFundementalScanRequest(context, name) {
      try {
        const result = await scanner.saveFundementalScan(
          context.state.scans.scanner.fundementals.settings,
          context.state.scans.scanner.fundementals.filterSettings,
          context.state.scans.scanner.fundementals.dataSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        return null
      }
    },

    async saveCapitulationScanRequest(context, name) {
      try {
        const settings = {
          ...context.state.scans.gurus.capitulation,
          tci: context.state.indicatorDefaults.tci,
        }
        const result = await gurus.saveCapitulationScan(
          settings,
          context.state.scans.gurus.capitulation.dataSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        console.log(error);
      }
    },

    async addNewSymbolToList(context, payload) {
      let newWatch = context.getters.getSelectedWatchlist
      console.log('payload', payload, newWatch)
      if (newWatch) {
        newWatch.symbolList = payload
        let newWatchRes = await saveWatches(newWatch)
        console.log('newWatchRes', newWatchRes)
        if (newWatchRes) {
          let updatedList = newWatchRes?.data?.find((d) => d.id === newWatch.id)
          context.state.watchLists = newWatchRes?.data
          context.state.watchListsData = updatedList?.symbolList
          context.state.selectedWatchlist = updatedList
          return newWatchRes
        } else {
          return false
        }
      }
    },

    async addNewSymbolToUnselectWatchlist(context, payload) {
      let newWatch = payload?.data
      let selectedWatch = context.getters.getSelectedWatchlist
      if (newWatch) {
        newWatch.symbolList = payload?.list
        let newWatchRes = await saveWatches(newWatch)
        if (newWatchRes) {
          let updatedList = newWatchRes?.data?.find((d) => d.id === newWatch.id)
          context.state.watchLists = newWatchRes?.data
            if (updatedList?.id == selectedWatch?.id) {
              context.state.watchListsData = updatedList?.symbolList
              context.state.selectedWatchlist = updatedList
            }
          return newWatchRes
        } else {
          return false
        }
      }
    },

    setChartLoadingStatus(context, status) {
      context.commit('SET_CHART_LOADING_STATUS', status)
    },

    setEmojiIcon(context, icon) {
      context.commit('SET_EMOJI_ICON', icon)
    },

    async setNewChartTimeFrame(context, payload) {
      try {
        let userData = {
          ...context.state.userData,
        }
        userData['timeFrame'] = payload.timeFrame
        if (payload?.symbol) userData['symbol'] = payload?.symbol
        // console.log('updateUserSettings',userData)
        context.commit('SET_TIME_FRAME', payload.timeFrame)
        const result = await updateUserSettings(
          {
            userData: userData,
          },
          'setNewChartTimeFrame'
        )
        return result.updated
      } catch (error) {
        return null
      }
    },

    async setSymbolData(context, payload) {
      console.log('setSymbolData', payload, context.state.chartType)
      //--- check if we are already on the same symbol
      let isComparision =
        payload?.isComparison || context.state.chartType === 'CompareChartMain'
      if (!payload.reForce) {
        if (
          payload.symbolName.length > 0 &&
          payload.symbolName === context.state.currentSymbol.symbol &&
          payload.timeFrame === context.state.currentSymbol.timeFrame
        ) {
          // console.log('failing to fetch')
          return
        }
      }

      //TODO:CHANGE_EFFECTS
      // remove chart drawings
      // context.getters.dc.del('Tool')
      // context.getters.dc.del('Fibonacci')
      // context.getters.dc.del('Trend')
      // context.getters.dc.tv.custom_event({
      //   event: 'remove-all-tools-event',
      //   args: [],
      // })
      //--- set loading status
      // console.log("OVERLAYS_CHARTS payload",payload)
      context.dispatch('setNewChartTimeFrame', {
        timeFrame: payload?.timeFrame,
        symbol: payload?.symbolName?.length ? payload.symbolName : '',
      })
      if (!payload.scanResultData) {
        chartEvents.$emit('remove_scan_overlay_new', null, true)
      }
      console.log('delete all tools')
      chartEvents.$emit('deleteAllTools')
      await context.dispatch('setChartLoadingStatus', true)

      //--- delete the existing objects
      if (payload.symbolName !== context.state.currentSymbol.symbol) {
        // await context.dispatch('removeAllObjects')
      }
      const searchElement = 'BHP.au~ASX'
      let containBHP = context.state.symbolsList?.includes(searchElement)
      let symbolName = payload.symbolName || getLastSymbol()
      // console.log('symbolName',symbolName)
      if (!symbolName) {
        if (containBHP) {
          symbolName = searchElement
        } else {
          symbolName = context.state.symbolsList?.[0]
        }
      }

      let marketId = 'ASX'
      // console.log('symbolName ==',symbolName)
      let symbolArray = symbolName?.split('~')
      if (symbolArray && symbolArray.length) {
        symbolName = symbolArray[0]
        marketId = symbolArray[1]
      }

      // let comparisionChart =
      //   context.getters.dc.data.chart.type === 'CompareChartMain'
      let symbolData = {}
      let multipleSymbol = {}
      let rangeMap
      // console.log("comparisionChart",isComparision)
      if (isComparision) {
        console.log("isComparision",isComparision)
        console.log('timeframe', payload, context.state.currentSymbol)
        const timeFrame =
          payload.timeFrame || context.state.currentSymbol.timeFrame
        const symbolList = Object.values(
          context.getters.getSelectedMultipleSymbols
        ).filter((str) => str)
        // const symbolName = context.rootGetters.
        console.log('time==>', TimeRangesConfig, timeFrame)
        let config = TimeRangesConfig[timeFrame]
        rangeMap = config?.map((d) => d.range)
        console.log("call 1")
        multipleSymbol = await symbol.getMultipleSymbolData(
          symbolList,
          timeFrame,
          symbolName,
          rangeMap
        )
        symbolData = multipleSymbol.main
      } else {
        let marketOpened = getMarketTime().isMarketOpened
        let intradayMode = context.state.intradayMode
        const isIntraday = intradayMode && marketOpened
        symbolData = await symbol.getData(
          symbolName,
          payload.timeFrame,
          isIntraday,
          marketId,
          context.state.minusIndex
        )
      }

      if ('Error' === symbolData?.symbol) {
        //--- set loading status
        context.dispatch('setChartLoadingStatus', false)
        context.dispatch('toggleSymbolMissing')
        const ohlcvData = parseToChartCandleData([currentSymbolDefaults])[0]
        context.state.dc.set('chart.data', ohlcvData)

        context.commit('SET_CURRENT_SYMBOL', {
          ...currentSymbolDefaults,
          symbol: symbolName,
        })
        return false
      } else {
        let symIdentifier =
          symbolData?.symbol + '~' + symbolData?.metaData?.exchange
        context.commit('SET_CURRENT_SYMBOL', symbolData)
        context.commit('SET_MARKET_ID', symbolData?.metaData?.exchange)
        setLastSymbol(symIdentifier)
        let marketOpened = getMarketTime().isMarketOpened
        let intradayMode = context.state.intradayMode
        const isIntraday = intradayMode && marketOpened

        const len = symbolData?.data?.length - 1
        if (isIntraday && symbolData?.latestCandle && symbolData?.lastCandle) {
          let latestDateApi = symbolData?.latestCandle?.date
          let lastCandleDate = symbolData?.lastCandle.date
          if (latestDateApi && lastCandleDate) {
            if (!moment(latestDateApi).isSame(moment(lastCandleDate))) {
            }
          }
        }
        let [parsedData, ohlcvMap] = parseToChartCandleData(symbolData?.data)
        context.commit('SET_OHLCV', parsedData)
        context.commit('SET_CANDLE_DATA_TIME_MAP', ohlcvMap)

        if (symbolData?.health) {
          symbolData.health = Math.random() * 100
        }
        context.commit('SET_CURRENT_SYMBOL', symbolData)
        const selectedSymbolIndex = context.state.symbolsList.findIndex(
          (sym) => sym === symIdentifier
        )

        if (selectedSymbolIndex !== -1) {
          context.commit('UPDATE_SYMBOL_INDEX', selectedSymbolIndex)
        }

        if (payload.timeFrame !== context.state.timeFrame) {
          context.commit('SET_SYMBOL_TIME_FRAME', payload.timeFrame || 'D')
        }

        if (isComparision) {
          context.commit('setMultiSymbolData', multipleSymbol)
          context.dispatch('updateMultipleSymbolData2', {
            data: multipleSymbol,
            rangeMap,
          })

          context.dispatch('setChartLoadingStatus', false)
        } else {
          context.dispatch('setChartLoadingStatus', false)
        }

        return true
      }
    },

    async toggleSymbolMissing(context) {
      context.state.symbolIsMissing = !context.state.symbolIsMissing
    },

    async removeCurrentSymbolFromList(context, payload) {
      let newWatch = context.getters.getSelectedWatchlist
      let filterData = newWatch?.symbolList.filter(
        (item) => item.name != payload
      )
      console.log('payload', payload, newWatch)
      if (newWatch) {
        newWatch.symbolList = filterData
        let newWatchRes = await saveWatches(newWatch)
        console.log('newWatchRes', newWatchRes)
        if (newWatchRes) {
          let updatedList = newWatchRes?.data?.find((d) => d.id === newWatch.id)
          context.state.watchLists = newWatchRes?.data
          context.state.watchListsData = updatedList?.symbolList
          context.state.selectedWatchlist = updatedList
          return newWatchRes
        } else {
          return false
        }
      }
      // let currentList = context.getters.selectedWatchData
      // let alreadyInList = currentList.symbolList.find(
      //     (s) => s.id === payload.id && s.name === payload.name
      // )
      // if (alreadyInList) {
      //     const _index = currentList.symbolList.findIndex(
      //         (s) => s.id === payload.id && s.name === payload.name
      //     )
      //     currentList.symbolList.splice(payload.index, 1)
      //     await saveWatches(currentList)
      //     context.watchLists = currentList
      //     if (_index == currentList.symbolList.length) return currentList.symbolList.length - 1
      //     return payload.index //-1
      // }
      // return payload.index //-1
    },

    async removeSymbolFromUnselectWatchlist(context, payload) {
      let newWatch = payload?.data
      let selectedWatch = context.getters.getSelectedWatchlist
      let filterData = newWatch?.symbolList.filter(
        (item) => item.name != payload.symbol
      )
      if (newWatch) {
        newWatch.symbolList = filterData
        let newWatchRes = await saveWatches(newWatch)
        if (newWatchRes) {
          let updatedList = newWatchRes?.data?.find((d) => d.id === newWatch.id)
          context.state.watchLists = newWatchRes?.data
          if (updatedList?.id == selectedWatch?.id) {
            context.state.watchListsData = updatedList?.symbolList
            context.state.selectedWatchlist = updatedList
          }
          return newWatchRes
        } else {
          return false
        }
      }
    },

    // async setSelectedWatchCode(context, payload) {
    //   if (
    //       payload.name &&
    //       payload.name.length > 0 &&
    //       payload.name !== context.state.currentSymbol.symbol
    //   ) {
    //       await context.dispatch('setSymbolData', {
    //           symbolName: payload.name,
    //           timeFrame: localStorage.getItem("SET_TIME_FRAME") || "D"
    //       })
    //       chartEvents.$emit('setChartRange', undefined, 'WatchSymbolSelect')
    //       chartEvents.$emit('symbolChangeVerifyScanDetails')
    //       chartEvents.$emit('loadDrawingData')
    //   }
    //   context.state.selectedWatchSymbolCode = payload.id
    // },
  },
  getters: {
    getNameOfScan: (state) => state.scanName,
    getIsShowProgressiveBar: (state) => state.isShowProgressBar,
    getScannerRightbarOpen: (state) => state.openScannerRightBar,
    getTheme: (state) => state.theme,
    getChartReset: (state) => state.chartReset,
    getChartMove: (state) => state.chartMove,
    getScannerModal: (state) => state.openScannerModal,
    getSelectedIndicators: (state) => state.selectedIndicator,
    candleIndexSelected: (state) => state.candleIndexSelected,
    getChartType: (state) => state.chartType,
    getCurrentDrawing: (state) => state.currentDrawing,
    symbolIsMissing: (state) => state.symbolIsMissing,
    getSelectedVariant: (state) => state.variants,
    tv: (state) => state.tradingChartReference,
    dc: (state) => state.tradingChartReference?.data,
    barTrendChart: (state) => state.barTrendChart,
    getChartSettingValue: (state) => state.chartSettingValue,
    isTrainingMode: (state) => state.trainingMode,
    priceLoading: (state) => state.priceLoading,
    trainingOffset: (state) => state.trainingOffset - 1,
    getSymbolData: (state) => state.currentSymbolData,
    drawingData: (state) => state.drawingData,
    formattedSymbolData: (state) => state.currentSymbol.data,
    ohlcv: (state) => state.ohlcv,
    getSymbolName: (state) => state.selectedSymbol,
    getRemoveIndicatorName: (state) => state.removeIndicatorName,
    getUpdatedSettings: (state) => state.updatedSettings,
    getCurrentChartSetting: (state) => state.currentChartSetting,
    getArrow: (state) => state.isArrowOpen,
    trainingModeStartingIndexSubset: (state) =>
      state.trainingModeStartingIndexSubset,
    getAppSetting: (state) => state.appSetting,
    compareChartSymbols: (state) => {
      return state.compareChartSymbols
  },
  mainChartProps: (state) => {
    return state.mainChartProps
},
    getAllWatchlist: (state) => state.watchlistData,
    getLatestWatchlist: (state) => state.latestWatchlist,
    getSymbolDetail: (state) => state.currentSymbol,
    getSymbolPrice: (state) => state.symbolPriceData,
    topListSymbol: (state) => state.topListSymbol,
    topListSymbolName: (state) => state.topListSymbolName,
    chartSettings: (state) => state.chartSettings,
    defaultChartSettings: (state) => state.defaultChartSettings,
    chartSettingUpdatedDate: (state) => state.chartSettingUpdatedDate,
    watchLists: (state) => state.watchLists,
    userData: (state) => state.userData,
    getUserId: (state) => state.userId,
    getTrialStartDate: (state) => state.userTrialStart,
    getUserEmail: (state) => state.emailId,
    getUserFirstName: (state) => state.firstName,
    getUserSubscriptionDetail : (state)=>state.userSubscriptionDetail,
    getUserLastName: (state) => state.lastName,
    userSavedScans: (state) => state.userSavedScans?.reverse(),
    getSingleIndicator: (state) => {
      return state.userData.indicatorSettings.single
    },
    getPercentageCount: (state) => state.progressPer,
    scanResults: (state) => state.scans.results,
    getAdditionalIndicator: (state) => {
      return state.userData.indicatorSettings.additional
    },
    getMultiIndicator: (state) => {
      return state.userData.multi
    },
    getScanResult: (state) => state.scanResult,
    getScanList: (state) => state.scanList,
    selectedWatch: (state) => state.selectedWatch,
    trainingModeStartingIndex: (state) => state.trainingModeStartingIndex,
    getMarketId: (state) => state.marketId,
    trainingModeDataRaw: (state) => {
      return state.trainingModeDataRaw
    },
    trainingModeData: (state) => {
      return state.trainingModeData
    },
    selectedWatchCode: (state) => state.selectedWatchCode,
    getCopySymbol: (state) => state.copySymbol,
    selectedDrawingID: (state) => state.selectedDrawingID,
    getEmojiIcon: (state) => state.emojiIcon,
    getCheckCount: (state) => state.checkCount,
    getChartCursor: (state) => state.chartCursor,
    drawingDataList: (state) => state.drawingDataList,
    isChartLoading: (state) => state.isChartLoading,
    getSymbolByIndex: (state) => state.symbolsList[state.symbolIndex],
    ohlcvTimeMap: (state) => state.ohlcvTimeMap,
    SelectedScanSymbol: (state) => state.SelectedScanSymbol,
    symbolName: (state) => state.currentSymbol.symbol,
    timeFrame: (state) => state.timeFrame,
    allChartOverlays: (state) => [
      ...state.chartOverlays.trendCharts.data,
      ...state.chartOverlays.momentumCharts.data,
      ...state.chartOverlays.volatilityCharts.data,
      ...state.chartOverlays.plusCharts.data,
    ],
    getChartVisibleRange: (state) => state.chartVisibleRange,
    selectedWatchSymbolCode: (state) => state.selectedWatchSymbolCode,
    getGeneralUserSettings: (state) => {
      // console.log("indicatorSettings from store")
      return state.userData.generalSettings
    },
    getSectorGroup: (state) => state.sectorGroup,
    selectedMarket: (state) => state.selectedMarket,
    comparisionIndex: (state) => {
      return state.comparisionIndex
  },
     chartLowerRange: (state) => {
            return state.chartLowerRange
        },
        chartUpperRange: (state) => {
          return state.chartUpperRange
      },
    // Scannner getters
    dataSettings: (state) => state.scans.dataSettings,
    filterSettings: (state) => state.scans.filterSettings,
    dataSettingsForScan(state) {
      return (scanId, scanType) => {
        if (scanId && scanType === 'scanner') {
          return state.scans.scanner[scanId]?.dataSettings
        } else if (scanId && scanType === 'guru') {
          return state.scans.gurus[scanId]?.dataSettings
        } else return state.scans.dataSettings
      }
    },
    selectedMarketForScan(state) {
      return (scanId, scanType) => {
        if (scanType === 'scanner') {
          return state.scans.scanner[scanId]?.selectedMarket
        } else if (scanType === 'guru') {
          return state.scans.gurus[scanId]?.selectedMarket
        }
      }
    },
    getSymbolCountLength: (state) => state.symbolCountLength,
    capitulationSettings: (state) => state.scans.gurus.capitulation,
    gurusScanSettings: (state) => state.scans.gurus,
    getCount: (state) => state.count,
    getTotalCount: (state) => state.totalCount,
    watchListsData: (state) => state.watchListsData,
    getShowDescription: (state) => state.showDescription,
    candleIndexJumpSelected: (state) => state.candleIndexJumpSelected,
    isIntradayMode: (state) => state.intradayMode,
    getTogglePlay: (state) => state.togglePlayBtn,
    getSelectedWatchlist: (state) => state.selectedWatchlist,
    getEventsSettings(state) {
      return (scanId) => {
        return state.scans.scanner[scanId].eventsSettings
      }
    },
    getIndicatorSetting: (state) => state.scans.scanner.indicators.settings,
    getDarvasBoxSetting: (state) => state.scans.scanner.darvasScan.settings,
    getFundementalSetting: (state) => state.scans.scanner.fundementals.settings,
    getPocketPivot: (state) => state.pocketPivotToggle,
    getProgressiveBarForPattern: (state) => {
      return (id) => {
        return state.progressiveBarScanner[id]
      }
    },
    getProgressiveBarForGuru: (state) => {
      return (id) => {
        return state.progressiveBarGuru[id]
      }
    },
    getPercentageRiskBufferToggle: (state) => state.percentageRiskBufferToggle,
    getFilterSettings(state) {
      return (scanId) => {
        return state.scans.scanner[scanId].filterSettings
      }
    },
    getActivitySettings(state) {
      return (scanId) => {
        return state.scans.scanner[scanId].settings
      }
    },
    getPatternSettings(state) {
      return (scanId) => {
        return state.scans.scanner[scanId].settings
      }
    },
    filterSettingsForScan(state) {
      return (scanId) => {
        if (scanId) return state.scans.scanner[scanId].filterSettings
        else return state.scans.filterSettings
      }
    },
    selectedWatchData: (state) =>
      state.watchLists && state.watchLists[state.selectedWatchCode]
        ? state.watchLists[state.selectedWatchCode]
        : null,
  },
})
