<template>
  <div class="w-full h-full -px-3 space-y-1 bg-white overflow-hidden">
    <!-- <div
      class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
    >
      <ModalHeader header="Comparing Symbols" @close="closeModal" />
    </div> -->

    <div
      class="w-full -bg-slate-400 border-t border-[#E2E8F0] xs:space-y-1 qm:space-y-0 px-4 py-4"
    >
      <div>
        Enter up to four additional stocks to compare against the current chart.
        The starting point for the comparison is controlled by the selected time
        frame (3M, 6M, etc), and is represented on the chart by a dashed
        vertical line.
      </div>
      <div
        class="w-full !mt-8 -bg-slate-400 xxs:!flex-col gap-4 flex justify-center items-center"
      >
        <div
          class="w-full h-full -bg-slate-600 -border flex flex-col space-y-8 justify-start items-start"
        >
        <div v-for="(symbol, index) in symbols" :key="index" class="">
            <SymbolSearchBox
              :symbolNameProp="symbol.name"
              v-model="symbols[index].value"
              @add="handleAddField"
              :showAdd="index === symbols?.length - 1 && symbols.length < 4"
              @input="setSymbolValue(symbol.name, $event)"
              :selectedSymbolType="symbol.market"
            />
       </div>
          <!-- <div class="">
            <SymbolSearchBox
              symbolNameProp="symbol1"
              v-model="symbol1"
              :selected-symbol-type="symbol1Market"
            />
          </div>
          <div class="">
            <SymbolSearchBox
              symbolNameProp="symbol2"
              v-model="symbol2"
              :selected-symbol-type="symbol2Market"
            />
          </div>
          <div class="">
            <SymbolSearchBox
              symbolNameProp="symbol3"
              v-model="symbol3"
              :selected-symbol-type="symbol3Market"
            />
          </div>
          <div class="">
            <SymbolSearchBox
              symbolNameProp="symbol4"
              v-model="symbol4"
              :selected-symbol-type="symbol4Market"
            />
          </div> -->
        </div>

     
      </div>
      <!-- <div class="w-full space-x-10 flex items-center justify-around">
        
      </div> -->
    </div>
    <div
      class="-w-full !h-[50px] -border-t -bg-slate-500 border-[#E2E8F0] px-4 flex flex-row justify-between items-center space-x-3 shrink-0"
    >
      <ModalFooter
        firstTitle=""
        secondTitle="Save"
        colorType="color"
        :isDisable="isDisabled"
        :isLoading="isLoading"
        @save="onSubmit"
      />
    </div>
  </div>

  <!-- <dialog-box
    class="content"
    modalHeader="Comparing Symbols"
    :open="open"
    @onClose="onClose"
    @ok="onSubmit"
    :scrolling="false"
    size="small"
    :isDisabled="isDisabled"
  >
    <sui-form :loading="isLoading" size="mini">
      <div>
        <p class="compare-chart-text">
          Enter up to four additional stocks to compare against the current
          chart. The starting point for the comparison is controlled by the
          selected time frame (3M, 6M, etc), and is represented on the chart by
          a dashed vertical line.
        </p>
        <div  style="display:flex; flex-direction:column; gap:1vh;">

          <div class="row-one">
            <div 
            >
              <symbol-search-box-modal symbolNameProp="symbol1" v-model="symbol1" :selected-symbol-type="symbol1Market"/>
            </div>
            <div>
              <symbol-search-box-modal symbolNameProp="symbol2" v-model="symbol2" :selected-symbol-type="symbol2Market"/>
            </div>
          </div>
  
          <div
            class="row-two"
            style="display: flex; justify-content: space-around"
          >
            <div>
              <symbol-search-box-modal symbolNameProp="symbol3" v-model="symbol3" :selected-symbol-type="symbol3Market"/>
            </div>
            <div>
              <symbol-search-box-modal symbolNameProp="symbol4" v-model="symbol4" :selected-symbol-type="symbol4Market"/>
            </div>
          </div>
        </div>
      </div>
    </sui-form>
   
  </dialog-box> -->
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
//import SymbolSearchBox from '../SymbolSearchBox.vue'
// import Loader from '../../baseComponents/Loader.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ButtonComp from '../ButtonComp'
// import SymbolSearchBoxModal from '../SymbolSearchBoxModal.vue'
import { chartEvents } from '../../chartEvents'
import ModalHeader from '../ModalHeader.vue'
import ModalFooter from '../ModalFooter.vue'
import SymbolSearchBox from '../SymbolSearchBox'

export default {
  name: 'ComparingSymbols',
  data() {
    return {
      isLoading: false,
      isDisabled: true,
      inputList:['symbol1','symbol2']
    }
  },
  mounted() {
    this.toggleButtonState()
  },
  components: {
    ModalHeader,
    ModalFooter,
    ButtonComp,
    SymbolSearchBox,
    // Loader,
    // SymbolSearchBox,
    // SymbolSearchBoxModal,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions([
      'retrieveMultipleSymbolData',
      'setNewChartType',
      'setSymbolData',
    ]),
    ...mapMutations(['changeSymbol', 'deleteSymbol']),
    onClose() {
      this.$emit('change', !open)
    },

    handleAddField(){
      
      this.inputList?.push(`symbol${this.inputList?.length+1}`)
    },

    setSymbolValue(symbol, value) {
      console.log("setSymbolValue value==>",symbol,value)
    if (value === '' || value === undefined) {
      this.deleteSymbol(symbol);
    } else {
      this.changeSymbol({ propName: symbol, propValue: value });
    }
  },

    handleSave() {
      console.log('save==>')
    },

    closeModal() {
      this.$emit('closeCompareModal')
    },
    toggleButtonState() {
      if (
        this.symbol1 === '' &&
        this.symbol2 === '' &&
        this.symbol3 === '' &&
        this.symbol4 === ''
      ) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    async onSubmit() {
      
      const allSymbols = this.getSelectedMultipleSymbols
      console.log("allSymbols", allSymbols)

      const comparisonSymbolList = []
      for (let i = 1; i <= 4; i++) {
        const key = allSymbols[`symbol${i}`]

        if (comparisonSymbolList.includes(key)) {
          this.$store.commit('deleteSymbol', `symbol${i}`)
        } else {
          comparisonSymbolList.push(key)
        }
      }

      this.isLoading = true
      this.setNewChartType({
        type: 'CompareChartMain',
        location: 'FromComparingSym',
        symbolMap: {
          symbol1: this.getSelectedMultipleSymbols?.symbol1 || '',
          symbol2: this.getSelectedMultipleSymbols?.symbol2 || '',
          symbol3: this.getSelectedMultipleSymbols?.symbol3 || '',
          symbol4: this.getSelectedMultipleSymbols?.symbol4 || '',
        },
      }).then((d) => {
       
        localStorage.setItem('isComparisonChart', 'true')
        console.log("response")
        console.log(
          'timeframe from compare symbol',
          this.symbolName,
          this.timeFrame
        )
        this.setSymbolData({
          symbolName: this.symbolName,
          timeFrame: this.timeFrame,
          reForce: true,
          isComparison: true,
        }).then((d) => {
          chartEvents.$emit('setChartRange', undefined, 'ComparingModals')
          chartEvents.$emit('symbolChangeVerifyScanDetails')
          chartEvents.$emit('loadDrawingData')
          this.closeModal()
        })
        // await this.retrieveMultipleSymbolData()
        this.isLoading = false
        this.$emit('change', false)
      })
    },
    getSymbolPart(symbol) {
      // console.log("getSymbolPart",symbol)
      let split = symbol?.split('~')
      return split?.[0] || ''
    },
    getSymbolMarket(symbol) {
      // console.log("getSymbolPart",symbol)
      let split = symbol?.split('~')
      return split?.[1] || 'NASDAQ'
    },
  },
  watch: {
    symbol1: 'toggleButtonState',
    symbol2: 'toggleButtonState',
    symbol3: 'toggleButtonState',
    symbol4: 'toggleButtonState',
  },
  computed: {
    ...mapGetters(['getSelectedMultipleSymbols', 'symbolName', 'timeFrame']),
    /** @returns {String} */
    symbol1Market() {
      return this.getSymbolMarket(this.getSelectedMultipleSymbols.symbol1)
    },
    symbols() {
    return this.inputList?.map(symbol => ({
      name: symbol,
      value: this.getSymbolPart(this.getSelectedMultipleSymbols[symbol]),
      market: this.getSymbolMarket(this.getSelectedMultipleSymbols[symbol])
    }));
  },


    symbol1: {
      get() {
        return this.getSymbolPart(this.getSelectedMultipleSymbols.symbol1)
      },
      set(value) {
        // console.log("symbol1",value)
        if (value === '' || undefined) {
          this.deleteSymbol('symbol1')
          // return "";
        } else {
          this.changeSymbol({ propName: 'symbol1', propValue: value })
        }
      },
    },
    /** @returns {String} */
    symbol2Market() {
      return this.getSymbolMarket(this.getSelectedMultipleSymbols.symbol2)
    },
    symbol3Market() {
      return this.getSymbolMarket(this.getSelectedMultipleSymbols.symbol3)
    },
    symbol4Market() {
      return this.getSymbolMarket(this.getSelectedMultipleSymbols.symbol4)
    },
    symbol2: {
      get() {
        return this.getSymbolPart(this.getSelectedMultipleSymbols.symbol2)
      },
      set(value) {
        if (value === '' || undefined) {
          this.deleteSymbol('symbol2')
        }
        this.changeSymbol({ propName: 'symbol2', propValue: value })
      },
    },
    /** @returns {String} */
    symbol3: {
      get() {
        return this.getSymbolPart(this.getSelectedMultipleSymbols.symbol3)
      },
      set(value) {
        if (value === '' || undefined) {
          this.deleteSymbol('symbol3')
        }
        this.changeSymbol({ propName: 'symbol3', propValue: value })
      },
    },
    /** @returns {String} */
    symbol4: {
      get() {
        return this.getSymbolPart(this.getSelectedMultipleSymbols.symbol4)
      },
      set(value) {
        if (value === '' || undefined) {
          this.deleteSymbol('symbol4')
        }
        this.changeSymbol({ propName: 'symbol4', propValue: value })
      },
    },
  },
}
</script>

<style>
.compare-chart-text {
  font-size: 15px;
}
.td {
}
@media screen and (max-width: 900) {
  .td {
  }
}
@media screen and (max-width: 900) {
  .tr {
  }
}
.tablebody {
  background-color: yellow;
}
.content {
}
.row-one {
  display: flex;
  justify-content: space-around;
}
.row-two {
  display: flex;
}

@media screen and (max-width: 900px) {
  .row-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    /* background-color: blue; */
    /* width: 20px; */
    /* padding-left: 20%; */
  }
  .row-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;

    /* width: 20px; */
    /* padding-left: 20%; */
  }
}

/* @media screen and (max-width: 470px) { font-size:1vw 
  .row-one {
    display: grid;
    width: 40px;
    padding-left: 2%;
  }
  .row-two {
    display: grid;
    width: 40px;
    padding-left: 2%;
  }
} */
</style>
