<template>
  <div class="xxs:h-[100svh] sm:h-[65vh] xxs:!w-[100vw] sm:!w-[80vw] md:!w-[50vw] lg:!w-[45vw] h-[40rem] indicatorContainer">
    <div class="w-full flex items-center justify-between -px-4">
      <div class="flex items-center space-x-2">
        <div v-if="width < 600 && showDetail">
          <svg
            @click="showDetailfalse()"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="size-6 dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <!-- <span class="font-[500] dark:text-[#F6F6F6] text-[24px]">
          {{ isIndicatorPressed ? selectedConfig.name : 'Indicators' }}
        </span>
      
      <span
        @click="handleClose"
        class="cursor-pointer p-2 rounded-full hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6 dark:text-white"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </span> -->
      </div>
      <!--Header-->

      <div
        class="w-full !h-[60px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="`${isIndicatorPressed ? selectedConfig.name : 'Indicators'}`"
          @close="handleClose"
        />
      </div>
    </div>

    <div
      class="w-full border-t  border-b border-[#D1D1D1] px-4 py-2 items-center flex space-x-2 dark:border-[#232323]"
    >
        <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 text-gray-500 dark:text-[#ffffff]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </span>
      <span class="w-full">
        <input
          type="text"
          v-model="searchTerm"
          @input="searchIndicators"
          placeholder="Search"
          class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#6D6D6D] placeholder:dark:text-[#BDBDBD] placeholder:text-[14px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
        />
      </span>
      <div @click="clearSearchTerm" class="cursor-pointer text-[#181818] dark:text-[#BDBDBD] text-[16px]" v-if="searchTerm.length > 0">clear</div>
      </div>
    <div class="flex w-full h-[80%]">
      <div :class="getLeftArea()" class="space-y-4 h-full py-4 ">
        <div
          v-for="(item, i) in filteredTrendConfig"
          @click="handleIndicatorSelect(item)"
          :key="i"
          :class="
            selectedConfig?.name == item.name
              ? '!bg-appBtnActive text-appBtnActiveTxt dark:!bg-transparent'
              : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover cursor-pointer !border-[transparent]'
          "
          class="flex w-full px-8 space-x-4 py-6 sm:py-2.5"
        >
          <div class=" items-center flex">
            <!-- <img :src="require(`../assets/chart/${item.image}`)" /> -->

            <span v-if="item.image === 'indicator.svg'">
              <svg
                :class="
                  selectedConfig?.name == item.name
                    ? 'text-appBtnActiveTxt '
                    : 'textt-[#BDBDBD]'
                "
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#bdbdbd"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 15L12 9L16 13L21 7"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </span>
            <span v-if="item.image === 'financial.svg'">
              <svg
                :class="
                  selectedConfig?.name == item.name
                    ? 'text-appBtnActiveTxt '
                    : 'textt-[#BDBDBD]'
                "
                fill="currentColor"
                width="20"
                height="20"
                viewBox="-0.5 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                stroke="currentColor"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M30.000,32.000 L23.000,32.000 C22.447,32.000 22.000,31.552 22.000,31.000 L22.000,1.000 C22.000,0.448 22.447,-0.000 23.000,-0.000 L30.000,-0.000 C30.553,-0.000 31.000,0.448 31.000,1.000 L31.000,31.000 C31.000,31.552 30.553,32.000 30.000,32.000 ZM29.000,2.000 L24.000,2.000 L24.000,30.000 L29.000,30.000 L29.000,2.000 ZM19.000,32.000 L12.000,32.000 C11.448,32.000 11.000,31.552 11.000,31.000 L11.000,17.000 C11.000,16.448 11.448,16.000 12.000,16.000 L19.000,16.000 C19.553,16.000 20.000,16.448 20.000,17.000 L20.000,31.000 C20.000,31.552 19.553,32.000 19.000,32.000 ZM18.000,18.000 L13.000,18.000 L13.000,30.000 L18.000,30.000 L18.000,18.000 ZM8.000,32.000 L1.000,32.000 C0.448,32.000 0.000,31.552 0.000,31.000 L0.000,11.000 C0.000,10.448 0.448,10.000 1.000,10.000 L8.000,10.000 C8.552,10.000 9.000,10.448 9.000,11.000 L9.000,31.000 C9.000,31.552 8.552,32.000 8.000,32.000 ZM7.000,12.000 L2.000,12.000 L2.000,30.000 L7.000,30.000 L7.000,12.000 Z"
                  ></path>
                </g>
              </svg>
            </span> 
            <span v-if="item.image === 'community.svg'">
              <svg
                :class="
                  selectedConfig?.name == item.name
                    ? 'text-appBtnActiveTxt '
                    : 'textt-[#BDBDBD]'
                "
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M5.11757 15.9999H18.8823M8.29404 9.99994H15.7058M10.5859 5.67095L4.2433 17.6513C3.833 18.4263 3.62785 18.8138 3.66932 19.13C3.70551 19.4059 3.85505 19.6543 4.08198 19.8154C4.34198 19.9999 4.78044 19.9999 5.65737 19.9999H18.3425C19.2194 19.9999 19.6579 19.9999 19.9179 19.8154C20.1448 19.6543 20.2943 19.4059 20.3305 19.13C20.372 18.8138 20.1668 18.4263 19.7565 17.6513L13.414 5.67094C12.9553 4.80456 12.726 4.37136 12.42 4.22973C12.1535 4.10637 11.8463 4.10637 11.5798 4.22973C11.2739 4.37136 11.0445 4.80456 10.5859 5.67095Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
          <div class="w-full flex items-center  justify-between">
            <div class="text-[14px] dark:text-[#F6F6F6] ">
              {{ item.name }} 
            </div> 

              <div
            class="flex items-center justify-center w-6 h-6 flex-shrink-0 rounded-full text-sm"
            :class="
              selectedConfig?.name == item.name
                ? 'bg-white text-gray-900'
                : 'bg-gray-800 text-white'
            "
          >
          <div>{{ searchIndicatorCount(item) }}</div>
          </div>
            <div v-if="width < 520">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5 dark:text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filteredIndicators?.length > 0"
        :class="getRightArea()"
        class="py-4 h-full overflow-y-auto no-scrollbar space-y-1"
      >
        <div
          @click="handleIndicators(indicator)"
          v-for="(indicator, ind) in filteredSelectedConfig?.list"
          :key="ind"
          :class="getTick(indicator) ? 'bg-appBtnHover' : ''"
          class="w-full px-8 cursor-pointer py-2.5 flex items-center justify-between dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover rounded-md"
        >
          <span class="text-[14px] dark:text-[#BDBDBD]">
            {{ indicator?.desc }}
          </span>
          <!-- <span v-if="getTick(indicator)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-green-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </span> -->
          <span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
                :fill="getTick(indicator) ? '#1C274C' : '#E2E2E2'"
              />
            </svg>
          </span>
        </div>
      </div>
      <div
        v-else
        :class="getNoDataStyle()"
        class="py-4 h-full flex items-center overflow-y-auto justify-center text-[#6D6D6D] text-[18px] no-scrollbar"
      >
        No indicator found
      </div>
    </div>
  </div>
</template>

<script>
import { trendConfig } from '../utils/chartEvent'
import { mapActions, mapGetters } from 'vuex'
import ModalHeader from './ModalHeader.vue'

export default {
  name: 'IndicatorModal',
  data() {
    return {
      config: trendConfig,
      selectedConfig: trendConfig[0],
      width: window.innerWidth,
      height: window.innerHeight,
      showDetail: false,
      width: window.innerWidth,
      searchTerm: '',
      filteredIndicators: [],
      isIndicatorPressed: false,
      selectedList: null,
    }
  },
  props: {
    variants: Object,
  },
  components: {
    ModalHeader,
  },
  computed: {
    ...mapGetters(['getSelectedIndicators']),
    searchIndicators() {
      const searchTermLower = this.searchTerm?.toLowerCase()
      if (searchTermLower) {
        this.filteredIndicators = this.getAllIndicators()?.filter((indicator) =>
          indicator.desc.toLowerCase()?.includes(searchTermLower)
        )
      } else {
        this.filteredIndicators = this.selectedConfig.list
      }
    },
    filteredTrendConfig(){
      return this.config.map((item)=> {
        return {
          ...item,
          list: item?.list.filter((indicator)=> {
            return indicator?.desc.toLowerCase().includes(this.searchTerm.toLowerCase())
          })
        }
      })
    },
    filteredSelectedConfig(){
      return {
        ...this.selectedConfig,
        list: this.selectedConfig?.list.filter(ind=> ind.desc.toLowerCase().includes(this.searchTerm.toLowerCase()))
      }
    }
  },
  created() {
    this.filteredIndicators = this.selectedConfig?.list
  },

  methods: {
    ...mapActions(['toggleIndicator', 'updateIndicatorList']),
    searchIndicatorCount(item){
      return item.list.length
    },
    handleKeyDown(event){
      if(event?.key == 'Escape'){
        this.handleClose()
      }
    },
    clearSearchTerm(){
      if(this.searchTerm?.length > 0){
        this.searchTerm = ""
      }
    },
    getCount(item){
      return item?.list?.length
    },    
    showDetailfalse() {
      this.showDetail = false
      this.isIndicatorPressed = false
    },
    handleIndicatorSelect(ind) {
      console.log('indicatorsName', ind)
      this.selectedConfig = ind
      this.filteredIndicators = this.selectedConfig?.list
      if (this.width < 520) {
        this.showDetail = true
        this.isIndicatorPressed = true
      }
    },
    getRightArea() {
      // [(showDetail && width < 520 ) ?'block w-[100%]':'hidden',width >= 520?'w-[70%] block':'']
      if (this.width >= 520) {
        return 'w-[70%] border-l border-[#D1D1D1] dark:border-[#232323]'
      } else {
        if (this.showDetail || this.searchTerm) {
          return 'w-[100%] block'
        } else {
          return 'hidden'
        }
      }
    },
    getNoDataStyle() {
      if (this.width >= 520) {
        return 'w-[70%] border-l border-[#D1D1D1] dark:border-[#232323]'
      } else {
        if (this.showDetail || this.searchTerm) {
          return 'w-[100%]'
        } else {
          return 'hidden'
        }
      }
    },
    getLeftArea() {
      // [(showDetail && width < 520 ) ?'hidden':'block w-[100%]',width >= 520?'w-[30%]':'']
      if (this.width >= 520) {
        return 'w-[30%]'
      } else {
        if (this.showDetail || this.searchTerm) {
          return 'hidden'
        } else {
          return 'w-[100%] block'
        }
      }
    },
    getTick(val) {
      let found = this.getSelectedIndicators?.some(
        (indicator) => indicator?.name == val?.name
      )
      if (found) {
        return true
      } else {
        return false
      }
    },
    handleIndicators(indicator) {
      console.log("monday indicator==>",indicator);
      this.selectedList = indicator.name
      if (this.variants?.sixthVariant) {
        if (indicator?.chartType == 'offchart') {
          let updatedArr = this.getSelectedIndicators?.filter(
            (val) => val?.chartType == 'onchart' || val.name == 'CovalData'
          )
          let isPresent = this.getSelectedIndicators?.some(
            (val) => val?.name == indicator?.name
          )
          if (isPresent) {
            let res = this.getSelectedIndicators?.filter(
              (val) => val?.name != indicator?.name
            )
            console.log('res==>', res)
            this.updateIndicatorList(res)
          } else {
            let res = [...updatedArr, indicator]
            this.updateIndicatorList(res)
          }
          this.$emit('addingIndicator', indicator)
          // this.toggleIndicator(indicator);
        } else {
          this.$emit('addingIndicator', indicator)
          this.toggleIndicator(indicator)
        }
      } else {
        console.log("monday else");
        this.$emit('addingIndicator', indicator) // in multichart function that render overlay on offchart, onchart.
        this.toggleIndicator(indicator) // selectedIndicator [] in store, this () add and remove like whenever change indicator.
      }
    },
    handleClose() {
      this.$emit('close')
    },
    getAllIndicators() {
      return this.config?.flatMap((category) => category.list)
    },

    // searchIndicators() {
    //   const searchTermLower = this.searchTerm?.toLowerCase()
    //   if (searchTermLower) {
    //     this.filteredIndicators = this.getAllIndicators()?.filter((indicator) =>
    //       indicator.desc.toLowerCase()?.includes(searchTermLower)
    //     )
    //   } else {
    //     this.filteredIndicators = this.selectedConfig.list
    //   }
    // },
    onResize() {
      this.width = window.innerWidth
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('resize', this.updateScreenWidth)
    window.addEventListener('keydown',this.handleKeyDown)
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed to avoid memory leaks
    window.addEventListener('resize', this.updateScreenWidth)
    window.removeEventListener('keydown',this.handleKeyDown)
  },
}
</script>

<style>
@media only screen and (max-width: 1400px) {
  .indicatorContainer {
    width: 60vw !important;
  }
}
@media only screen and (max-width: 860px) {
  .indicatorContainer {
    width: 80vw !important;
  }
}
@media only screen and (max-width: 540px) {
  .indicatorContainer {
    width: 90vw !important;
  }
}
@media only screen and (max-width: 520px) {
  .indicatorContainer {
    width: 85vw !important;
  }
}
</style>
