<template>
  <div class="w-full !h-full pb-8">
    <div
      v-if="getSelectedWatchlist?.symbolList?.length > 0"
      class="w-full !h-full -bg-slate-500"
    >
      <div
        @mouseover="isMouseOver(item.name)"
        @mouseleave="isMouseLeave"
        v-for="(item, index) in getSelectedWatchlist?.symbolList"
        @click="handleSymbolPress(item.name)"
        :key="index"
        class="w-full h-[4rem] cursor-pointer flex border-b items-center justify-between hover:bg-appBtnHover -bg-slate-400 px-3"
      >
        <!-- Icon Brand Name -->
        <div class="w-[30%] flex justify-start items-center gap-2">
          <!-- Brand Icon -->
          <div
            class="min-w-[2.5rem] min-h-[2.5rem] max-w-[2.5rem] max-h-[2.5rem] rounded-full text-white text-[18px] -bg-[red] flex justify-center items-center"
          >
            <TickerIcon :ticker="item.name" />
          </div>
          <!-- Brand Name -->
          <div class="w-[70%] h-full flex flex-col -!bg-black">
            <span
              
              class="xxs:text-[12px] qm:text-[14px] hover:underline cursor-pointer"
              >{{ item.name }}</span
            >
            <span class="xxs:text-[11px] qm:text-[12px]">{{ item.id }}</span>
          </div>
        </div>

        <!-- Price -->
        <div class="w-[45%] flex flex-col items-end justify-center relative">
          <span
            class="text-[#131722] xxs:!text-[12px] qm:text-[14px] font-[400]"
          >
            $ {{item?.close}}
          </span>
          <span :class=" item?.change < 0 ?'text-errorText':' text-[#037950]'" class=" xxs:!text-[12px] qm:text-[14px] font-[400]">
            {{ item?.change?.toFixed(2) }}
            {{`
             (${item.changePercent?.toFixed(2)})
            `}}
          </span>
          <span
            @click.stop="openRemoveSymbolModal(item.name)"
            :class="isMouseOnSymbol == item.name ? 'display' : 'invisible'"
            class="absolute xxs:left-[-6.5rem] qm:left-[-7.5rem] rounded-full shadow-md p-[0.3px] cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="18"
              height="18"
            >
              <path
                fill="currentColor"
                d="M12 4h3v1h-1.04l-.88 9.64a1.5 1.5 0 0 1-1.5 1.36H6.42a1.5 1.5 0 0 1-1.5-1.36L4.05 5H3V4h3v-.5C6 2.67 6.67 2 7.5 2h3c.83 0 1.5.67 1.5 1.5V4ZM7.5 3a.5.5 0 0 0-.5.5V4h4v-.5a.5.5 0 0 0-.5-.5h-3ZM5.05 5l.87 9.55a.5.5 0 0 0 .5.45h5.17a.5.5 0 0 0 .5-.45L12.94 5h-7.9Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div
          v-else
         
          class="w-full h-full text-[14px] text-appBtnActive flex items-center justify-center"
        >
          
          <span
            @click="openAddSymbolModal()"
            class="cursor-pointer hover:underline ml-2 font-sans font-[500]"
            >Click here to add symbols.</span
          >
        </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TickerIcon from '../../TickerIcon.vue'
import { chartEvents } from '@/chartEvents.js'
import NoData from '../../NoData'

export default {
  name: 'App',
  data() {
    return {
      showBtn: false,
      isMouseOnSymbol: null,
      symbols: [
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
      ],
    }
  },
  components: {
    TickerIcon,
    NoData,
  },
  props: {
    selectedRight: String,
  },
  methods: {
    handleSymbolPress(sym) {
      chartEvents.$emit('load-symbol', sym)
    },
    isMouseOver(name) {
      this.isMouseOnSymbol = name

      // console.log('isMouseOnIndex', this.isMouseOnIndex)
    },

    isMouseLeave() {
      this.isMouseOnSymbol = null

      // console.log('isMouseOnIndex', this.isMouseOnIndex)
    },

    openAddSymbolModal() {
      chartEvents.$emit('openAddSymbolModal1')
      console.log('open add symbol modal head')
    },
    openRemoveSymbolModal(sym) {
      chartEvents.$emit('openRemoveSymbolModal', sym)
      console.log('delete', sym, getSelectedWatchlist?.symbolList)
    },
  },
  computed: {
    ...mapGetters(['getSelectedWatchlist']),
  },
  mounted() {
    console.log('getSelectedWatchlist', this.getSelectedWatchlist)
  },
  watch: {},
}
</script>
<style scoped></style>
