<template>
  <div class="flex flex-col -bg-red-100 w-full h-full px-[10px] py-[10px]">
    <div class="w-full h-[10%] -bg-yellow-200 gap-3 flex flex-row justify-between items-center">
      <div
        :class="!selectedScan ? '' : 'xxs:hidden md:flex'"
        class="w-full h-[70%] -border-t -border-b border-[#D1D1D1] dark:bg-[#FFFFFF1A] px-4 items-center flex space-x-4 dark:border-[#232323]"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
          >
            <path
              stroke="#a3a6af"
              d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
            ></path>
          </svg>
        </span>
        <span class="w-full">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search Symbol"
            class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#949cb2] placeholder:dark:text-[#BDBDBD] -font-[600] placeholder:text-[16px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
          />
        </span>
      </div>
      <div ref="showRef" class="relative">
        <ButtonComp
          :title="'Add to Watchlist'"
          :colorType="'color'"
          :hideSave="hideSave"
          @press="openFavDropdown  = !openFavDropdown"
          :isLoading="isLoading"
        />

        <div class="absolute right-0 top-[3rem]" style="z-index: 999;">
          <div v-if="openFavDropdown" :class="isLoading ? 'pointer-events-none' : ''" class="bg-white rounded-[4px] w-[12rem] dark:border-none shadow-lg py-2">
              <span v-if="watchLists.length > 0">
                <div v-for="(item,i) in watchLists" :key="i" @click="handlerWatchList(item)" class="flex pl-2 py-2.5 hover:bg-appBtnHover dark:hover:bg-[#3f3f3f] dark:text-white dark:bg-[#2d2d2d] cursor-pointer items-center space-x-3">
                    <div class="text-[13px] font-[500] flex items-center space-x-2">
                      <div class="ml-2">{{ item.name }}</div>
                    </div>
                </div>
              </span>
          </div>
        </div>
        
      </div>
      <div>
        <ColumnsVisibilty :visibileColumn="visibileColumn" :columns="columns" @visibleHandler="visibleHandler" />
      </div>
    </div>
    <div class="w-full h-[calc(100%-10%)] -bg-blue-200">
        <MarketTable :columns="columns" :visibileColumn="visibileColumn" :searchQuery="searchQuery" @updateSelectedSymbols="handleUpdateList"/>
    </div>
  </div>
</template> 

<script>
import ColumnsVisibilty from '../../columnsVisibilty.vue';
import ButtonComp from '../../ButtonComp.vue';
import MarketTable from '../../MarketTable.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
    name: '',
    data() {
        return {
          openFavDropdown: false,
          hideSave: false,
          selectedListSymbols:[],
          isLoading: false,
          selectedScan: false,
          searchQuery: "",
          columns: [
                {key: "symbol",label: "Symbol"},
                {key: "companyName",label: "Company Name"},
                {key: "price",label: "Price"},
                {key: "changePercent",label: "Change Percent"},
                {key: "volume",label: "Volume"},
                {key: "marketCap",label: "Market Cap"},
                {key: "peRatio",label: "P/E"},
                {key: "epsGrowth",label: "EPS dil growth (TTM YoY)"},
                {key: "rating",label: "Analyst Rating"},
            ],
            visibileColumn: ['symbol','companyName','price','volume','peRatio','epsGrowth','marketCap','rating','changePercent'],
        };
    },
    methods: {
      ...mapActions([
      'removeSymbolFromUnselectWatchlist',
      'addNewSymbolToUnselectWatchlist',
    ]),
      addToWatchlist(){
        console.log("add to watchlist");
      },
    
      handleUpdateList(val){
        this.selectedListSymbols = val
      },
      visibleHandler(columnKey){
         if(this.visibileColumn.includes(columnKey)){
                this.visibileColumn = this.visibileColumn.filter((item)=> item !== columnKey)
                localStorage.setItem("visibileColumn",JSON.stringify(this.visibileColumn))
            }else{
                this.visibileColumn.push(columnKey)
                localStorage.setItem("visibileColumn",JSON.stringify(this.visibileColumn))
            }
      },
      async handlerWatchList(watchlist){
        let modifiedDataListSymbol = this.selectedListSymbols.map((item)=>{
          return {
            ...item,
            close: 224.23,
            change: -2.730000000000018,
            changePercent: -1.2028551286570401
          }
        })
        .map((item)=>{
          return {
              id: "NASDAQ",
              name: item.symbol,
              change: item.change,
              changePercent: item.changePercent,
            }
        })
        // console.log("watchlistInTop==>",watchlist,this.selectedListSymbols);
        let updatedArr = [...watchlist?.symbolList,...modifiedDataListSymbol].filter(
              (item, index, self) =>
                index === self.findIndex((obj) => obj.name === item.name)
            );
            let payload = {
              list: updatedArr,
              data: watchlist
            }
            let res = await this.addNewSymbolToUnselectWatchlist(payload)
              if (res) {
                  console.log("response:==>",res);
              } else {
                  console.log("else===>");
              }

          console.log("updatedArray==>",updatedArr, payload);
      }
    },
    computed: {
      ...mapGetters([
      'watchLists',
    ]),
    },
    mounted() {
      const isVisibleColumnInLocalStorage = JSON.parse(localStorage.getItem('visibileColumn'))
      if(isVisibleColumnInLocalStorage){
        this.visibileColumn = isVisibleColumnInLocalStorage
      }

    },
   
    watch: {},
    components: { ButtonComp,MarketTable, ColumnsVisibilty }
}
</script>

<style>
</style>
