var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full !h-full flex justify-between gap-3 -border-t -border-[#E2E8F0] dark:border-[#2D2D2D] !items-center"},[_c('div',{staticClass:"flex justify-center items-center"},[(!_vm.hideSave)?_c('span',{class:[
        _vm.infoBtnTitle == 'Add to favourite' || _vm.infoBtnTitle == 'Add'
          ? 'block'
          : 'hidden',
        _vm.width <= 600
          ? 'w-[100px] flex justify-center overflow-hidden rounded'
          : '',
      ]},[_c('ButtonComp',{attrs:{"title":_vm.infoBtnTitle,"colorType":"green"},on:{"press":_vm.handleAddToFavourite}})],1):_vm._e(),(!_vm.hideSave && _vm.showResultBottom)?_c('span',{staticClass:"ml-4 space-x-4"},[_c('span',[_vm._v(_vm._s(_vm.scanResults && _vm.scanResults?.length ? _vm.scanResults[_vm.scanResults?.length - 1]?.results?.length : 0)+" "+_vm._s(_vm.scanResults[_vm.scanResults?.length - 1]?.results?.length > 1 ? 'stocks' : 'stock')+" found.")]),_c('span',{staticClass:"underline cursor-pointer",on:{"click":_vm.moveToShowResult}},[_vm._v("Show Result")])]):_vm._e()]),_c('div',{staticClass:"flex items-center gap-3"},[(!_vm.hideClose)?_c('span',{class:[
        _vm.firstTitle == '' ? 'hidden' : 'block',
        _vm.firstTitle == 'Cancel' && _vm.width <= 600
          ? '!w-[100px] bg-slate-400 border flex justify-center overflow-hidden rounded'
          : '',
      ]},[_c('CustomButton',{attrs:{"isLoading":_vm.isLoading,"title":_vm.firstTitle},on:{"press":_vm.cancleHandler}})],1):_vm._e(),(!_vm.hideSave && !_vm.isLoading)?_c('span',{class:[
        _vm.secondTitle == '' ? 'hidden' : 'block',
        _vm.secondTitle == 'Save' && _vm.width <= 600
          ? '!w-[100px] border flex justify-center overflow-hidden rounded'
          : '',
      ]},[_c('ButtonComp',{attrs:{"title":_vm.secondTitle,"colorType":_vm.colorType,"isDisable":_vm.isDisable,"hideSave":_vm.hideSave,"isLoading":_vm.isLoading},on:{"press":_vm.handleSave}})],1):_vm._e(),(_vm.isLoading)?_c('span',{class:_vm.thirdTitle == '' ? 'hidden' : 'block'},[_c('ButtonComp',{attrs:{"title":_vm.thirdTitle,"colorType":_vm.colorType,"hideSave":_vm.hideSave,"isLoading":_vm.isLoading},on:{"press":_vm.stopScan}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }