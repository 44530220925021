import { render, staticRenderFns } from "./Multichart.vue?vue&type=template&id=54a4a32e"
import script from "./Multichart.vue?vue&type=script&lang=js"
export * from "./Multichart.vue?vue&type=script&lang=js"
import style0 from "./Multichart.vue?vue&type=style&index=0&id=54a4a32e&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports