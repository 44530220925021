<template>
  <!-- p-2.5 it was in the main div -->
  <div class="w-full border-b px-3 min-h-[60px] flex items-center justify-between">
    <!-- WatchList text -->
    <!-- <BackgroundHover> -->
    <div class="flex items-center py-0.5 --space-x-4">
      <span
        @click="handleCloseRightBar"
        :class="width <= 880 ? '' : 'hidden'"
        class="cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </span>
      <div :class="width >= 600 ? '!ml-[0px]' : 'ml-[0px]'">
        <div class="text-appTextColor font-bold text-[15px]">
          {{ mainHeading }}
        </div>
        <div class="text-[#323232] -mt-1 font-medium text-[12px]">
          {{ subHeading }}
        </div>
      </div>
      <div>
        <span v-if="selectedRight == 'watchlist'">
          <BackgroundHover @press="handleOpenWatchlistModal">
            <svg
              width="17"
              height="17"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.20215 1L5.20215 5L9.20215 1"
                stroke="#323232"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </BackgroundHover>
        </span>
        <span v-if="selectedRight == 'scanner'">
          <BackgroundHover>
            <svg
              width="20"
              height="20"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.20231 14.6673C11.8842 14.6673 14.869 11.6825 14.869 8.00065C14.869 4.31875 11.8842 1.33398 8.20231 1.33398C4.52041 1.33398 1.53564 4.31875 1.53564 8.00065C1.53564 11.6825 4.52041 14.6673 8.20231 14.6673Z"
                stroke="#1C274C"
              />
              <path
                d="M8.20215 11.334V7.33398"
                stroke="#1C274C"
                stroke-linecap="round"
              />
              <path
                d="M8.20231 4.66667C8.5705 4.66667 8.86898 4.96514 8.86898 5.33333C8.86898 5.70152 8.5705 6 8.20231 6C7.83412 6 7.53564 5.70152 7.53564 5.33333C7.53564 4.96514 7.83412 4.66667 8.20231 4.66667Z"
                fill="#1C274C"
              />
            </svg>
          </BackgroundHover>
        </span>
        <div v-if="disablePlayContainer">
          <BackgroundHover
            @press="handleRefresh"
            title="Click here to exit training mode"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.23712 5.86882C4.49034 3.71207 6.82573 2.26196 9.49981 2.26196C13.4973 2.26196 16.7379 5.50257 16.7379 9.50006C16.7379 13.4975 13.4973 16.7382 9.49981 16.7382C5.50232 16.7382 2.26172 13.4975 2.26172 9.50006"
                stroke="#1C274C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.78516 5.88101H3.16611V2.26196"
                stroke="#1C274C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </BackgroundHover>
        </div>
        <div v-if="!disablePlayContainer && selectedRight == 'trainingMode'">
          <BackgroundHover @press="handleTrainingModal">
            <svg
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#1C274C"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </BackgroundHover>
        </div>
      </div>
    </div>
<!-- 
    <div v-if="selectedRight == 'scannerResults'" class="w-[60%] bg-slate-300">
      <ProgressiveBar
        :stockMessage="'stocksMessage'"
        :percentage="percentage"
        :showTitle="false"
      />
    </div> -->

    <div
      v-if="selectedRight == 'watchlist' || selectedRight == 'scanner'"
      class="flex items-center space-x-3"
    >
      <BackgroundHover
        :class="
          selectedRight == 'watchlist' &&
          getSelectedWatchlist?.symbolList?.length > 0
            ? ''
            : 'pointer-events-none opacity-50'
        "
      >
        <svg
          @click="handleOpenDownloadSymbolModal(getSelectedWatchlist)"
          width="20"
          height="20"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.20215 10C2.20215 11.8856 2.20215 12.8284 2.78794 13.4142C3.37372 14 4.31653 14 6.20215 14H10.2021C12.0877 14 13.0305 14 13.6163 13.4142C14.2021 12.8284 14.2021 11.8856 14.2021 10"
            stroke="#001A2C"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.20182 2V10.6667M8.20182 10.6667L10.8685 7.75M8.20182 10.6667L5.53516 7.75"
            stroke="#001A2C"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </BackgroundHover>
      <BackgroundHover
        v-if="selectedRight == 'watchlist'"
        :class="watchLists?.length == 0 ? 'pointer-events-none opacity-50' : ''"
      >
        <svg
          @click="openAddSymbolModal"
          width="20"
          height="20"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6564_7740)">
            <path
              d="M8.20215 14.8845V1.11523"
              stroke="#001A2C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.31738 8H15.0867"
              stroke="#001A2C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_6564_7740">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(0.202148)"
              />
            </clipPath>
          </defs>
        </svg>
      </BackgroundHover>
    </div>

    <!-- Icons for Training Mode -->
    <div
      v-if="selectedRight == 'trainingMode'"
      :class="disablePlayContainer ? '' : 'pointer-events-none opacity-50'"
      class="flex items-center space-x-3"
    >
      <BackgroundHover
        @press="handleBack"
        :class="disablePrev ? 'pointer-events-none' : ''"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6564_8116)">
            <path
              d="M15.666 12.2656L15.666 3.73562L8.20202 8.00113L15.666 12.2656ZM15.133 11.3471L9.27702 8.00113L15.133 4.65462L15.133 11.3471Z"
              fill="black"
            />
            <path
              d="M8.20215 12.2656L8.20215 3.73562L0.738148 8.00113L8.20215 12.2656ZM7.66915 11.3471L1.81315 8.00113L7.66915 4.65462L7.66915 11.3471Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_6564_8116">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(16.2021 16) rotate(180)"
              />
            </clipPath>
          </defs>
        </svg>
      </BackgroundHover>
      <BackgroundHover
        class="p-2"
        @press="handlePlay"
        v-if="togglePlay && !disableNext"
      >
        <svg
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="text-[#001A2C]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 5.25v13.5m-7.5-13.5v13.5"
          />
        </svg>
      </BackgroundHover>
      <BackgroundHover class="p-2" @press="handlePlay" v-else>
        <svg
          width="20"
          height="20"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.777616 1.32762L12.4564 8.00072L0.777616 14.6738V1.32762ZM0 0.000714489V16L14 8L0 0.000714489Z"
            fill="#001A2C"
          />
        </svg>
      </BackgroundHover>
      <BackgroundHover
        @press="handleNext"
        :class="disableNext ? 'pointer-events-none' : ''"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6564_8113)">
            <path
              d="M0.738281 3.73438V12.2644L8.20228 7.99887L0.738281 3.73438ZM1.27128 4.65288L7.12728 7.99887L1.27128 11.3454V4.65288Z"
              fill="black"
            />
            <path
              d="M8.20215 3.73438V12.2644L15.6661 7.99887L8.20215 3.73438ZM8.73515 4.65288L14.5911 7.99887L8.73515 11.3454V4.65288Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_6564_8113">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(0.202148)"
              />
            </clipPath>
          </defs>
        </svg>
      </BackgroundHover>
    </div>
  </div>
</template>

<script>
import BackgroundHover from './BackgroundHover'
import { mapGetters } from 'vuex'
import { chartEvents } from '../chartEvents'
import Chart from '../@trading-vue-js/src/components/Chart.vue'
import ProgressiveBar from './ProgressiveBar.vue'

export default {
  name: 'RightBarHead',
  data() {
    return {
      width: window.innerWidth,
    }
  },
  components: {
    BackgroundHover,
    ProgressiveBar,
  },
  props: {
    mainHeading: String,
    subHeading: String,
    selectedRight: String,
    disablePlayContainer: Boolean,
    disableNext: Boolean,
    disablePrev: Boolean,
    togglePlay: Boolean,
    percentage: Number,
    socketScannerResult: Array,
  },

  computed: {
    ...mapGetters([
      'getTheme',
      'getAllWatchlist',
      'getSelectedWatchlist',
      'watchLists',
    ]),
    // stocksMessage() {
    //   return `${this.socketScannerResult.length} scanned.`
    // },
  },
  methods: {
    handleOpenWatchlistModal() {
      this.$emit('handleOpenWatchlistModal')
      console.log('open watchlist Modal')
    },
    updateScreenWidth() {
      this.width = window.innerWidth
    },
    handleBack() {
      this.$emit('back')
    },

    handleCloseRightBar() {
      chartEvents.$emit('closeRightBar')
      console.log('close right bar')
    },
    handlePlay() {
      this.$emit('play')
    },
    handleNext() {
      this.$emit('next')
    },
    handleRefresh() {
      this.$emit('refresh')
      if (this.togglePlay) {
        this.handlePlay()
      }
    },
    handleTrainingModal() {
      this.$emit('openTrainingModal')
    },

    handleOpenDownloadSymbolModal(watchlist) {
      this.$emit('handleOpenDownloadSymbolModal', watchlist)
      console.log('open download symbol Download 2')
    },
    openAddSymbolModal() {
      chartEvents.$emit('openAddSymbolModal1')
      console.log('open add symbol modal head')
    },
  },

  watch: {
    selectedRight() {
      console.log('selectedRight', this.selectedRight)
    },
  },
  mounted() {
    chartEvents.$on('disable-training-mode',()=>{
      this.handleRefresh();
    })   
    
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    chartEvents.$off('disable-training-mode')
    window.removeEventListener('resize', this.updateScreenWidth)
  },
}
</script>
