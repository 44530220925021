<template>
  <div
    class="relative flex flex-col justify-between bg-yellow xxs:!w-[100vw] md:!w-[750px] -lg:!w-[55vw] 2lg:!w-[80vw] xxs:h-[100svh] md:h-[90vh] !overflow-hidden"
  >
    <div
      v-if="isLoading"
      class="absolute -backdrop-blur-md flex-col w-full h-[85vh] flex items-center justify-between"
      style="z-index: 9999"
    >
      <!-- <loader></loader> -->
    </div>
    <!-- Header For Pattern Scanners -->
    <div
      :class="[
        isLoading ? 'pointer-events-none' : '',
        selectedScan == false ? 'h-[12%]' : 'xxs:h-[6%] md:h-[12%]',
      ]"
      class="!w-full border-b -border-green-400"
    >
      <div
        :class="selectedScan == false ? '!h-[50%]' : 'xxs:h-full md:h-[50%]'"
        class="w-full -bg-slate-500 px-4 -border-2 -border-green-800 flex flex-row justify-between items-center space-x-4"

      >
        <div class="w-auto flex flex-row space-x-3">
          <span @click="toBack" v-if="selectedScan && width <= 880">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          <div class="text-[#6D6D6D] text-[18px]">Scanners</div>
        </div>

        <span
          @click="closeHandler"
          class="cursor-pointer p-2 rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>

      <!-- Search bar -->
      <div
        :class="selectedScan == false ? '' : 'xxs:hidden md:flex'"
        class="w-full h-[50%] border-t border-b border-[#D1D1D1] dark:bg-[#FFFFFF1A] px-4 items-center flex space-x-4 dark:border-[#232323]"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
          >
            <path
              stroke="#a3a6af"
              d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
            ></path>
          </svg>
        </span>
        <span class="w-full">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search lists"
            class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#949cb2] placeholder:dark:text-[#BDBDBD] -font-[600] placeholder:text-[16px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
          />
        </span>
      </div>
    </div>

    <!-- Main Content For Scanner Data -->
    <div
      :class="[isLoading ? 'pointer-events-none' : '']"
      class="w-full min-h-[30rem] h-[80%] flex flex-row -bg-slate-400"
    >
      <!-- Left Side -->
      <div
        :class="selectedScan == false ? '' : 'xxs:hidden md:block'"
        class="xxs:w-full md:w-[200px] xl:w-[18%] -bg-slate-300 !h-full mt-1 flex flex-col -rounded-[10px] border-r border-gray dark:!border-[#2D2D2D]"
      >
        <div class="py-3 h-[80%]  mr-1">
          <div class="mb-4 px-4 bold font-bold xxs:text-[18px] sm:text-[16px]">
            Scan Types
          </div>
          <div class="py-3 h-[95%] overflow-y-auto custom-scrollbar">
            <div
              v-for="(sidebar, index) in filteredScanners"
              :key="index"
              @click="handlerSelectedScanner(sidebar)"
              :class="[
                sidebar.name === selectedScanner.name
                  ? '!bg-[#001a2c] text-white -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]',
                sidebar.name === selectedScanner.name && width >= 880
                  ? 'pointer-events-none'
                  : '',
              ]"
              class="flex flex-row justify-start sm:text-[14px] items-center hover:bg-[#f6f6f6] xxs:px-8 md:px-10 sm:py-[15px] xxs:py-[20px] xxs:space-x-2 md:space-x-3"
            >
              <div
                v-html="
                  getChartSvgs(
                    sidebar?.image,
                    sidebar.name == selectedScanner.name
                  )
                "
              ></div>
              <div class="xxs:text-[18px] sm:text-[14px]">
                {{ sidebar.name }}
              </div>
            </div>
          </div>
        </div>

        <!-- Favourite -->
        <div class="py-3 h-[20%]">
          <div class="mb-4 px-4 bold xxs:text-[16px] sm:text-[14px] font-bold">
            My Favourite(s)
          </div>
          <div class="w-full h-[80%] pb-6 overflow-y-auto no-scrollbar">
            <!-- v-if="userSavedScans?.length > 0" -->
            <div
              v-if="userSavedScans.length > 0"
              v-for="(item, index) in userSavedScans"
              :key="index"
              :class="
                selectedFavScan == item?.scanName
                  ? '!bg-[#ededed] text-black -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]'
              "
              class="flex cursor-pointer justify-between items-center hover:bg-[#f6f6f6] xxs:px-2 md:px-10 py-[12px] xxs:space-x-1 md:space-x-3"
            >
              <div class="flex items-center space-x-3">
                <div v-html="getChartSvgs('favourite', false)"></div>
                <div @click="handleFavScanSelect(item)" class="hover:underline">
                  {{ item.scanName }}
                </div>
              </div>
              <div class="flex items-center space-x-1">
                <div
                  @click="handleEditName(item)"
                  class="hover:bg-gray-200 p-1 rounded-md"
                  title="Edit name"
                  v-html="getChartSvgs('edit', false)"
                ></div>
                <div
                  class="hover:bg-gray-200 p-1 rounded-md"
                  @click="handleFavDelete(item)"
                  title="Delete"
                  v-html="getChartSvgs('delete', false)"
                ></div>
              </div>
            </div>
            <div
              v-if="userSavedScans?.length == 0"
              class="flex justify-center items-center p-3"
            >
              <span>No Favourites Found</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div
        :class="selectedScan == true ? '' : 'xxs:hidden md:block'"
        class="flex flex-col xxs:justify-between xxs:w-full md:w-[80%] -min-h-[30rem] xxs:!mb-0 !h-full -bg-yellow-200 -xxs:mt-[-25px] md:mt-2"
      >
        <!-- Right side Header -->
        <div class="w-full xxs:!h-[28%] -bg-red-400 md:!h-[35%] xl:!h-[16%]">
          <div class="w-full h-full">
            <RightSideHeader
              :id="idForScanner"
              :type="typeOfScan"
              :scanTypeMarket="scanTypeMarket"
              @selectedBtn="selectedButtonNameType"
              :selectedButtonTypeName="selectedButtonTypeName"
              :scannerNameList="selectedScanner"
            />
          </div>
        </div>
        <!-- Right side Body -->
        <div
          v-if="selectedScanner?.name == 'Charting Patterns'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[64%] xl:!h-[84%] overflow-y-scroll no-scrollbar"
        >
          <ChartPatternsIndicator
            @isValid_For_Pattern="isValidHandler"
            @is_single_pattern_selected="isValidChartingPattern"
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Activity'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar"
        >
          <Activity :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Candlestick'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar"
        >
          <Candle
            @isSelectedCandleStick="isSelectedCandleStick"
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Technical Indicators'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar"
        >
          <Indicators
            @indicator="indicatorHandler"
            @is_valid="isValidHandlerIndicator"
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Capitulation Scan'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <CapitulationScan :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Bollinger Squeeze'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <BolingerSqueeze :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Big Candle'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <BigCandle :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Heiken Ashi'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <HeikenAshi :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Higher High, Higher Low'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <HigherHighHigherLow
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Fundamental'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar"
        >
          <Fundamental
            @measures="measuresHandler"
            @capitalization="capitalization"
            @returns="returns"
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Darvas Box'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <DarvasBoxes :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Divergense Scan'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <DivergenceScan :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Pocket Pivot'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <PocketPivot :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProReversal Signal'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProReversalSignal :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProDowntrend Monitor'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProDowntrendMonitor
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'ProBreakout Alert'"
          class="w-full -bg-black xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProBreakoutAlert :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProDivergence Watch'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProDivergence :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProSwing Boost'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProSwingBoostVue :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProSwing Drop'"
          class="w-full xxs:!h-[70%] xxs:mt-4 md:mt-0 md:!h-[84%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProSwingDrop :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
      </div>
    </div>
    <!-- Footer show when width is less than 768 -->
    <div
      :class="[
        selectedScan == true ? '' : 'xxs:hidden md:block',
        width <= 768 ? 'flex justify-center' : '',
      ]"
      class="w-full border-t xxs:h-[5rem] sm:h-[4rem] py-2 px-4 bg-white"
    >
      <ModalFooter
        :firstTitle="isLoading ? 'Cancle' : 'Close'"
        secondTitle="Scan"
        thirdTitle="Scanning..."
        :infoBtnTitle="width <= 880 ? 'Add' : 'Add to favourite'"
        :addIcon="addIcon"
        :colorType="buttonValidation"
        :isProgressBar="isProgressBar"
        :progressPercentageProps="progressPercentage"
        :isDisable="false"
        :isLoading="isLoading"
        @close="closeHandler"
        @save="selectedBtnFunc"
        @stopScan="stopScan"
        @addToFav="handleAddToFavourtire"
        :scannerId="idForScanner"
        :showResultBottom="true"
      />
    </div>

    <div
      :class="selectedScan == false && width <= 768 ? '' : 'xxs:hidden'"
      class="w-full border-t xxs:h-[5rem] sm:h-[4rem] py-2 px-4 bg-white"
    >
      <ModalFooter
        firstTitle="Close"
        secondTitle=""
        thirdTitle="Stop Scan"
        infoBtnTitle=""
        :isProgressBar="isProgressBar"
        :colorType="buttonValidation"
        :isLoading="isLoading"
        @close="closeHandler"
        @save="selectedBtnFunc"
        @stopScan="stopScan"
        @addToFav="handleAddToFavourtire"
      />
    </div>


    <BaseModal v-if="openFavScanModal">
      <InputModal
        @close="closeFavScanModal"
        v-model="favScanName"
        @save="handleSaveFavScan"
        :isLoading="favScanLoading"
        placeholder="Enter name"
        :errorMessage="favScanError"
        :successMessage="favScanSuccess"
        heading="Favourite Scan"
        inputHeading="Please enter a name for favourite scan."
      />
    </BaseModal>
    <BaseModal v-if="openEditFavModal">
      <InputModal
        @close="closeEditNameModal"
        v-model="updatedFavName"
        :initialValue="updatedFavName"
        @save="handleEditScanName"
        :isLoading="favScanLoading"
        placeholder="Enter name"
        :errorMessage="favScanError"
        :successMessage="favScanSuccess"
        heading="Update Favourite Scan"
        inputHeading="Please enter a new name for favourite scan."
      />
    </BaseModal>
    <BaseModal v-if="openDeleteScanModal">
      <RemoveAlertModal
        header="Remove Favourite Scan"
        @remove="handleRemove"
        :message="`Are you sure you want to Remove '${deleteScanData?.scanName}' from your favourite scans?`"
        @closeModal="closeDeleteModal"
        :showError="favScanError"
        :showSuccess="favScanSuccess"
        :isLoading="favScanLoading"
      />
    </BaseModal>
  </div>
</template>

<script>
import { scannerList } from '../../../utils/newScanner.js'
import ChartPatternsIndicator from './ChartPatternsIndicator.vue'
import RightSideHeader from '../../../component/RightSideHeader.vue'
import RemoveAlertModal from '../../../component/RemoveAlertModal.vue'
import Activity from './Activity.vue'
import scans from '../../../services/scans.js'
import ModalFooter from '../../ModalFooter.vue'
import { indicatorSvgsWithName } from './chartSvgs'
import { mapActions, mapGetters } from 'vuex'
import Candle from './Candle.vue'
import Indicators from './Indicators.vue'
import CapitulationScan from './CapitulationScan.vue'
import BolingerSqueeze from './BolingerSqueeze.vue'
import BigCandle from './BigCandle.vue'
import HeikenAshi from './HeikenAshi.vue'
import HigherHighHigherLow from './HigherHighHigherLow.vue'
import DarvasBoxes from './DarvasBoxes.vue'
import Fundamental from './Fundamental.vue'
import DivergenceScan from './DivergenceScan.vue'
import PocketPivot from './PocketPivot.vue'
import ProReversalSignal from './ProReversalSignal.vue'
import ProDowntrendMonitor from './ProDowntrendMonitor.vue'
import ProBreakoutAlert from './proBreakoutAlert.vue'
import ProDivergence from './ProDivergence.vue'
import ProSwingBoostVue from './ProSwingBoost.vue'
import ProSwingDrop from './ProSwingDrop.vue'
import BaseModal from '../../BaseModal.vue'
import InputModal from '../InputModal.vue'
import { chartEvents } from '../../../chartEvents.js'

export default {
  name: 'PatternBaseModal',
  components: {
    RemoveAlertModal,
    InputModal,
    BaseModal,
    ChartPatternsIndicator,
    CapitulationScan,
    RightSideHeader,
    ModalFooter,
    Activity,
    Candle,
    Indicators,
    BolingerSqueeze,
    BigCandle,
    HigherHighHigherLow,
    HeikenAshi,
    DarvasBoxes,
    Fundamental,
    DivergenceScan,
    PocketPivot,
    ProReversalSignal,
    ProDowntrendMonitor,
    ProBreakoutAlert,
    ProDivergence,
    ProSwingBoostVue,
    ProSwingDrop,
  },
  data() {
    return {
      // scannerNameList: JSON.parse(JSON.stringify(scannerList)),
      isProgressBar: true,
      isSingleEventSelectedPattern: null,
      isValidPattern: null,
      scanTypeMarket: 'scanner',
      favScanError: '',
      favScanSuccess: '',
      favScanLoading: false,
      emptySpaceRegix: /^\s*$/,
      localScanList: [],
      favScanName: '',
      searchQuery: '',
      openFavScanModal: false,
      deleteScanData: null,
      openDeleteScanModal: false,
      width: window.innerWidth,
      selectedFavScan: null,
      selectedScan: false,
      addIcon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.7372 5.19247C13.6343 4.88644 13.3694 4.66331 13.0505 4.61453L9.33853 4.04731L7.67122 0.495469C7.52903 0.192719 7.22519 0 6.89137 0C6.55734 0 6.25372 0.192719 6.11153 0.495469L4.444 4.04753L0.732031 4.61475C0.413093 4.66353 0.148187 4.88644 0.0455934 5.19269C-0.0570003 5.49894 0.0204372 5.83625 0.245968 6.06769L2.95672 8.84734L2.31469 12.7798C2.26175 13.1053 2.39978 13.4326 2.66906 13.6229C2.93812 13.8132 3.29337 13.8324 3.58278 13.6728L6.89159 11.8429L10.2004 13.6728C10.331 13.7449 10.4747 13.7804 10.6178 13.7804C10.7919 13.7804 10.966 13.7274 11.1141 13.6229C11.3834 13.4328 11.5212 13.1055 11.4683 12.7798L10.8263 8.84734L13.5372 6.06769C13.7623 5.83625 13.8398 5.49894 13.7372 5.19247Z"
            fill="white"
          />
        </svg>
      ),

      scannerNameList: [
        {
          id: 1,
          name: 'Charting Patterns',
          scanType: 'scanner',
          tabs: [
            { name: 'Charting Patterns' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onScanClick,
          image: 'charting-patterns',
          value: 'patterns',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
        },
        {
          id: 2,
          name: 'Activity',
          onClickHandler: this.onActivityClick,
          scanType: 'scanner',
          tabs: [{ name: 'Activity' }, { name: 'Filters' }],
          image: 'activity',
          value: 'activity',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 3,
          name: 'Candlestick',
          scanType: 'scanner',
          tabs: [{ name: 'Candlestick' }, { name: 'Filters' }],
          onClickHandler: this.onCandlestickClick,
          image: 'candlestick',
          value: 'candlesticks',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 4,
          name: 'Technical Indicators',
          scanType: 'scanner',
          tabs: [
            { name: 'Technical Indicators' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onIndicatorClick,
          image: 'technical-indicators',
          value: 'indicators',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 5,
          name: 'Darvas Box',
          scanType: 'scanner',
          tabs: [{ name: 'Darvas Box' }, { name: 'Filters' }],
          onClickHandler: this.onDarvasClick,
          image: 'darvas-box',
          value: 'darvasScan',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 6,
          name: 'Fundamental',
          scanType: 'scanner',
          tabs: [
            { name: 'Measures' },
            { name: 'Capitalization' },
            { name: 'Returns' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onFundamentalClick,
          image: 'fundamental',
          value: 'fundementals',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },

        {
          id: 7,
          name: 'Capitulation Scan',
          scanType: 'guru',
          tabs: [{ name: 'Capitulation Scan' }],
          onClickHandler: this.onCapitulationClick,
          image: 'capitulation-days',
          value: 'capitulation',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 8,
          name: 'Higher High, Higher Low',
          scanType: 'guru',
          tabs: [{ name: 'Higher High, Higher Low' }],
          onClickHandler: this.onHigherClick,
          image: 'hhll',
          value: 'hhhl',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 9,
          name: 'Bollinger Squeeze',
          scanType: 'guru',
          tabs: [{ name: 'Bollinger Squeeze' }],
          onClickHandler: this.onBollingerClick,
          image: 'bollinger-squeeze',
          type: 'Fixed',
          value: 'bollingerSqueeze',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 10,
          name: 'Divergense Scan',
          scanType: 'guru',
          tabs: [{ name: 'Divergense Scan' }],
          onClickHandler: this.onDivergenceClick,
          image: 'divergence-scan',
          value: 'divergenceScan',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 11,
          name: 'Big Candle',
          scanType: 'guru',
          tabs: [{ name: 'Big Candle' }],
          onClickHandler: this.onBigCandleClick,
          image: 'big-candle',
          value: 'bigCandle',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 12,
          name: 'Heiken Ashi',
          scanType: 'guru',
          tabs: [{ name: 'Heiken Ashi' }],
          onClickHandler: this.onHeikenAshiClick,
          image: 'heiken-ashi',
          value: 'heikenAshi',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 13,
          name: 'Pocket Pivot',
          scanType: 'guru',
          tabs: [{ name: 'Pocket Pivot' }],
          onClickHandler: this.onPocketPivotClick,
          image: 'pocket-pivot',
          value: 'pocketPivot',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 14,
          name: 'ProReversal Signal',
          scanType: 'scanner',
          tabs: [{ name: 'ProReversal Signal' }, { name: 'Filters' }],
          onClickHandler: this.onProreversalSignalClick,
          image: 'proReversal-signal',
          value: 'proReversalSignal',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 15,
          name: 'ProDowntrend Monitor',
          scanType: 'scanner',
          tabs: [
            { name: 'ProDowntrend Monitor' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProDowntrendMonitorClick,
          image: 'proDowntrend-Monitor',
          value: 'proDowntrendMonitor',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 16,
          name: 'ProBreakout Alert',
          scanType: 'scanner',
          tabs: [{ name: 'ProBreakout Alert' }, { name: 'Filters' }],
          onClickHandler: this.onProbreakoutAlertClick,
          image: 'proBreakout-alert',
          value: 'proBreakoutAlert',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 17,
          name: 'ProDivergence Watch',
          scanType: 'scanner',
          tabs: [
            { name: 'ProDivergence Watch' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProDivergenceWatchClick,
          image: 'proDivergence-watch',
          value: 'proDivergenceWatch',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 18,
          name: 'ProSwing Boost',
          scanType: 'scanner',
          tabs: [
            { name: 'ProSwing Boost' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProSwingBoostClick,
          image: 'proSwing-Boost',
          value: 'proSwingBoost',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 19,
          name: 'ProSwing Drop',
          scanType: 'scanner',
          tabs: [
            { name: 'ProSwing Drop' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProSwingDropClick,
          image: 'proSwing-drop',
          value: 'proSwingDrop',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
      ],

      selectedScanner: JSON.parse(JSON.stringify(scannerList[0])),
      scannerId: {
        id: 1,
        name: 'Charting Patterns',
        scanType: 'scanner',
        tabs: [
          { name: 'Charting Patterns' },
          { name: 'Events' },
          { name: 'Filters' },
        ],
        onClickHandler: this.onScanClick,
        image: 'charting-patterns',
        value: 'patterns',
        type: 'Handheld',
        status: 'Active',
        lastChecked: '2024-08-01 14:23:45',
      },
      selectedButtonTypeName: 'Charting Patterns',
      selectedBtnFunc: this.onScanClick, // selectedButtonTypeName: 'null',
      isLoading: false,
      editData: null,
      updatedFavName: '',
      openEditFavModal: false,
      lenPattern: null,
      candleStickPattern: null,
      isValidIndicatorSetting: null,
      isValidIndicator: null,
      isMeasuresValid: null,
      isCapitalizationValid: null,
      isReturnsValid: null,
    }
  },
  props: {
    progressPercentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      'userSavedScans',
      'getUserId',
      'getPatternSettings',
      'getCheckCount',
      'getIndicatorSetting',
      'getIsShowProgressiveBar',
    ]),
    buttonValidation() {
      if (this.scannerId?.value == 'patterns') {
        if (this.isSingleEventSelectedPattern || this.isValidPattern) {
          return 'color'
        } else {
          return 'isValidScanner'
        }
      } else if (this.scannerId?.value == 'candlesticks') {
        if (this.candleStickPattern) {
          return 'color'
        } else {
          return 'isValidScanner'
        }
      } else if (this.scannerId?.value == 'indicators') {
        if (this.isValidIndicator || this.isValidIndicatorSetting) {
          return 'color'
        } else {
          return 'isValidScanner'
        }
      } else if (this.scannerId?.value == 'fundementals') {
        if (
          this.isMeasuresValid ||
          this.isCapitalizationValid ||
          this.isReturnsValid
        ) {
          return 'color'
        } else {
          return 'isValidScanner'
        }
      }
      return 'color'
    },

    patternSettingsData() {
      const patternId = 'pattern'
      return this.getPatternSettings(patternId)
    },
    filteredScanners() {
      // Return full array if searchQuery is empty
      if (this.searchQuery == '') {
        return this.scannerNameList
      }
      // Otherwise, filter the array based on searchQuery
      return this.scannerNameList.filter((scanner) =>
        scanner.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },

    idForScanner() {
      if (this.selectedScanner.name == this.scannerId.name) {
        return this.selectedScanner.value
      }
    },
    typeOfScan() {
      if (this.selectedScanner.scanType == 'guru') {
        return 'guru'
      } else {
        return 'scanner'
      }
    },
    scanBtn() {
      return this.isLoading ? 'Stop' : 'Scan'
    },
  },
  methods: {
    ...mapActions([
      'savePatternsScanRequest',
      'sendPatternsScanRequest',
      'cancelScans',
      'sendCandlestickScanRequest',
      'sendIndicatorsScanRequest',
      'sendActivityScanRequest',
      'sendCapitulationRequest',
      'sendBigCandleScanRequest',
      'sendHeikenAshiScanRequest',
      'sendBollingerSqueezePatternRequest',
      'sendHigherHighHigherLowPatternRequest',
      'sendDarvasScanRequest',
      'sendFundementalScanRequest',
      'sendDivergenceScanRequest',
      'sendPocketPivotScanRequest',
      'sendProReversalScanRequest',
      'sendProDowntrendScanRequest',
      'sendProBraakoutRequest',
      'sendDivergenveRequest',
      'sendProSwingScanRequest',
      'getUserScanList',
      'saveActivityScanRequest',
      'saveCandlestickScanRequest',
      'saveIndicatorsScanRequest',
      'saveDarvasScanRequest',
      'saveFundementalScanRequest',
      'saveCapitulationScanRequest',
      'updateMarketAccordingTODataSettings',
      'sendProSwingScanRequest',
      'updatedScanResult',
      'sendProSwingDownScanRequest',
      'emptyProgressBar',
      'updateProgressiveBarForGuru',
      'updateProgressiveBarForScanner',
      'updateIsShowProgressiveBar',
      'updateNameOfScan'
    ]),
    returns(re) {
      console.log('result', re)
      this.isReturnsValid = re
    },
    capitalization(cap) {
      console.log('cap', cap)
      this.isCapitalizationValid = cap
    },
    measuresHandler(measures) {
      console.log('measure', measures)
      this.isMeasuresValid = measures
    },
    indicatorHandler(ind) {
      this.isValidIndicatorSetting = ind
    },
    isValidHandlerIndicator(isValidTechnicalIndicator) {
      this.isValidIndicator = isValidTechnicalIndicator
    },
    isSelectedCandleStick(candleStick) {
      this.candleStickPattern = candleStick
    },
    isValidChartingPattern(patt) {
      this.isValidPattern = patt
    },
    isCheckScanRun(scannerSetting, len) {
      if (scannerSetting || len > 0) {
        return true
      } else {
        return false
      }
    },

    updateScreenWidth() {
      this.width = window.innerWidth
    },
    // onResize() {
    //   this.width = window.innerWidth
    // },
    toBack() {
      this.selectedScan = false
    },
    isValidHandler(valid, scannerName) {
      this.isSingleEventSelectedPattern = valid
      // this.lenPattern = this.isLengthGreterThanZeroForChartingPattern()
    },
    stopScan() {
      console.log("stop scan====>")
      this.isLoading = false;
      this.cancelScans()
    },
    closeEditNameModal() {
      this.editData = null
      this.updatedFavName = ''
      this.openEditFavModal = false
    },
    async handleEditScanName() {
      if (this.emptySpaceRegix.test(this.updatedFavName)) {
        this.favScanError = 'Name is required'
        return
      }
      let isNamePresent = this.userSavedScans?.some(
        (item) => item.scanName == this.updatedFavName
      )
      if (isNamePresent) {
        this.favScanError = 'Name already exists'
        return
      }

      this.favScanLoading = true
      try {
        let payload = {
          userId: this.editData?.userId,
          scanId: this.editData?.id,
          newName: this.updatedFavName,
        }
        let updateScan = await scans.updateScanName(payload)
        if (updateScan && updateScan.data && updateScan.data.isAcknowledged) {
          this.favScanSuccess = 'Name updated successfully'
          this.getUserScanList()
        } else {
          this.favScanError = 'Failed to edit scan'
        }
      } catch (err) {
        this.favScanError = 'Failed to edit scan'
      }
      this.favScanLoading = false
      // this.closeEditNameModal()
    },
    handleEditName(item) {
      this.editData = item
      this.updatedFavName = item?.scanName
      this.openEditFavModal = true
    },
    handleFavScanSelect(item) {
      chartEvents.$emit('favClick')
      let selectedFav = this.scannerNameList?.find(
        (x) => x.value == item?.data?.scanType
      )
      if (selectedFav) {
        this.selectedFavScan = item?.scanName
        let payload = {
          ...item?.data,
          id: item?.data?.scanType,
        }
        setTimeout(() => {
          this.updateMarketAccordingTODataSettings(payload)
          this.handlerSelectedScanner(selectedFav, false)
        }, 500)
      }
    },
    closeFavScanModal() {
      this.openFavScanModal = false
    },
    async handleRemove() {
      let isNamePresent = this.userSavedScans?.some(
        (item) => item.scanName == this.deleteScanData?.scanName
      )

      if (!isNamePresent) {
        this.favScanError = 'No scanner found'
        return
      }

      this.favScanLoading = true
      try {
        let deleteScan = await scans.deleteScanId(
          this.getUserId,
          this.deleteScanData?.id
        )
        if (deleteScan && deleteScan.data && deleteScan.data.deletedCount) {
          this.favScanSuccess = 'Scan successfully deleted'
          this.getUserScanList()
        } else {
          this.favScanError = 'Failed to delete scan'
        }
      } catch (err) {
        this.favScanError = 'Failed to delete scan'
      }
      this.favScanLoading = false
      // this.closeDeleteModal()
    },
    closeDeleteModal() {
      this.deleteScanData = null
      this.openDeleteScanModal = false
    },
    handleFavDelete(val) {
      this.deleteScanData = val
      this.openDeleteScanModal = true
    },
    async handleSaveFavScan() {
      if (this.emptySpaceRegix.test(this.favScanName)) {
        this.favScanError = 'Name is required'
        return
      }

      let isNamePresent = this.userSavedScans?.some(
        (item) => item.scanName == this.favScanName
      )
      if (isNamePresent) {
        this.favScanError = 'Name already exists'
        return
      }
      this.favScanLoading = true
      if (this.scannerId?.value == 'patterns') {
        const res = await this.savePatternsScanRequest(this.favScanName)
        if (res?.data?.id) {
          this.favScanSuccess = 'Scanner added successfully'
        } else {
          this.favScanError = 'Failed to add to fav scanner'
        }
      }
      if (this.scannerId?.value == 'activity') {
        const res = await this.saveActivityScanRequest(this.favScanName)
        if (res?.data?.id) {
          this.favScanSuccess = 'Scanner added successfully'
        } else {
          this.favScanError = 'Failed to add to fav scanner'
        }
      }
      if (this.scannerId?.value == 'candlesticks') {
        const res = await this.saveCandlestickScanRequest(this.favScanName)
        if (res?.data?.id) {
          this.favScanSuccess = 'Scanner added successfully'
        } else {
          this.favScanError = 'Failed to add to fav scanner'
        }
      }
      if (this.scannerId?.value == 'indicators') {
        const res = await this.saveIndicatorsScanRequest(this.favScanName)
        if (res?.data?.id) {
          this.favScanSuccess = 'Scanner added successfully'
        } else {
          this.favScanError = 'Failed to add to fav scanner'
        }
      }
      if (this.scannerId?.value == 'darvasScan') {
        const res = await this.saveDarvasScanRequest(this.favScanName)
        if (res?.data?.id) {
          this.favScanSuccess = 'Scanner added successfully'
        } else {
          this.favScanError = 'Failed to add to fav scanner'
        }
      }
      if (this.scannerId?.value == 'fundementals') {
        const res = await this.saveFundementalScanRequest(this.favScanName)
        if (res?.data?.id) {
          this.favScanSuccess = 'Scanner added successfully'
        } else {
          this.favScanError = 'Failed to add to fav scanner'
        }
      }
      if (this.scannerId?.value == 'capitulation') {
        await this.saveCapitulationScanRequest()
      }
      if (this.scannerId?.value == 'hhhl') {
        console.log('no favourite')
      }
      if (this.scannerId?.value == 'bollingerSqueeze') {
        console.log('no favourite')
      }
      if (this.scannerId?.value == 'divergenceScan') {
        console.log('no favourite')
      }
      if (this.scannerId?.value == 'bigCandle') {
        console.log('no favourite')
      }
      if (this.scannerId?.value == 'heikenAshi') {
        console.log('no favourite')
      }
      if (this.scannerId?.value == 'pocketPivot') {
        console.log('no favourite')
      }

      this.favScanLoading = false

      // this.closeFavScanModal()
      await this.getUserScanList()
    },
    async onPocketPivotClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendPocketPivotScanRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true)
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onProSwingDropClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendProSwingDownScanRequest()
      if (response) {
        this.updatedScanResult(response)
      this.updateNameOfScan('ProSwingDrop')
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onProSwingBoostClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendProSwingScanRequest()
      if (response) {
        this.updatedScanResult(response)
        this.updateNameOfScan('ProSwingBoost')
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onProDivergenceWatchClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendDivergenveRequest()
      if (response) {
        this.updatedScanResult(response)
        this.updateNameOfScan('ProDivergence')
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onProbreakoutAlertClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendProBraakoutRequest()
      if (response) {
        this.updatedScanResult(response)
        this.updateNameOfScan('ProBreakoutAlert')
        this.updateIsShowProgressiveBar(true) 
        this.isLoading = false
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onProDowntrendMonitorClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendProDowntrendScanRequest();
      console.log("response===>",response)
      if (response) {
        console.log("tradedown")
        this.updateNameOfScan('ProDownTrendMonitor')
        this.updatedScanResult(response)
        this.isLoading = false
      }
      //if (!this.isLoading) this.closeHandler()
    },
    async onProreversalSignalClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendProReversalScanRequest()
      if (response){ 
        this.updateNameOfScan('ProReversalScan')
      this.updatedScanResult(response)
      this.isLoading = false
      }
      //if (!this.isLoading) this.closeHandler()
    },

    async onFundamentalClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendFundementalScanRequest()
      if (response) {
        console.log("response",response);
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onDarvasClick() {
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendDarvasScanRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onIndicatorClick() {
      console.log("indicators===>")
      this.isLoading = true
      // this.closeHandler()
      const response = await this.sendIndicatorsScanRequest()
      // console.log("indicator response==>",response);
      if (response) {
        console.log("res==>",response)
        this.updatedScanResult(response)
        this.updateIsShowProgressiveBar(true)
        this.isLoading = false
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onActivityClick() {
      this.isLoading = true;
      const response = await this.sendActivityScanRequest()
      // this.closeHandler()
      if (response) {
        console.log("res==>",response);
        this.updateIsShowProgressiveBar(true)
        this.updatedScanResult(response)
        this.isLoading = false
      }
      this.isLoading = false
      // //if (!this.isLoading) this.closeHandler()
    },
    async onScanClick() {
      this.isLoading = true
      // this.lenPattern = this.isLengthGreterThanZeroForChartingPattern()
      if (this.isSingleEventSelectedPattern || this.isValidPattern) {
        // this.updateProgressiveBarForScanner('patterns')
        this.updateIsShowProgressiveBar(true)
        chartEvents.$emit('clearScanner')
        // this.closeHandler()
        // this.isLoading = true
        const response = await this.sendPatternsScanRequest()
        if (response) {
          this.updatedScanResult(response)
          this.isLoading = false
        }
      }
      // this.emptyProgressBar(0)
      // this.isLoading = false
      // //if (!this.isLoading) this.closeHandler()
    },

    async onCapitulationClick() {
      console.log('capitulation scanner=====>')
      this.isLoading = true
      const response = await this.sendCapitulationRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      console.log('response====>', response)
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onBollingerClick() {
      this.isLoading = true
      const response = await this.sendBollingerSqueezePatternRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false;
        this.updateIsShowProgressiveBar(true) 
      }
      console.log('response====>', response)
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },

    async onHigherClick() {
      console.log('capitulation scanner')
      this.isLoading = true
      const response = await this.sendHigherHighHigherLowPatternRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      console.log('response====>', response)
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onBigCandleClick() {
      console.log('BigCandle scanner=====>')
      this.isLoading = true
      const response = await this.sendBigCandleScanRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      console.log('response====>BC', response)
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onDivergenceClick() {
      this.isLoading = true
      const response = await this.sendDivergenceScanRequest()
      if(response){
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onHeikenAshiClick() {
      console.log('Heiken Ashi scanner=====>')
      this.isLoading = true
      const response = await this.sendHeikenAshiScanRequest()
      if(response){
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      console.log('response====>BC', response)
      this.isLoading = false
      //if (!this.isLoading) this.closeHandler()
    },
    async onCandlestickClick() {
      this.isLoading = true
      const response = await this.sendCandlestickScanRequest()
      if (response) {
        this.updatedScanResult(response)
        this.isLoading = false
        this.updateIsShowProgressiveBar(true) 
      }
      this.isLoading = false
      // if (!this.isLoading) this.closeHandler()
    },
    // async onScanClick() {
    //   // this.lenPattern = this.isLengthGreterThanZeroForChartingPattern()
    //   if (this.isSingleEventSelectedPattern || this.isValidPattern) {
    //     console.log('verified')
    //     chartEvents.$emit('clearScanner')
    //     // this.closeHandler()
    //     // this.isLoading = true
    //     const response = await this.sendPatternsScanRequest()
    //     if (response) this.updatedScanResult()
    //   } else {
    //     console.log('not verified')
    //   }
    //   // this.emptyProgressBar(0)
    //   // this.isLoading = false
    //   // if (!this.isLoading) this.closeHandler()
    // },

    selectedButtonNameType(payload) {
      this.selectedButtonTypeName = payload
    },
    handleAddToFavourtire() {
      this.openFavScanModal = true
    },
    handlerSelectedScanner(scanner, isType = true) {
      this.selectedScan = true
      if (isType) {
        this.selectedFavScan = null
      }
      if (scanner.name == 'Fundamental') {
        this.selectedButtonTypeName = 'Measures'
      } else {
        this.selectedButtonTypeName = scanner.name
      }
      this.selectedBtnFunc = scanner.onClickHandler
      this.scannerId = scanner
      this.selectedScanner = scanner
      this.updateIsShowProgressiveBar(false) 
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        this.closeHandler()
      }
    },
    closeHandler() {
      this.$emit('handleCloseEmitPattern')
    },
    getChartSvgs(name, boolean) {
      return indicatorSvgsWithName(name, boolean)
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    if (this.getSelectedWatchlist?.id) {
      // this.isActive = this.getSelectedWatchlist?.id
    }
    // window.addEventListener('resize', this.onResize)
    window.addEventListener('resize', this.updateScreenWidth)
    document.addEventListener('keydown', this.handleEscapeKey)
    console.log('this.selectedScanner', this.scannerId)
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateScreenWidth)
    document.addEventListener('keydown', this.handleEscapeKey)
  },
  watch: {
    favScanError() {
      setTimeout(() => {
        this.favScanError = ''
      }, 3000)
    },
    favScanSuccess() {
      setTimeout(() => {
        this.favScanSuccess = ''
      }, 3000)
    },
    selectedButtonTypeName(val) {
      console.log('value==>', val)
    },
    progressPercentage(val) {
      console.log('valueInPattern==>', val)
    },
  },
}
</script>

<style scoped></style>
