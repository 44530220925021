<!-- <template>
  <dialog-box
    :modalHeader="modelHeader"
    :open="open"
    class="fontSize"
    v-model="open"
    @onClose="onCancel"
    @ok="onSave"
  >
    <div class="input-container fontSize" v-if="isPnf">
      <span> Box size </span>
      <input
        style="width: 10ch"
        type="number"
        min="0.1"
        step="0.01"
        v-model="boxSize"
      />
    </div>
    <div class="input-container fontSize" v-if="isPnf">
      <span> Reversal </span>
      <input
        style="width: 10ch"
        type="number"
        min="1"
        step="1"
        v-model="reversal"
      />
    </div>
    <sui-form-fields>
       <sui-form-field style="margin-bottom:10px">
        <sui-input
          v-model="maxPrice"
          type="number"
          tabindex="1"
      
        />
        Max price scale
      </sui-form-field>
      <sui-form-field style="margin-bottom:10px" >
        <sui-input
          v-model="minPrice"
          type="number"
          tabindex="1"
  
        />
        Min price scale
      </sui-form-field>
    </sui-form-fields>
    <sui-button  style="margin-bottom:10px" @click.prevent="handleResetScale()" size="mini">
      Set Default Scale
    </sui-button>
  </dialog-box>
</template> -->



<template>

  <div
    class="xxs:w-[88vw] md:w-[60vw] h-[37vh] lg:w-[35vw] -px-3 space-y-1 bg-white !overflow-hidden"
  >
    <div
      class="w-full !h-[50px] !border-b border-[#D1D1D1] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
    >
      <ModalHeader :header="modelHeader" @close="onCancel" />
    </div>

    <div class="w-full h-[70%] px-4 py-3 scroll-container">
      <div class="w-full grid grid-cols-2 gap-4">
        <div v-if="isPnf">
          <div>Box size</div>
          <input type="number" v-model="boxSize" min="0.1" step="0.01" placeholder="box size value" class="inputStyle" />
        </div>
        <div v-if="isPnf">
          <div>Reversal</div>
          <input type="number" min="1" v-model="reversal"  step="1" placeholder="reversal value" class="inputStyle" />
        </div>
        <div>
          <div> Max price scale</div>
          <input type="number" v-model="maxPrice" tabindex="1" placeholder="max price scale value" class="inputStyle" />
        </div>
        <div>
          <div>Min price scale</div>
          <input type="number"  tabindex="1"  v-model="minPrice" placeholder="min price scale value" class="inputStyle" />
        </div>
      </div>
      <div class="mt-3">
        <sui-button  style="margin-bottom:10px" @click.prevent="handleResetScale()" size="mini">
          Set Default Scale
        </sui-button>
      </div>
    </div>

    <div
      class="w-full !h-[50px] !border-t border-[#D1D1D1] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
    >
      <ModalFooter
        firstTitle="Cancel"
        secondTitle="Save"
        colorType="color"
        @close="onCancel"
        @save="onSave"
      />
    </div>
  </div>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ModalHeader from '../ModalHeader.vue';
import ModalFooter from '../ModalFooter.vue';
import { chartEvents } from '../../chartEvents'
export default {
  components: { DialogBox,ModalHeader,ModalFooter },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'MainChartProps',
  data() {
    return {
      isLoading: false,
      maxPrice: 0,
      minPrice: 0,
      boxSize: 0,
      reversal: 0,
    }
  },
  methods: {
    ...mapActions(['setChartSettingValue']),
    ...mapMutations(['MAIN_CHART_PROPS']),
    onCancel() {
      this.updateStoreValue()
      this.MAIN_CHART_PROPS(false)
    },
    updateStoreValue(){
      this.setChartSettingValue({maxPrice:this.maxPrice,minPrice:this.minPrice,boxSize:this.boxSize,reversal:this.reversal})
    },
    handleResetScale() {
      this.tv.toggleSideBarYAxis()
      this.minPrice=0;
      this.maxPrice=0;
      this.updateStoreValue()
      this.MAIN_CHART_PROPS(false)
    },
    updateInputValue(){
      this.boxSize = this.getChartSettingValue.boxSize
      this.maxPrice = this.getChartSettingValue.maxPrice
      this.minPrice = this.getChartSettingValue.minPrice
      this.reversal = this.getChartSettingValue.reversal
    },
    onSave() {
      this.updateStoreValue()
      if (this.isPnf) {
        chartEvents.$emit('PnfValueUpdate', {
          boxSize: this.boxSize,
          reversal: this.reversal,
        })
      }
      this.tv?.toggleSidebarCustomRange([Number(this.maxPrice),Number(this.minPrice)])
      // setTimeout(()=>{
      //   this.dc.tv.updateChart()
      // },5000)
      this.MAIN_CHART_PROPS(false)
    },
    PnfValueSetterToModal(val) {
      this.boxSize = val.boxSize
      this.reversal = val.reversal
    },
  },
  computed: {
    ...mapGetters(['getChartType', 'tv','dc','getChartSettingValue']),
    modelHeader() {
      return `Chart Settings ${this.getChartType}`
    },
    isPnf() {
      return this.getChartType === 'PnF'
    },
    isCandles() {
      return this.getChartType === 'Candles'
    },
  },
  mounted() {

    this.updateInputValue()

    chartEvents.$on('PnfValueSetterToModal', this.PnfValueSetterToModal)
  },
  beforeDestroy() {
    console.log('PnfValueSetterToModal destroy')
    chartEvents.$off('PnfValueSetterToModal')
  },
}
</script>

<style>



</style>
