<template>
  <div class="w-full h-[95vh] flex flex-col dark:bg-[#1A1A1A] items-center shrink-0 !zIndex">
    <div class="w-full flex justify-center">
      <div
        :class="[{ 'cursor-not-allowed pointer-events-none opacity-50': isDisabled }, 'w-full -h-auto flex flex-col mt-[0.3rem] -justify-around items-center']"
      >
        <!-- RightBarUpdates Btns -->
        <div
          v-for="(svgs, i) in icons"
          :key="i"
          @click="sideBarToggle(svgs?.name)"
          v-tooltip.right="{ content: svgs?.toolTip, class: 'tooltip-custom' }"
          :class="[
            selectedRight === svgs?.name
              ? '!bg-appBtnActive dark:!bg-[#2D2D2D] text-appBtnActiveTxt'
              : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover text-appBtnTxt',
            isDisabled ? 'pointer-events-none opacity-50' : ''
          ]"
          class="md:w-[43.2px] md:h-[43.2px] bg-white dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
        >
          <div v-html="getSvg(svgs?.name)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from '../assets/rightsidebarsvgs/utils/SvgUtils'
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

Vue.use(Tooltip);

export default {
  name: 'RightBarUpdate',
  props: {
    selectedRight: String,
  },
  data() {
    return {
      icons: [
        { id: 1, name: 'watchlist', toolTip: 'Watchlist' },
        { id: 2, name: 'scannerResults', toolTip: 'Scanner Results' },
        { id: 3, name: 'trainingMode', toolTip: 'Training Mode' },
        { id: 4, name: 'comparingSymbols', toolTip: 'Comparing Symbols' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getScannerRightbarOpen', 'isTrainingMode', 'getTogglePlay']),
    isDisabled() {
      return this.isTrainingMode
    },
  },
  methods: {
    ...mapActions(['updateTogglePlay']),
    getSvg(name) {
      return getSvgByName(name)
    },
    sideBarToggle(name) {
      if (name !== 'comparingSymbols' && this.isTrainingMode && this.getTogglePlay) {
        this.updateTogglePlay()
      }
      console.log("sidebarToggle==>",name);
      this.$emit('sideBarToggle', name)
    },
  },
  watch: {
    getScannerRightbarOpen(val) {
      if (val && this.selectedRight !== 'scannerResults') {
        setTimeout(() => {
          this.sideBarToggle('scannerResults')
        }, 200)
      }
    },
  },
}
</script>

<style scoped>
.zIndex {
  z-index: 100 !important;
}
.tooltip-custom {
  background-color: #fff; /* Dark background */
  color: #000; /* Light text */
  animation-duration: 1.5s;
  animation-delay: 1s;
  font-size: 0.875rem; /* Adjust font size */
  /* padding: 8px 12px; Inner padding */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Drop shadow */
  border: 1px solid #666; /* Border color */
  right: 30px !important;
  position: absolute;
  max-width: 20px; /* Limit width */
  z-index: 1000000 !important; /* Ensure it’s above other elements */
}
/* .tooltip-other-custom {
  Additional custom styles, if needed
} */

</style>
