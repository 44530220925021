<template>
  <div
    class="relative flex flex-col justify-between bg-yellow xxs:!w-[100vw] md:!w-[750px] -lg:!w-[55vw] 2lg:!w-[80vw] xxs:h-[100svh] md:h-[90vh] !overflow-hidden"
  >
    <!-- Header -->
    <div
      :class="[
        isLoading ? 'pointer-events-none' : '',
        selectedScan == false ? 'h-[6%]' : 'xxs:h-[6%] md:h-[6%]',
      ]"
      class="!w-full border-b -bg-green-100 -border-green-400"
    >
      <div
        :class="selectedScan == false ? '!h-[100%]' : 'xxs:h-full md:h-[100%]'"
        class="w-full -bg-slate-500 px-4 -border-2 -border-green-800 flex flex-row justify-between items-center space-x-4"
      >
        <div class="w-auto flex flex-row space-x-3">
          <span @click="toBack" v-if="selectedScan && width <= 880">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          <div class="text-[#6D6D6D] text-[18px]">
            Market Insight - {{ selectedScanner.name }}
          </div>
        </div>

        <span
          @click="$emit('market-insight-close')"
          class="cursor-pointer p-2 rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
    </div>
    <!-- Main  -->

    <div
    ref="rightRef" 
      class="-bg-blue-200 w-full flex flex-row min-h-[30rem]"
      :style="{ height: `calc(100% - 12%)` }"
    >
      <!-- Left Side -->
      <div
        :class="selectedScan == false ? '' : 'xxs:hidden md:block'"
        class="xxs:w-full md:w-[200px] -bg-gray-300 xl:w-[18%] -bg-slate-300 !h-full mt-1 flex flex-col -rounded-[10px] border-r border-gray dark:!border-[#2D2D2D]"
      >
        <div class="py-3 h-[100%]">
          <div class="mb-4 px-4 bold font-bold xxs:text-[18px] sm:text-[16px]">
            Scan Types
          </div>
          <div class="h-[95%] border overflow-y-auto custom-scrollbar">
            <div
              v-for="(sidebar, index) in filteredScanners"
              :key="index"
              @click="handlerSelectedScanner(sidebar)"
              :class="[
                sidebar.name === selectedScanner.name
                  ? '!bg-[#001a2c] text-white -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]',
                sidebar.name === selectedScanner.name && width >= 880
                  ? 'pointer-events-none'
                  : '',
              ]"
              class="flex flex-row justify-start sm:text-[14px] items-center hover:bg-[#f6f6f6] xxs:px-8 md:px-10 sm:py-[15px] xxs:py-[20px] xxs:space-x-2 md:space-x-3"
            >
              <div
                v-html="
                  getChartSvgs(
                    sidebar?.image,
                    sidebar.name == selectedScanner.name
                  )
                "
              ></div>
              <div class="xxs:text-[18px] sm:text-[14px]">
                {{ sidebar.name }}
              </div>
            </div>
          </div>
        </div>

        <!-- Favourite -->
        <!-- <div class="py-3 h-[20%]">
          <div class="mb-4 px-4 bold xxs:text-[16px] sm:text-[14px] font-bold">
            My Favourite(s)
          </div>
          <div class="w-full h-[80%] pb-6 overflow-y-auto no-scrollbar">
            <div
              v-if="userSavedScans.length > 0"
              v-for="(item, index) in userSavedScans"
              :key="index"
              :class="
                selectedFavScan == item?.scanName
                  ? '!bg-[#ededed] text-black -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]'
              "
              class="flex cursor-pointer justify-between items-center hover:bg-[#f6f6f6] xxs:px-2 md:px-10 py-[12px] xxs:space-x-1 md:space-x-3"
            >
              <div class="flex items-center space-x-3">
                <div v-html="getChartSvgs('favourite', false)"></div>
                <div @click="handleFavScanSelect(item)" class="hover:underline">
                  {{ item.scanName }}
                </div>
              </div>
              <div class="flex items-center space-x-1">
                <div
                  @click="handleEditName(item)"
                  class="hover:bg-gray-200 p-1 rounded-md"
                  title="Edit name"
                  v-html="getChartSvgs('edit', false)"
                ></div>
                <div
                  class="hover:bg-gray-200 p-1 rounded-md"
                  @click="handleFavDelete(item)"
                  title="Delete"
                  v-html="getChartSvgs('delete', false)"
                ></div>
              </div>
            </div>
            <div
              v-if="userSavedScans?.length == 0"
              class="flex justify-center items-center p-3"
            >
              <span>No Favourites Found</span>
            </div>
          </div>
        </div> -->
      </div>

      <!-- Right -->
      <div :style="{width: `calc(100% - 18%)`}" class="flex -bg-blue-200 flex-col xxs:justify-between xxs:w-full md:w-[80%] -min-h-[30rem] xxs:!mb-0 !h-full -bg-yellow-200 -xxs:mt-[-25px] md:mt-0">
      <!-- New things I learn. multiple Renders Components --> 
        <component :is="scannerMap[selectedScanner.value]" />
      </div>
    </div>

    <!-- Footer -->
    <div
      :class="[
        selectedScan == true ? '' : 'xxs:hidden md:block',
        width <= 768 ? 'flex justify-center' : '',
      ]"
      class="w-full border-t xxs:h-[5rem] -sm:h-[4rem] sm:h-[6%] py-2 px-4 -bg-red-100"
    >
      <ModalFooter
        firstTitle="Close"
        secondTitle=""
        thirdTitle="Stop Scan"
        infoBtnTitle=""
        :addIcon="addIcon"
        :colorType="buttonValidation"
        :isProgressBar="isProgressBar"
        :progressPercentageProps="progressPercentage"
        :isDisable="false"
        :isLoading="isLoading"
        @close="$emit('market-insight-close')"
        @save="selectedBtnFunc"
        @stopScan="stopScan"
        @addToFav="handleAddToFavourtire"
        :scannerId="idForScanner"
      />
    </div>

    <div
      :class="selectedScan == false && width <= 768 ? '' : 'xxs:hidden'"
      class="w-full border-t xxs:h-[5rem] sm:h-[4rem] py-2 px-4 bg-white"
    >
      <ModalFooter
        firstTitle="Close"
        secondTitle=""
        thirdTitle="Stop Scan"
        infoBtnTitle=""
        :isProgressBar="isProgressBar"
        :colorType="buttonValidation"
        :isLoading="isLoading"
        @close="$emit('market-insight-close')"
        @save="selectedBtnFunc"
        @stopScan="stopScan"
        @addToFav="handleAddToFavourtire"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { indicatorSvgsWithName } from '../dialogBoxes/patterns/chartSvgs'
import ModalFooter from '../ModalFooter.vue'
import { scannerList } from '../../utils/marketInsight'
import {
  FiftyTwoWeekHigh,
  FiftyTwoWeekLow,
  GapDownStock,
  GapUpStock,
  HighBetaStocks,
  HighDividedYieldStocks,
  HighVolumeStock,
  LowBetaStocks,
  LowBudgetStock,
  OverBought,
  OversoldStock,
  PennyStock,
  ShortSqueezeCandidates,
  SteadyGrowthStock,
  TodayTopGainer,
  TodayTopLosers,
} from './SubMarketInsight'

export default {
  name: 'market_insight',
  data() {
    return {
      selectedScan: false,
      width: window.innerWidth,
      selectedFavScan: null,
      selectedScanner: JSON.parse(JSON.stringify(scannerList[0])),
      searchQuery: '',
      scannerNameList: [
        {
          id: 1,
          name: "Today's Top Gainers",
          onClickHandler: 'onScanTodayTopGainers',
          image: 'today-top-gainer',
          value: 'todayTopGainers',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Identifies stocks with the highest percentage price increase for the day.',
          formula:
            '% Gain = ((Today’s Close - Previous Close) / Previous Close) * 100',
          criteria: 'Sort by highest % gain; Minimum volume: 100,000 shares',
        },
        {
          id: 2,
          name: "Today's Top Losers",
          onClickHandler: 'onScanTodayTopLosers',
          image: 'today-top-losers',
          value: 'todayTopLosers',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Highlights stocks with the largest percentage price decrease for the day.',
          formula:
            '% Loss = ((Previous Close - Today’s Close) / Previous Close) * 100',
          criteria: 'Sort by highest % loss; Minimum volume: 100,000 shares',
        },
        {
          id: 3,
          name: 'High Volume Stocks',
          onClickHandler: 'onScanHighVolumeStocks',
          image: 'high-volume-stocks',
          value: 'highVolumeStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Finds stocks trading with unusually high volume compared to their average, often indicating increased investor interest.',
          formula: 'Volume Ratio = Current Volume / Average Volume (10-day)',
          criteria:
            'Volume Ratio > 2; Minimum volume threshold: 500,000 shares',
        },
        {
          id: 4,
          name: 'Steady Growth Stocks (Good Momentum)',
          onClickHandler: 'onScanSteadyGrowthStocks',
          image: 'steady-growth-stocks',
          value: 'steadyGrowthStock',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Identifies stocks showing consistent positive returns over a defined period, indicating steady upward momentum.',
          formula:
            'Momentum = (Today’s Close - Close N Days Ago) / Close N Days Ago * 100',
          criteria:
            'Positive Momentum > 15% over the last 3 months (or 63 trading days); Above 50-day and 200-day moving averages (MA50 > MA200)',
        },
        {
          id: 5,
          name: 'Low Budget Stocks (Under $20)',
          onClickHandler: 'onScanLowBudgetStocks',
          image: 'low-budget-stocks',
          value: 'lowBudgetStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Filters for stocks trading under $20, making them accessible to more budget-conscious investors.',
          criteria: 'Stock Price < $20; Minimum volume: 200,000 shares',
        },
        {
          id: 6,
          name: 'Penny Stocks',
          onClickHandler: 'onScanPennyStocks',
          image: 'penny-stocks',
          value: 'pennyStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Screens for stocks trading under $5, often with higher volatility and potential for quick price swings.',
          criteria: 'Stock Price < $5; Minimum volume: 100,000 shares',
        },
        {
          id: 7,
          name: '52-Week High',
          onClickHandler: 'onScan52WeekHigh',
          image: '52-week-high',
          value: 'fiftyTwoWeekHigh',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Identifies stocks trading at or near their 52-week high, indicating strong upward momentum or bullish sentiment.',
          formula: '52-Week High = Maximum(Price over last 52 weeks)',
          criteria:
            'Current Price >= 95% of 52-week high; Minimum volume: 200,000 shares',
        },
        {
          id: 8,
          name: '52-Week Low',
          onClickHandler: 'onScan52WeekLow',
          image: '52-week-low',
          value: 'fiftyTwoWeekLow',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Finds stocks trading at or near their 52-week low, often considered undervalued or oversold.',
          formula: '52-Week Low = Minimum(Price over last 52 weeks)',
          criteria:
            'Current Price <= 105% of 52-week low; Minimum volume: 200,000 shares',
        },
        {
          id: 9,
          name: 'Oversold Stocks (RSI)',
          onClickHandler: 'onScanOversoldStocks',
          image: 'oversold-stocks',
          value: 'oversoldStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Locates stocks with an RSI (Relative Strength Index) below 30, which could indicate they’re oversold and may experience a rebound.',
          formula:
            'RSI = 100 - (100 / (1 + RS)), where RS = Average Gain / Average Loss over a 14-day period',
          criteria: 'RSI < 30; Minimum volume: 200,000 shares',
        },
        {
          id: 10,
          name: 'Overbought Stocks (RSI)',
          onClickHandler: 'onScanOverboughtStocks',
          image: 'overbought-stocks',
          value: 'overboughtStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Finds stocks with an RSI above 70, indicating they may be overbought and due for a pullback.',
          formula: 'RSI = 100 - (100 / (1 + RS))',
          criteria: 'RSI > 70; Minimum volume: 200,000 shares',
        },
        {
          id: 11,
          name: 'Gap Up Stocks',
          onClickHandler: 'onScanGapUpStocks',
          image: 'gap-up-stocks',
          value: 'gapUpStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Identifies stocks that opened significantly higher than the previous close, often due to positive news or events.',
          formula:
            'Gap % = ((Today’s Open - Previous Close) / Previous Close) * 100',
          criteria: 'Gap % > 3%; Minimum volume: 100,000 shares',
        },
        {
          id: 12,
          name: 'Gap Down Stocks',
          onClickHandler: 'onScanGapDownStocks',
          image: 'gap-down-stocks',
          value: 'gapDownStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Highlights stocks that opened significantly lower than the previous close, often signaling a negative market sentiment.',
          formula:
            'Gap % = ((Previous Close - Today’s Open) / Previous Close) * 100',
          criteria: 'Gap % < -3%; Minimum volume: 100,000 shares',
        },
        {
          id: 13,
          name: 'High Dividend Yield Stocks',
          onClickHandler: 'onScanHighDividendYieldStocks',
          image: 'high-dividend-yield',
          value: 'highDividedYieldStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Screens for stocks with high dividend yields, making them attractive to income-focused investors.',
          formula:
            'Dividend Yield = (Annual Dividend / Current Stock Price) * 100',
          criteria: 'Dividend Yield > 4%; Market Cap > $500 million',
        },
        {
          id: 14,
          name: 'Short Squeeze Candidates',
          onClickHandler: 'onScanShortSqueezeCandidates',
          image: 'short-squeeze-candidates',
          value: 'shortSqueezeCandidates',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Finds stocks with high short interest, which could result in a short squeeze if prices start to rise and short-sellers rush to cover their positions.',
          formula: 'Short Interest Ratio = Short Shares / Average Daily Volume',
          criteria:
            'Short Interest Ratio > 5; Price increase of >5% over the last 3 days',
        },
        {
          id: 15,
          name: 'High Beta Stocks',
          onClickHandler: 'onScanHighBetaStocks',
          image: 'high-beta-stocks',
          value: 'highBetaStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Screens for stocks with high beta values, indicating greater volatility compared to the market.',
          formula:
            'Beta = Covariance of Stock and Market Returns / Variance of Market Returns',
          criteria: 'Beta > 1.5; Minimum volume: 100,000 shares',
        },
        {
          id: 16,
          name: 'Low Beta Stocks',
          onClickHandler: 'onScanLowBetaStocks',
          image: 'low-beta-stocks',
          value: 'lowBetaStocks',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
          description:
            'Filters for stocks with low beta values, which are less volatile and more stable compared to the market.',
          formula:
            'Beta = Covariance of Stock and Market Returns / Variance of Market Returns',
          criteria: 'Beta < 0.8; Minimum volume: 100,000 shares',
        },
      ],
      scannerMap: {
        todayTopGainers: 'TodayTopGainer',
        todayTopLosers: 'TodayTopLosers',
        highVolumeStocks: 'HighVolumeStock',
        lowBudgetStocks: 'LowBudgetStock',
        fiftyTwoWeekHigh: 'FiftyTwoWeekHigh',
        fiftyTwoWeekLow: 'FiftyTwoWeekLow',
        gapDownStocks: 'GapDownStock',
        gapUpStocks: 'GapUpStock',
        highBetaStocks: 'HighBetaStocks',
        highDividedYieldStocks: 'HighDividedYieldStocks',
        lowBetaStocks: 'LowBetaStocks',
        marketInsight: 'MarketInsight',
        overboughtStocks: 'OverBought',
        oversoldStocks: 'OversoldStock',
        pennyStocks: 'PennyStock',
        shortSqueezeCandidates: 'ShortSqueezeCandidates',
        steadyGrowthStock: 'SteadyGrowthStock',
      },
    }
  },
  methods: {
    getChartSvgs(name, boolean) {
      return indicatorSvgsWithName(name, boolean)
    },
    handleFavScanSelect(item) {
      console.log('item==>', item)
    },
    handleEditName(item) {},
    handleFavDelete(item) {},
    handlerSelectedScanner(scanner) {
      this.selectedScanner = scanner
    },
    handleClickOutside(event){
      console.log("event",event);
    },
    handleKeyDown(event){
      if(event.key ==  'Escape'){
        this.$emit('market-insight-close')
      }
    }
  },
  components: {
    ModalFooter,
    FiftyTwoWeekHigh,
    FiftyTwoWeekLow,
    GapDownStock,
    GapUpStock,
    HighBetaStocks,
    HighDividedYieldStocks,
    HighVolumeStock,
    LowBetaStocks,
    LowBudgetStock,
    OverBought,
    OversoldStock,
    PennyStock,
    ShortSqueezeCandidates,
    SteadyGrowthStock,
    TodayTopGainer,
    TodayTopLosers,
  },
  computed: {
    ...mapGetters(['userSavedScans']),
    filteredScanners() {
      if (!this.searchQuery) {
        return this.scannerNameList
      }

      return this.scannerNameList.filter((scanner) => {
        return scanner.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      })
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    document.addEventListener('keydown',this.handleKeyDown)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
    document.removeEventListener('click',this.handleKeyDown)
  },
}
</script>

<style>
</style>