<script>
import Overlay from '../../mixins/overlay.js'

export default {
    name: 'Splitters',
    mixins: [Overlay],
    data() {
        return {}
    },
    computed: {
        sett() {
            return this.$props.settings;
        },
        new_font() {
            return this.sett.font || '12px ' + this.$props.font?.split('px').pop();
        },
        flag_color() {
            return this.sett.flagColor || '#4285f4';
        },
        label_color() {
            return this.sett.labelColor || '#fff';
        },
        line_color() {
            return this.sett.lineColor || '#4285f4';
        },
        line_width() {
            return this.sett.lineWidth || 1.0;
        },
        y_position() {
            return this.sett.yPosition || 0.9;
        },
        label_text() {
            return this.sett.labelText || "Default Text";
        },
        show_arrow() {
            return this.sett.showArrow || false;
        }
    },
    methods: {
        meta_info() {
            return { author: 'C451', version: '1.0.1' };
        },
        draw(ctx) {
            let layout = this.$props.layout;
            ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.line_color;

            this.$props.data.forEach((p, i) => {
                // console.log("splitter p",{p,data: this.$props.data, layout:  this.$props.layout});
                ctx.beginPath();
                let x = layout.t2screen(p[0]); // x - Mapping
                // let x = layout.t2screen(p[0] - 0.5); // x - Mapping /** changed the default behavior added 0.5 */
                ctx.setLineDash([10, 10]);
                ctx.moveTo(x, 0);
                ctx.lineTo(x, this.layout.height);
                ctx.stroke();

                // Draw label
                if (p[1]) this.draw_label(ctx, x, p);
                

                // console.log("splitter closing price finding==>",this.layout.candles[0]?.c);

                // Draw arrow with label in the middle of the line
                // const middleY = this.layout.height / 2;
                let upSplitter = 10;
                const middleY = this.layout.candles[0]?.c - 20; 
                if(this.show_arrow){
                    this.draw_arrow(ctx, x, middleY, this.label_text); // Pass dynamic text
                }
            });
        },

        draw_label(ctx, x, p) {
            let side = p[2] ? 1 : -1;
            x += 2.5 * side;
            ctx.font = this.new_font;
            let pos = p[4] || this.y_position;
            let w = ctx.measureText(p[1]).width + 10;
            let y = this.layout.height * (1.0 - pos);
            y = Math.floor(y);
            ctx.fillStyle = p[3] || this.flag_color;

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x + 10 * side, y - 10 * side);
            ctx.lineTo(x + (w + 10) * side, y - 10 * side);
            ctx.lineTo(x + (w + 10) * side, y + 10 * side);
            ctx.lineTo(x + 10 * side, y + 10 * side);
            ctx.closePath();
            ctx.fill();

            ctx.fillStyle = this.label_color;
            ctx.textAlign = side < 0 ? 'right' : 'left';
            ctx.fillText(p[1], x + 15 * side, y + 4);
        },

        draw_arrow(ctx, x, y, text) {
            const arrowWidth = 10;
            const arrowHeight = 20;
            const padding = 10;
            const boxWidth = ctx.measureText(text).width + 6 * padding;
            const boxHeight = 30;

                // Start drawing from the left of the line and extend to the right
                const startX = x - boxWidth; // Shift left to start the box from the line
                const startY = y - boxHeight / 2; // Center vertically

                // Draw the arrow box with a right-pointing arrow tip
                ctx.fillStyle = '#020617';
                ctx.beginPath();
                ctx.moveTo(startX, startY); // Left-top corner of the box
                ctx.lineTo(startX + boxWidth - arrowWidth, startY); // Move to the right side before the arrow
                ctx.lineTo(startX + boxWidth, y); // Arrow tip pointing to the right
                ctx.lineTo(startX + boxWidth - arrowWidth, startY + boxHeight); // Right-bottom corner before arrow
                ctx.lineTo(startX, startY + boxHeight); // Left-bottom corner of the box
                ctx.closePath();
                ctx.fill();

                // Draw the text inside the box, with padding for spacing
                ctx.fillStyle = this.label_color;
                ctx.font = this.new_font;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(text, startX + boxWidth / 2 - arrowWidth / 2, y); // Center the text within the box
            },

        use_for() { return ['Splitters'] }
    }
}
</script>
