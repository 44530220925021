<template>
  <div
    @click="handlePress"
    class="font-[600] text-[12px] min-w-[120px] w-[120px] h-[36px] cursor-pointer flex items-center justify-center space-x-2 -py-2 border-[#BCBCBC] border rounded-[6px] !shrink-0"
    :class="isLoading ? 'bg-red-600 text-white' : ''"
  >
    <span>{{ title }}</span>
  </div>
</template>
  
  <script>
export default {
  name: 'ButtonComp',

  data() {
    return {}
  },

  props: ['colorType', 'title', 'isLoading', 'isDisable', 'dangerous'],
  components: {},

  methods: {
    handlePress() {
      this.$emit('press')
    },
  },
  watch: {},
}
</script>
  