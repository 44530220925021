export const scannerList = [
  {
    id: 1,
    name: "Today's Top Gainers",
    onClickHandler: 'onScanTodayTopGainers',
    image: 'today-top-gainer',
    value: 'todayTopGainers',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Identifies stocks with the highest percentage price increase for the day.',
    formula: '% Gain = ((Today’s Close - Previous Close) / Previous Close) * 100',
    criteria: 'Sort by highest % gain; Minimum volume: 100,000 shares',
  },
  {
    id: 2,
    name: "Today's Top Losers",
    onClickHandler: 'onScanTodayTopLosers',
    image: 'today-top-losers',
    value: 'todayTopLosers',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Highlights stocks with the largest percentage price decrease for the day.',
    formula: '% Loss = ((Previous Close - Today’s Close) / Previous Close) * 100',
    criteria: 'Sort by highest % loss; Minimum volume: 100,000 shares',
  },
  {
    id: 3,
    name: "High Volume Stocks",
    onClickHandler: 'onScanHighVolumeStocks',
    image: 'high-volume-stocks',
    value: 'highVolumeStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Finds stocks trading with unusually high volume compared to their average, often indicating increased investor interest.',
    formula: 'Volume Ratio = Current Volume / Average Volume (10-day)',
    criteria: 'Volume Ratio > 2; Minimum volume threshold: 500,000 shares',
  },
  {
    id: 4,
    name: "Steady Growth Stocks (Good Momentum)",
    onClickHandler: 'onScanSteadyGrowthStocks',
    image: 'steady-growth-stocks',
    value: 'steadyGrowthStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Identifies stocks showing consistent positive returns over a defined period, indicating steady upward momentum.',
    formula: 'Momentum = (Today’s Close - Close N Days Ago) / Close N Days Ago * 100',
    criteria: 'Positive Momentum > 15% over the last 3 months (or 63 trading days); Above 50-day and 200-day moving averages (MA50 > MA200)',
  },
  {
    id: 5,
    name: "Low Budget Stocks (Under $20)",
    onClickHandler: 'onScanLowBudgetStocks',
    image: 'low-budget-stocks',
    value: 'lowBudgetStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Filters for stocks trading under $20, making them accessible to more budget-conscious investors.',
    criteria: 'Stock Price < $20; Minimum volume: 200,000 shares',
  },
  {
    id: 6,
    name: "Penny Stocks",
    onClickHandler: 'onScanPennyStocks',
    image: 'penny-stocks',
    value: 'pennyStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Screens for stocks trading under $5, often with higher volatility and potential for quick price swings.',
    criteria: 'Stock Price < $5; Minimum volume: 100,000 shares',
  },
  {
    id: 7,
    name: "52-Week High",
    onClickHandler: 'onScan52WeekHigh',
    image: '52-week-high',
    value: 'fiftyTwoWeekHigh',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Identifies stocks trading at or near their 52-week high, indicating strong upward momentum or bullish sentiment.',
    formula: '52-Week High = Maximum(Price over last 52 weeks)',
    criteria: 'Current Price >= 95% of 52-week high; Minimum volume: 200,000 shares',
  },
  {
    id: 8,
    name: "52-Week Low",
    onClickHandler: 'onScan52WeekLow',
    image: '52-week-low',
    value: 'fiftyTwoWeekLow',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Finds stocks trading at or near their 52-week low, often considered undervalued or oversold.',
    formula: '52-Week Low = Minimum(Price over last 52 weeks)',
    criteria: 'Current Price <= 105% of 52-week low; Minimum volume: 200,000 shares',
  },
  {
    id: 9,
    name: "Oversold Stocks (RSI)",
    onClickHandler: 'onScanOversoldStocks',
    image: 'oversold-stocks',
    value: 'oversoldStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Locates stocks with an RSI (Relative Strength Index) below 30, which could indicate they’re oversold and may experience a rebound.',
    formula: 'RSI = 100 - (100 / (1 + RS)), where RS = Average Gain / Average Loss over a 14-day period',
    criteria: 'RSI < 30; Minimum volume: 200,000 shares',
  },
  {
    id: 10,
    name: "Overbought Stocks (RSI)",
    onClickHandler: 'onScanOverboughtStocks',
    image: 'overbought-stocks',
    value: 'overboughtStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Finds stocks with an RSI above 70, indicating they may be overbought and due for a pullback.',
    formula: 'RSI = 100 - (100 / (1 + RS))',
    criteria: 'RSI > 70; Minimum volume: 200,000 shares',
  },
  {
    id: 11,
    name: "Gap Up Stocks",
    onClickHandler: 'onScanGapUpStocks',
    image: 'gap-up-stocks',
    value: 'gapUpStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Identifies stocks that opened significantly higher than the previous close, often due to positive news or events.',
    formula: 'Gap % = ((Today’s Open - Previous Close) / Previous Close) * 100',
    criteria: 'Gap % > 3%; Minimum volume: 100,000 shares',
  },
  {
    id: 12,
    name: "Gap Down Stocks",
    onClickHandler: 'onScanGapDownStocks',
    image: 'gap-down-stocks',
    value: 'gapDownStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Highlights stocks that opened significantly lower than the previous close, often signaling a negative market sentiment.',
    formula: 'Gap % = ((Previous Close - Today’s Open) / Previous Close) * 100',
    criteria: 'Gap % < -3%; Minimum volume: 100,000 shares',
  },
  {
    id: 13,
    name: "High Dividend Yield Stocks",
    onClickHandler: 'onScanHighDividendYieldStocks',
    image: 'high-dividend-yield',
    value: 'highDividendYieldStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Screens for stocks with high dividend yields, making them attractive to income-focused investors.',
    formula: 'Dividend Yield = (Annual Dividend / Current Stock Price) * 100',
    criteria: 'Dividend Yield > 4%; Market Cap > $500 million',
  },
  {
    id: 14,
    name: "Short Squeeze Candidates",
    onClickHandler: 'onScanShortSqueezeCandidates',
    image: 'short-squeeze-candidates',
    value: 'shortSqueezeCandidates',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Finds stocks with high short interest, which could result in a short squeeze if prices start to rise and short-sellers rush to cover their positions.',
    formula: 'Short Interest Ratio = Short Shares / Average Daily Volume',
    criteria: 'Short Interest Ratio > 5; Price increase of >5% over the last 3 days',
  },
  {
    id: 15,
    name: "High Beta Stocks",
    onClickHandler: 'onScanHighBetaStocks',
    image: 'high-beta-stocks',
    value: 'highBetaStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Screens for stocks with high beta values, indicating greater volatility compared to the market.',
    formula: 'Beta = Covariance of Stock and Market Returns / Variance of Market Returns',
    criteria: 'Beta > 1.5; Minimum volume: 100,000 shares',
  },
  {
    id: 16,
    name: "Low Beta Stocks",
    onClickHandler: 'onScanLowBetaStocks',
    image: 'low-beta-stocks',
    value: 'lowBetaStocks',
    type: 'Handheld',
    status: 'Active',
    lastChecked: '2024-08-01 14:23:45',
    description: 'Filters for stocks with low beta values, which are less volatile and more stable compared to the market.',
    formula: 'Beta = Covariance of Stock and Market Returns / Variance of Market Returns',
    criteria: 'Beta < 0.8; Minimum volume: 100,000 shares',
  }
];
