<template>
  <div class="relative">
        <!-- Box Outer -->
        <div  @click="handleVisibleToggle" :class="screenWidth < 554 ? 'h-[2rem]':''" class="flex flex-row items-center w-[14rem] px-4 py-2 justify-between cursor-pointer rounded-[10px] hover:bg-[#e1ebf4] bg-[#f1f5f9]">
            <span>
              <svg :class="screenWidth < 554 ? 'w-4 h-4':'w-[18px] h-[18px]'" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3. org/2000/svg">
              <g clip-path="url(#clip0_4215_49520)">
              <path d="M12.75 2.25023C12.947 2.05324 13.1808 1.89699 13.4382 1.79038C13.6956 1.68378 13.9714 1.62891 14.25 1.62891C14.5286 1.62891 14.8044 1.68378 15.0618 1.79038C15.3192 1.89699 15.553 2.05324 15.75 2.25023C15.947 2.44721 16.1032 2.68106 16.2098 2.93843C16.3165 3.1958 16.3713 3.47165 16.3713 3.75023C16.3713 4.0288 16.3165 4.30465 16.2098 4.56202C16.1032 4.81939 15.947 5.05324 15.75 5.25023L5.625 15.3752L1.5 16.5002L2.625 12.3752L12.75 2.25023Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_4215_49520">
              <rect width="18" height="18" fill="white"/>
              </clipPath>
              </defs>
              </svg>
            </span>
            <span v-if="screenWidth > 923" class="text-[14px] text-[#020617]">
                Columns visibility
            </span>
        </div>
        <!-- Drop Down Box -->
        <div  v-if="showColumnVisibilityToggle" class="w-[18rem] z-[999] h-fit border space-y-2 p-2 rounded shadow bg-white absolute top-11 right-6">
            <div v-for="(item,i) in columns" :key="i"  @click="toggleColumnVisibility(item.key)" :class="[item.key == 'symbol' ? 'pointer-events-none cursor-default' : 'cursor-pointer', 'flex py-1 items-center px-2 space-x-2 hover:bg-[#f2f2f280]']">
                <span :class="visibileColumn?.includes(item.key) ? 'bg-[#00AA6F] border-[#00aa6f]':'border-[#64748B] box-with-shadow'" class="w-5 h-5 border-[2px] cursor-pointer border rounded-[4px] flex items-center justify-center">
                                <svg v-if="visibileColumn?.includes(item.key)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" class="w-full h-full text-[#ffffff]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg>
                            </span>
                      <span>
                        {{ item?.label }}
                      </span> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "columnVisibility",
    data() {
        return {
            showColumnVisibilityToggle: false,
            screenWidth: window.innerWidth,
        }
    },
    props:{
        columns:{
            type: Array,
            default: []
        },
        visibileColumn:{
            type: Array,
            default: []
        }
    },
    methods: {
        handleVisibleToggle(){
            this.showColumnVisibilityToggle = !this.showColumnVisibilityToggle;
        },
        toggleColumnVisibility(columnVisible){
            this.$emit('visibleHandler',columnVisible)
           
        },
        hideDropDown(){
            console.log("hideDropDown");
        },
      
    },
  
}
</script>

<style>

</style>