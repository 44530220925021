<template>
  <div
    @click="handlePress"
    :class="[
      isDisable ? 'pointer-events-none opacity-50':'',
      colorType == 'color' 
        ? 'text-appBtnActiveTxt bg-appBtnActive hover:bg-BlackBtnHover dark:!border-[#2D2D2D]'
        : colorType == 'green'
        ? 'text-[#FFFFFF] bg-[#00c92e] hover:bg-[#0caf2f] dark:!border-[#2D2D2D]'
        : ' hover:bg-appBtnHover dark:!border-[#2D2D2D] dark:bg-[rgba(255,255,255,0.1)] dark:hover:bg-[#232323] dark:text-[#F6F6F6] text-[#292929]',
        colorType == 'isValidScanner'  ? 'pointer-events-none opacity-50' :  '',
        addIcon == 'addicon' ? 'h-[46px]' : '',
    ]"
    class="font-[600] text-[12px] min-w-[120px] w-[120px] h-[36px] cursor-pointer  flex items-center justify-center space-x-2 -py-2 border-[#BCBCBC] border rounded-[6px] !shrink-0"
  >  
    <span v-if="isLoading">
      <Loader customClass="w-4 h-4" />
    </span>
    <span v-if="title === 'Add'">
      <img :src="addIcon" alt="Add Icon" /> 
    </span>
    <span v-else>
      {{ title }}
    </span>
  </div>
</template>

<script>
import Loader from './Loading.vue'

export default {
  name: 'ButtonComp',

  data() {
    return {
      addIcon: require('../assets/favourite.svg'),
    }
  },

  props: ['colorType', 'title', 'isLoading','isDisable','dangerous'],
  components: {
    Loader,
  },

  methods: {
    handlePress() {
      this.$emit('press')
    },
  },
  watch: {
    colorType(val){
      console.log("colorType",val);
    }
  },
}
</script>
