<template>
  <div class="w-full h-full">
    <div class="w-full flex flex-row justify-center px-4 items-center">
      <div class="w-[100%]">
        <RightBarHead
          :socketScannerResult="socketScannerResult"
          :percentage="progressPercentage"
          :mainHeading="'Scanner Result'"
          :subHeading="`${scanResults.length ? scanResults[scanResults.length - 1].results.length : 0 } results found`"
          :selectedRight="selectedRight"
        />
      </div>
      <!-- :subHeading="`${getScanResult.length ? getScanResult[getScanResult.length - 1].data.results.length : 0 } results found`" -->
      
      <!-- <div class="w-[60%]">
        <ProgressiveBar :stockMessage="stocksMessage"  :percentage="progressPercentage" :showTitle="showTitle"/>
      </div> -->
    </div>

    <div
      :class="
        scanResults?.length > 0
          ? 'overflow-y-auto no-scrollbar'
          : 'overflow-y-hidden'
      "
      class="w-full !h-[calc(100%-4.5rem)] -bg-green-500"
    >
      <!-- <ScannerResultSymbols :scannerResultArray="socketScannerResult" /> -->
      <!-- <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
        <div
          class="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
          :style="{ width: progressPercentage + '%' }"
        > </div>
      </div> -->
      <ScannerResultSymbols :scannerResultArray="socketScannerResult" />
    </div>
  </div>
</template>
<script>
import { chartEvents } from '../../../chartEvents.js'
import RightBarHead from '../../RightBarHead.vue'
import ScannerResultSymbols from './ScannerResultSymbols.vue'
import { mapGetters } from 'vuex'
import ProgressiveBar from '../../ProgressiveBar.vue'
export default {
  name: 'App',
  data() {
    return {
      // scannerResultArray: [],
      showTitle: false,
    }
  },
  components: {
    RightBarHead,
    ScannerResultSymbols,
    ProgressiveBar,
  },
  props: {
    mainHeading: String,
    subHeading: String,
    selectedRight: String,
    socketScannerResult: Array,
    progressPercentage: {
      type: Number,
      default: 0,
    },
    totalSymbolCount_second_props: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
  updateScannerResult(payload) {
    console.log('child 2', payload)
    this.scannerResultArray = payload
  },
  computed: {
    ...mapGetters(['scanResults','getPercentageCount','getScanResult']),
    // percentage(){
    //   return this.getPercentageCount
    // },
    stocksMessage() {
      return `${this.socketScannerResult.length} scanned.`
    },
  },

  watch: {
    scannerResultArray() {
      console.log('scanResults', this.scanResults)
    },
    progressPercentage(val) {
      console.log('Result==>', val)
    },
    socketScannerResult(){
      console.log("socketScannerResult",this.socketScannerResult)
    },
    scanResults(val){
      console.log("scan result==>",val[val.length - 1])
    }
  },

  mounted() {
    console.log('progressPercentage==>', this.progressPercentage)
  },
}
</script>
<style scoped></style>
