<template>
  <div class="w-full h-full">
    <div v-if="isTrainingMode" class="py-2">
      <div class="w-full space-y-1">
        <div
          v-for="symData of data"
          :key="symData.left"
          class="w-full flex items-center border-b px-4 py-2 justify-between"
        >
          <div class="text-[14px] text-appTextColor font-[400]">
            {{ symData.left }}
          </div>
          <div class="text-[#001A2C] text-[13px] font-[600]">
            ${{ symData.right || '0' }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-full relative h-full space-y-2 px-4 py-3">
      <div>Starting Date</div>
      <div class="absolute w-11/12 h-auto left-6" >
      <div class="w-full">
        <DatePicker
          v-model="startingDate"
          :placeholder="placeholder"
          type="date"
          :disabled-date="disabledDate"
        ></DatePicker>
      </div>
      <div  ref="dropdownContainer">
      <div class="w-full mt-3 relative space-y-2">
        <div>Preceding Candles</div>
        <div   @click="showCandlePreceeding = !showCandlePreceeding" class="w-full border p-2 w-full flex items-center cursor-pointer justify-between rounded-md text-appBtnTxt">
          <div>{{selectedCandlePreceeding?.label || candleInputValue}}</div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>

          </div> 
        </div>
        <div v-if="showCandlePreceeding" class="w-full absolute bg-white shadow-md rounded-sm" >
          <div @click="handleCandlePreceeding(item)" class="px-4 py-2.5" :class="item.value == selectedCandlePreceeding?.value ? 'bg-appBtnActive text-appBtnActiveTxt':'cursor-pointer hover:bg-appBtnHover'" v-for="(item,i) of preceedingCandles" :key="i">
            {{ item.label }}
          </div>
          <div class="px-3 w-full my-2">
            <input v-model="candleInputValue" type="number" min="3" placeholder="Enter a specific number of candles (e.g., 10, 50, 100)" class="w-full border px-2 !py-3 text-md placeholder:text-md rounded-sm hover:!border-gray-300 focus:!border-gray-400 focus:outline-none" />
          </div>
          <div v-if="inputError" class="text-[13px] px-3 pb-2 pt-1 text-red-600">{{inputError}}</div>
        </div>
      </div>
      </div>
      <div class="w-full flex justify-end mt-3">
        <div class="w-fit">
          <ButtonComp title="Search" colorType="color" @press="handleSearch" />
        </div>
      </div>
      </div>

      <!-- <div class="w-full bg-red-400">
        <DatePicker
          v-model="startingDate"
          :placeholder="placeholder"
          type="date"
        ></DatePicker>
      </div> -->
   
    </div>
  </div>
</template>

<script>
import ButtonComp from '../../ButtonComp'
import { mapGetters, mapActions } from 'vuex'
import { FormatUnixDate } from '../../../utils/candle'
import moment from 'moment/moment'
import { chartEvents } from '../../../chartEvents';

export default {
  name: 'TrainingModeData',
  components: {
    ButtonComp,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      startingDate: '',
      showCandlePreceeding:false,
      inputError:'',
      placeholder: '',
      candleInputValue:null,
      selectedCandlePreceeding:{label:'1 Month',value:21},
      preceedingCandles:[{label:'Max',value:'all'},{label:'52 Weeks',value:252},{label:'26 Weeks',value:126},{label:'13 Weeks',value:63},{label:'1 Month',value:21},{label:'1 Week',value:7}]
      
    }
  },
  methods: {
    ...mapActions(['updateTrainingModeState', 'setNewChartType','updateTrainingModeCandles']),

    filterCandle(){
        const date = moment(this.startingDate)
        const startingDateFormate = new Date(date)
        const filteredData = this.formattedSymbolData?.filter(item => new Date(item.date) < startingDateFormate);
        return filteredData?.length
    },

    assignInitial(){     
        this.selectedCandlePreceeding = {label:'1 Month',value:21}
        this.updateTrainingModeCandles(21)
    },  

    updateMaxCandlePreceeding(){
        this.updateTrainingModeCandles(this.filterCandle())
    },

    handleCandlePreceeding(item){
      this.candleInputValue = null
      this.selectedCandlePreceeding = item
      if(item.value == 'all'){
       this.updateMaxCandlePreceeding()
      }else{
        this.updateTrainingModeCandles(item.value)
      }
      this.showCandlePreceeding = false
    },
    async handleSearch() {
      console.log("date==>",this.startingDate)

      const date = moment(this.startingDate)
      const formattedDate = date?.format('YYYY-MM-DD')
      
      const startingTime = new Date(formattedDate).getTime()
      const symbolDataLastIndex = this.getSymbolData.length - 1
      const symbolDatumElement = this.getSymbolData[symbolDataLastIndex][0]
      if (startingTime > symbolDatumElement) return

      let index = 0
      for (const dt of this.getSymbolData) {
        if (dt[0] > startingTime) break
        index++
      }

      const end = index + 1
      const trainingOffset = this.trainingOffset
      const trainingModeData = this.formattedSymbolData.slice(
        index - trainingOffset,
        end
      )

      const trainingModeDataRemaining = this.getSymbolData.slice(
        index + 1,
        this.getSymbolData.length
      )

      const trainingModeDataRaw = trainingModeData.map((candle) => [
        moment(candle.date).valueOf(),
        candle.open,
        candle.high,
        candle.low,
        candle.close,
        candle.volume,
        null,
        candle.value,
      ])
      const trainingModeDataRaw2 = trainingModeDataRemaining.map((candle) => [
        candle[0],
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ])
      const finalRawDataSet = [...trainingModeDataRaw, ...trainingModeDataRaw2]
      console.log(
        'last dataSlice',
        FormatUnixDate(finalRawDataSet[finalRawDataSet.length - 1][0]),
        finalRawDataSet.length,
        FormatUnixDate(this.getSymbolData[symbolDataLastIndex][0]),
        this.getSymbolData.length
      )

      console.log('training-mode', {
        state: !this.isTrainingMode,
        startingIndex: index,
        trainingDataIndex: trainingModeData.length - 1,
        trainingModeData,
        trainingModeDataRaw: finalRawDataSet,
      })

      if (this.getChartType != 'Candles') {
        this.setNewChartType({ type: 'Candles', location: 'FromTrainingMode' })
      }


        this.updateTrainingModeState({
          state: !this.isTrainingMode,
          startingIndex: index,
          trainingDataIndex: trainingModeData.length - 1,
          trainingModeData,
          trainingModeDataRaw: finalRawDataSet,
        })
        chartEvents.$emit('moveToStart',startingTime)
      await this.$nextTick()
    },
    disabledDate(date) {
      let currentCandleDate = this.formattedSymbolData[this.formattedSymbolData?.length - 1]?.date
      let firstCandleDate = this.formattedSymbolData[0]?.date
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      if(currentCandleDate){
        const firstCandleMoment = moment(firstCandleDate).toDate()
        const twoDaysBefore = moment(currentCandleDate).subtract(2, 'days').toDate(); 
        return isWeekend || date > twoDaysBefore || date < firstCandleMoment; 
      }
    },
    handleClickOutside(event) {
      if(this.showCandlePreceeding){
        const dropdownContainer = this.$refs.dropdownContainer;
        if (!dropdownContainer.contains(event.target)) {
          this.showCandlePreceeding = false;
        }
      }
    },
   
  
    getDateTwoWeekdaysBefore() {
      let currentCandleDate = this.formattedSymbolData[this.formattedSymbolData?.length - 1]?.date
      if(currentCandleDate){
          let date = moment(currentCandleDate);
          // Counter for weekdays
          let weekdaysCount = 0;
          // Continue until we have 2 weekdays
          while (weekdaysCount < 2) {
              date.subtract(1, 'days'); // Subtract one day
              // Check if the day is a weekday (0: Sunday, 6: Saturday)
              if (date.isoWeekday() < 6) { // 6 is Saturday
                  weekdaysCount++; // Increment if it's a weekday
              }
          }
          this.startingDate = new Date(date.format('YYYY-MM-DD'));// Format the date as desired
          // this.placeholder = date.format('YYYY-MM-DD'); // Format the date as desired
  }

}
  },
  computed: {
    ...mapGetters([
      'isTrainingMode',
      'dc',
      'getSymbolData',
      'formattedSymbolData',
      'trainingOffset',
      'getChartType',
      'ohlcv'
    ]),
    today() {
      return Date.now()
    },
  },
  mounted() {
    this.getDateTwoWeekdaysBefore()
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch:{
    startingDate(){
      if(this.selectedCandlePreceeding?.value == 'all'){
        this.updateMaxCandlePreceeding()
      }
    },
    candleInputValue(newVal){
      if(newVal){
        this.inputError = ''        
        if(newVal > 1){
          let filterCandleLength = this.filterCandle()
          if(newVal <= filterCandleLength){
            this.updateTrainingModeCandles(newVal)
            this.selectedCandlePreceeding = null
          }else{
            this.inputError = `Number must be less than or equal to ${filterCandleLength}`
            this.assignInitial()
        }
        }else{
          this.inputError = 'Number must be greater than 1'
        }
      }else{
        this.inputError = ''
        this.assignInitial()
      }
    }
  }
}
</script>

<style scoped>

</style>
